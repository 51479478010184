export const VIEW_MODE_OPTIONS = [
  {
    label: "Дни",
    value: "Day",
  },
  {
    label: "Месяцы",
    value: "Month",
  },
  {
    label: "Года",
    value: "Year",
  },
];
