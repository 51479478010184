import React, { memo, useState } from "react";
import { NodeResizer, useReactFlow } from "reactflow";

import styles from "./styles.module.css";
import "./style.css";
import CustomInput from "../components/CustomInput";

const MIN_WIDTH = 80;
const MIN_HEIGHT = 40;

export default memo(({ id, data }) => {
  const { setNodes } = useReactFlow();
  const [sizeBlock, setSizeBlock] = useState({
    width: MIN_WIDTH,
    height: MIN_HEIGHT,
  });

  return (
    <div className={styles.wrapperNode}>
      <NodeResizer
        lineClassName={styles.resizeLine}
        handleClassName={styles.resizeHandle}
        onResize={(e, param) => {
          setSizeBlock(param);
        }}
        minWidth={MIN_WIDTH}
        minHeight={MIN_HEIGHT}
      />
      <div
        style={{
          width: `${sizeBlock.width}px`,
          height: `${sizeBlock.height}px`,
        }}
      />
      <CustomInput
        value={data.mainText}
        id={id}
        setter={setNodes}
        sizeBlock={sizeBlock}
      />
    </div>
  );
});
