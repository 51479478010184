import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../api/api";

const initialState = {
  lookup: {},
  pareto: {},
  error: "",
  errorPareto: "",
  chart: "",
  loadingChart: false,
  loadingPareto: false,
  loading: false,
  shewhart: {},
  errorShewhart: "",
  loadingShewhart: false,
};

export const getAnalyticsLookup = createAsyncThunk(
  "analytics/getAnalyticsWorkers",
  async () => {
    const responseWorkers = api.get("/api/analysis/workers/lookup/");
    const responseShoesModels = api.get("/api/analysis/shoes_models/lookup/");
    const responseOrders = api.get("/api/analysis/orders/lookup/");
    const response = await Promise.all([
      responseWorkers,
      responseShoesModels,
      responseOrders,
    ]);
    const data = response.reduce(
      (arr, item) => ({
        ...arr,
        [item.config.url.split("/")[3]]: item.data,
      }),
      {}
    );
    return data;
  }
);

export const getAnalyticsChart = createAsyncThunk(
  "analytics/getAnalyticsChart",
  async (data) => {
    const { date, ...rest } = data;
    const params = {
      ...rest,
      start_date: date && date[0].format("YYYY-MM-DD"),
      end_date: date && date[1].format("YYYY-MM-DD"),
    };

    const getChart = await api.get("/api/analysis/chart/", { params });
    return getChart.data;
  }
);

export const getParetoChart = createAsyncThunk(
  "analytics/getParetoChart",
  async (data) => {
    const { date, ...rest } = data;
    const params = {
      ...rest,
      start_date: date && date[0].format("YYYY-MM-DD"),
      end_date: date && date[1].format("YYYY-MM-DD"),
    };

    const getChart = await api.get("/api/analysis/pareto/", { params });
    return getChart.data;
  }
);

export const getShewhartChart = createAsyncThunk(
  "analytics/getShewhartChart",
  async (data) => {
    const { date, ...rest } = data;
    const params = {
      ...rest,
      start_date: date && date[0].format("YYYY-MM-DD"),
      end_date: date && date[1].format("YYYY-MM-DD"),
    };

    const getShewhart = await api.get("/api/analysis/shewhart/", { params });
    return getShewhart.data;
  }
);

export const analyticsSlice = createSlice({
  name: "analytics",
  initialState,
  reducers: {},
  extraReducers: {
    [getAnalyticsLookup.pending]: (state) => {
      state.loading = true;
    },
    [getAnalyticsLookup.rejected]: (state) => {
      state.loading = false;
    },
    [getAnalyticsLookup.fulfilled]: (state, action) => {
      state.lookup = action.payload;
      state.loading = false;
    },
    [getAnalyticsChart.pending]: (state) => {
      state.loadingChart = true;
      state.error = null;
      state.chart = null;
    },
    [getAnalyticsChart.rejected]: (state) => {
      state.loadingChart = false;
      state.chart = null;
      state.error = "Нет данных по введенным фильтрам";
    },
    [getAnalyticsChart.fulfilled]: (state, action) => {
      state.chart = action.payload;
      state.loadingChart = false;
      state.error = null;
    },
    [getParetoChart.pending]: (state) => {
      state.loadingPareto = true;
      state.errorPareto = null;
      state.pareto = null;
    },
    [getParetoChart.rejected]: (state) => {
      state.loadingPareto = false;
      state.chart = null;
      state.errorPareto = "Нет данных по введенным фильтрам";
    },
    [getParetoChart.fulfilled]: (state, action) => {
      state.pareto = action.payload;
      state.loadingPareto = false;
      state.errorPareto = null;
    },
    [getShewhartChart.pending]: (state) => {
      state.loadingShewhart = true;
      state.errorShewhart = null;
      state.shewhart = null;
    },
    [getShewhartChart.rejected]: (state) => {
      state.loadingShewhart = false;
      state.shewhart = null;
      state.errorShewhart = "Нет данных по введенным фильтрам";
    },
    [getShewhartChart.fulfilled]: (state, action) => {
      state.shewhart = action.payload;
      state.loadingShewhart = false;
      state.errorShewhart = null;
    },
  },
});

export const act = analyticsSlice.actions;

export default analyticsSlice.reducer;
