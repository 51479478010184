import React from "react";
import { useReactFlow, getRectOfNodes, getTransformForBounds } from "reactflow";
import { toPng } from "html-to-image";
import { Button } from "antd";
import moment from "moment";

function downloadImage(dataUrl) {
  const a = document.createElement("a");

  a.setAttribute("download", `диаграмма-${moment().format("DD-MM-YYYY")}.png`);
  a.setAttribute("href", dataUrl);
  a.click();
}

const DownloadButton = () => {
  const { getNodes } = useReactFlow();
  const onClick = () => {
    // we calculate a transform for the nodes so that all nodes are visible
    // we then overwrite the transform of the `.react-flow__viewport` element
    // with the style option of the html-to-image library
    const nodesBounds = getRectOfNodes(getNodes());
    const transform = getTransformForBounds(
      nodesBounds,
      nodesBounds.width,
      nodesBounds.height,
      0.5,
      2
    );

    toPng(document.querySelector(".react-flow__viewport"), {
      backgroundColor: "#fff",
      width: nodesBounds.width,
      height: nodesBounds.height,
      style: {
        width: nodesBounds.width,
        height: nodesBounds.height,
        transform: `translate(${transform[0]}px, ${transform[1]}px) scale(${transform[2]})`,
      },
    }).then(downloadImage);
  };

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Button className="download-btn" onClick={onClick} danger>
        Сохранить
      </Button>
    </div>
  );
};

export default DownloadButton;
