import { useMemo } from "react";
import { generateHandlePort, generateHandleStyle } from "../../utils";
import { Handle, Position } from "reactflow";

const Ports = ({ size, className }) => {
  const handleStyleLeft = useMemo(
    () => generateHandleStyle(size, "left"),
    [size]
  );
  const handleStyleTop = useMemo(
    () => generateHandleStyle(size, "top"),
    [size]
  );

  const topPorts = useMemo(() => generateHandlePort(size, "top"), [size]);
  const bottomPorts = useMemo(() => generateHandlePort(size, "bottom"), [size]);
  const leftPorts = useMemo(() => generateHandlePort(size, "left"), [size]);
  const rightPorts = useMemo(() => generateHandlePort(size, "right"), [size]);

  return (
    <>
      {topPorts.map((handleId, index) => (
        <Handle
          className={className}
          key={handleId}
          style={handleStyleLeft[index]}
          type="source"
          position={Position.Top}
          id={handleId}
        />
      ))}
      {rightPorts.map((handleId, index) => (
        <Handle
          className={className}
          key={handleId}
          style={handleStyleTop[index]}
          type="source"
          position={Position.Right}
          id={handleId}
        />
      ))}
      {bottomPorts.map((handleId, index) => (
        <Handle
          className={className}
          key={handleId}
          style={handleStyleLeft[index]}
          type="source"
          position={Position.Bottom}
          id={handleId}
        />
      ))}
      {leftPorts.map((handleId, index) => (
        <Handle
          className={className}
          key={handleId}
          style={handleStyleTop[index]}
          type="source"
          position={Position.Left}
          id={handleId}
        />
      ))}
    </>
  );
};

export default Ports;
