import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./authSlice";
import modelsReducer from "./modelsSlice";
import ordersReducer from "./ordersSlice";
import workersReducer from "./workersSlice";
import analyticsReducer from "./analyticsSlice";
import typeDefectsReducer from "./typeDefectsSlice";
import warehouseReducer from "./warehouseSlice";
import warehouseStatusReducer from "./warehouseStatusSlice";

export default configureStore({
  reducer: {
    auth: authReducer,
    models: modelsReducer,
    orders: ordersReducer,
    workers: workersReducer,
    ware: warehouseReducer,
    wareStatus: warehouseStatusReducer,
    analytics: analyticsReducer,
    typeDefects: typeDefectsReducer,
  },
});
