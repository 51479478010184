export const Temp1 = {
  nodes: [
    {
      id: "d75eabc3-a84c-453d-a805-8c5f9ec45ac3",
      type: "textNodeWithBlocks",
      position: {
        x: 592.964201582417,
        y: 246.952700060538,
      },
      data: {
        mainText: "причина 1.3",
        topLeftText: "",
        topRightText: "",
        bottomLeftText: "",
        bottomRightText: "",
      },
      width: 149,
      height: 50,
      positionAbsolute: {
        x: 592.964201582417,
        y: 246.952700060538,
      },
      selected: false,
      dragging: false,
      style: {
        width: 149,
        height: 50,
      },
      resizing: false,
    },
    {
      id: "21e3a29f-eb80-478d-b485-2d1f2f6b7766",
      type: "textNodeWithBlocks",
      position: {
        x: 592.6580330405476,
        y: 148.06874559799996,
      },
      data: {
        mainText: "причина 1.2",
        topLeftText: "",
        topRightText: "",
        bottomLeftText: "",
        bottomRightText: "",
      },
      width: 149,
      height: 53,
      positionAbsolute: {
        x: 592.6580330405476,
        y: 148.06874559799996,
      },
      selected: false,
      dragging: false,
      style: {
        width: 149,
        height: 53,
      },
      resizing: false,
    },
    {
      id: "834a1dc3-5e7a-45ae-a47c-8732b424c85d",
      type: "textNodeWithBlocks",
      position: {
        x: 591.8975410229185,
        y: 52.433407335879934,
      },
      data: {
        mainText: "причина 1.1",
        topLeftText: "",
        topRightText: "",
        bottomLeftText: "",
        bottomRightText: "",
      },
      width: 149,
      height: 50,
      positionAbsolute: {
        x: 591.8975410229185,
        y: 52.433407335879934,
      },
      selected: false,
      dragging: false,
      style: {
        width: 149,
        height: 50,
      },
      resizing: false,
    },
    {
      id: "9da3b188-40e3-4bb7-86e8-cc2638497cb7",
      type: "textNodeWithBlocks",
      position: {
        x: 310.837893918788,
        y: 340.4764048262592,
      },
      data: {
        mainText: "причина 3",
        topLeftText: "",
        topRightText: "",
        bottomLeftText: "",
        bottomRightText: "",
      },
      width: 128,
      height: 50,
      positionAbsolute: {
        x: 310.837893918788,
        y: 340.4764048262592,
      },
      selected: false,
      dragging: false,
      style: {
        width: 128,
        height: 50,
      },
      resizing: false,
    },
    {
      id: "c2880564-d5bc-4b69-8a8f-e2ddd65aa74d",
      type: "textNodeWithBlocks",
      position: {
        x: 311.337893918788,
        y: 243.47640482625923,
      },
      data: {
        mainText: "причина 2",
        topLeftText: "",
        topRightText: "",
        bottomLeftText: "",
        bottomRightText: "",
      },
      width: 128,
      height: 50,
      positionAbsolute: {
        x: 311.337893918788,
        y: 243.47640482625923,
      },
      selected: false,
      dragging: false,
      style: {
        width: 128,
        height: 50,
      },
      resizing: false,
    },
    {
      id: "7d5eb5c8-8939-408c-bd51-662f909052a5",
      type: "textNodeWithBlocks",
      position: {
        x: 310.837893918788,
        y: 148.47640482625923,
      },
      data: {
        mainText: "причина 1",
        topLeftText: "",
        topRightText: "",
        bottomLeftText: "",
        bottomRightText: "",
      },
      width: 128,
      height: 50,
      positionAbsolute: {
        x: 310.837893918788,
        y: 148.47640482625923,
      },
      selected: false,
      dragging: false,
      style: {
        width: 128,
        height: 50,
      },
      resizing: false,
    },
    {
      id: "textNodeWithBlocks-4fb58b55-b9d5-4c10-a815-8adb3c50a831",
      type: "textNodeWithBlocks",
      position: {
        x: 23.171875,
        y: 243.40625,
      },
      data: {
        mainText: "проблема",
        topLeftText: "",
        topRightText: "",
        bottomLeftText: "",
        bottomRightText: "",
      },
      width: 128,
      height: 50,
      positionAbsolute: {
        x: 23.171875,
        y: 243.40625,
      },
      selected: false,
      dragging: false,
      style: {
        width: 128,
        height: 50,
      },
      resizing: false,
    },
  ],
  edges: [
    {
      markerStart: {
        type: "arrowclosed",
      },
      markerEnd: {
        type: "arrowclosed",
      },
      type: "defaultEdge",
      data: {
        color: "#000",
        countArrow: 0,
      },
      source: "7d5eb5c8-8939-408c-bd51-662f909052a5",
      sourceHandle: "right-3",
      target: "834a1dc3-5e7a-45ae-a47c-8732b424c85d",
      targetHandle: "left-5",
      id: "reactflow__edge-7d5eb5c8-8939-408c-bd51-662f909052a5right-3-834a1dc3-5e7a-45ae-a47c-8732b424c85dleft-5",
      selected: false,
    },
    {
      markerStart: {
        type: "arrowclosed",
      },
      markerEnd: {
        type: "arrowclosed",
      },
      type: "defaultEdge",
      data: {
        color: "#000",
        countArrow: 0,
      },
      source: "7d5eb5c8-8939-408c-bd51-662f909052a5",
      sourceHandle: "right-5",
      target: "21e3a29f-eb80-478d-b485-2d1f2f6b7766",
      targetHandle: "left-5",
      id: "reactflow__edge-7d5eb5c8-8939-408c-bd51-662f909052a5right-5-21e3a29f-eb80-478d-b485-2d1f2f6b7766left-5",
      selected: false,
    },
    {
      markerStart: {
        type: "arrowclosed",
      },
      markerEnd: {
        type: "arrowclosed",
      },
      type: "defaultEdge",
      data: {
        color: "#000",
        countArrow: 0,
      },
      source: "7d5eb5c8-8939-408c-bd51-662f909052a5",
      sourceHandle: "left-5",
      target: "textNodeWithBlocks-4fb58b55-b9d5-4c10-a815-8adb3c50a831",
      targetHandle: "right-1",
      selected: false,
      id: "reactflow__edge-7d5eb5c8-8939-408c-bd51-662f909052a5left-5-textNodeWithBlocks-4fb58b55-b9d5-4c10-a815-8adb3c50a831right-1",
    },
    {
      markerStart: {
        type: "arrowclosed",
      },
      markerEnd: {
        type: "arrowclosed",
      },
      type: "defaultEdge",
      data: {
        color: "#000",
        countArrow: 0,
      },
      source: "textNodeWithBlocks-4fb58b55-b9d5-4c10-a815-8adb3c50a831",
      sourceHandle: "right-5",
      target: "c2880564-d5bc-4b69-8a8f-e2ddd65aa74d",
      targetHandle: "left-5",
      selected: false,
      id: "reactflow__edge-textNodeWithBlocks-4fb58b55-b9d5-4c10-a815-8adb3c50a831right-5-c2880564-d5bc-4b69-8a8f-e2ddd65aa74dleft-5",
    },
    {
      markerStart: {
        type: "arrowclosed",
      },
      markerEnd: {
        type: "arrowclosed",
      },
      type: "defaultEdge",
      data: {
        color: "#000",
        countArrow: 0,
      },
      source: "textNodeWithBlocks-4fb58b55-b9d5-4c10-a815-8adb3c50a831",
      sourceHandle: "right-8",
      target: "9da3b188-40e3-4bb7-86e8-cc2638497cb7",
      targetHandle: "left-6",
      selected: false,
      id: "reactflow__edge-textNodeWithBlocks-4fb58b55-b9d5-4c10-a815-8adb3c50a831right-8-9da3b188-40e3-4bb7-86e8-cc2638497cb7left-6",
    },
    {
      markerStart: {
        type: "arrowclosed",
      },
      markerEnd: {
        type: "arrowclosed",
      },
      type: "defaultEdge",
      data: {
        color: "#000",
        countArrow: 0,
      },
      source: "7d5eb5c8-8939-408c-bd51-662f909052a5",
      sourceHandle: "right-8",
      target: "d75eabc3-a84c-453d-a805-8c5f9ec45ac3",
      targetHandle: "left-4",
      selected: false,
      id: "reactflow__edge-7d5eb5c8-8939-408c-bd51-662f909052a5right-8-d75eabc3-a84c-453d-a805-8c5f9ec45ac3left-4",
    },
  ],
};

export const Temp2 = {
  nodes: [
    {
      id: "textNode-6dc226bd-f283-4819-b9b2-aa776d1ade33",
      type: "textNode",
      position: {
        x: 303.3487509577669,
        y: -133.87243731332472,
      },
      data: {
        mainText: "полезность",
      },
      width: 100,
      height: 38,
      positionAbsolute: {
        x: 303.3487509577669,
        y: -133.87243731332472,
      },
      style: {
        width: 100,
        height: 38,
      },
      resizing: false,
      selected: false,
      dragging: false,
    },
    {
      id: "textNodeWithBlocks-c583b481-3385-422b-9300-675af7524ca6",
      type: "textNodeWithBlocks",
      position: {
        x: 266.20818090162106,
        y: -89.1374838986506,
      },
      data: {
        mainText: "полезный",
        topLeftText: "",
        topRightText: "",
        bottomLeftText: "",
        bottomRightText: "",
      },
      width: 113,
      height: 50,
      positionAbsolute: {
        x: 266.20818090162106,
        y: -89.1374838986506,
      },
      selected: false,
      dragging: false,
      style: {
        width: 113,
        height: 50,
      },
      resizing: false,
    },
    {
      id: "textNodeWithBlocks-8e9648de-2cb6-4949-a8c9-bb7aaae57eaf",
      type: "textNodeWithBlocks",
      position: {
        x: 252.0084389554362,
        y: -24.63928495464299,
      },
      data: {
        mainText: "с витаминами",
        topLeftText: "",
        topRightText: "",
        bottomLeftText: "",
        bottomRightText: "",
      },
      width: 140,
      height: 50,
      positionAbsolute: {
        x: 252.0084389554362,
        y: -24.63928495464299,
      },
      style: {
        width: 140,
        height: 50,
      },
      resizing: false,
      selected: false,
      dragging: false,
    },
    {
      id: "textNodeWithBlocks-8c00a681-031f-47f0-a0da-028b1bb3ee8d",
      type: "textNodeWithBlocks",
      position: {
        x: 237.12931539945527,
        y: 31.384454978802978,
      },
      data: {
        mainText: "минеральные вещества",
        topLeftText: "",
        topRightText: "",
        bottomLeftText: "",
        bottomRightText: "",
      },
      width: 177,
      height: 50,
      positionAbsolute: {
        x: 237.12931539945527,
        y: 31.384454978802978,
      },
      style: {
        width: 177,
        height: 50,
      },
      resizing: false,
      selected: false,
      dragging: false,
    },
    {
      id: "textNodeWithBlocks-c7bed533-262b-4edd-82b2-cd9a04a137b3",
      type: "textNodeWithBlocks",
      position: {
        x: 240.75406838741526,
        y: 87.93060159097939,
      },
      data: {
        mainText: "молочный белок",
        topLeftText: "",
        topRightText: "",
        bottomLeftText: "",
        bottomRightText: "",
      },
      width: 162,
      height: 50,
      selected: false,
      positionAbsolute: {
        x: 240.75406838741526,
        y: 87.93060159097939,
      },
      dragging: false,
      style: {
        width: 162,
        height: 50,
      },
      resizing: false,
    },
    {
      id: "textNodeWithBlocks-db02592d-0a92-42a3-95fe-7609797c3d41",
      type: "textNodeWithBlocks",
      position: {
        x: 482.4927058335768,
        y: -87.20882629370982,
      },
      data: {
        mainText: "с полезными микроорганизмами",
        topLeftText: "",
        topRightText: "",
        bottomLeftText: "",
        bottomRightText: "",
      },
      width: 179,
      height: 57,
      positionAbsolute: {
        x: 482.4927058335768,
        y: -87.20882629370982,
      },
      style: {
        width: 179,
        height: 57,
      },
      resizing: false,
      selected: false,
      dragging: false,
    },
    {
      id: "textNodeWithBlocks-a6cbdfa4-04d1-4ce8-ad65-4b4208c5e522",
      type: "textNodeWithBlocks",
      position: {
        x: 478.3414213709689,
        y: -8.438717132101203,
      },
      data: {
        mainText: "с пищевыми волокнами",
        topLeftText: "",
        topRightText: "",
        bottomLeftText: "",
        bottomRightText: "",
      },
      width: 169,
      height: 50,
      positionAbsolute: {
        x: 478.3414213709689,
        y: -8.438717132101203,
      },
      selected: false,
      dragging: false,
      style: {
        width: 169,
        height: 50,
      },
      resizing: false,
    },
    {
      id: "textNode-16c7fc75-1df5-4432-8599-f2c8616dffae",
      type: "textNode",
      position: {
        x: 826.8404864405086,
        y: -130.0974448530343,
      },
      data: {
        mainText: "пищевая ценность",
      },
      width: 155,
      height: 38,
      positionAbsolute: {
        x: 826.8404864405086,
        y: -130.0974448530343,
      },
      style: {
        width: 155,
        height: 38,
      },
      resizing: false,
      selected: false,
      dragging: false,
    },
    {
      id: "textNodeWithBlocks-296b9187-1155-476c-8424-726bd7d2a52d",
      type: "textNodeWithBlocks",
      position: {
        x: 749.2707724981641,
        y: -88.77526079028996,
      },
      data: {
        mainText: "содержание углеводов",
        topLeftText: "",
        topRightText: "",
        bottomLeftText: "",
        bottomRightText: "",
      },
      width: 142,
      height: 54,
      positionAbsolute: {
        x: 749.2707724981641,
        y: -88.77526079028996,
      },
      style: {
        width: 142,
        height: 54,
      },
      resizing: false,
      selected: false,
      dragging: false,
    },
    {
      id: "textNodeWithBlocks-f0401411-8912-4bcc-8b33-0e811ff95fe4",
      type: "textNodeWithBlocks",
      position: {
        x: 970.5487228048355,
        y: -85.69337496874195,
      },
      data: {
        mainText: "содержание жира",
        topLeftText: "",
        topRightText: "",
        bottomLeftText: "",
        bottomRightText: "",
      },
      width: 206,
      height: 52,
      positionAbsolute: {
        x: 970.5487228048355,
        y: -85.69337496874195,
      },
      style: {
        width: 206,
        height: 52,
      },
      resizing: false,
      selected: false,
      dragging: false,
    },
    {
      id: "textNodeWithBlocks-fb1b825e-7c25-480a-be43-d1b384b8fe6c",
      type: "textNodeWithBlocks",
      position: {
        x: 940.4790299584013,
        y: -28.119933082716216,
      },
      data: {
        mainText: "содержание белка",
        topLeftText: "",
        topRightText: "",
        bottomLeftText: "",
        bottomRightText: "",
      },
      width: 171,
      height: 50,
      positionAbsolute: {
        x: 940.4790299584013,
        y: -28.119933082716216,
      },
      selected: false,
      dragging: false,
      style: {
        width: 171,
        height: 50,
      },
      resizing: false,
    },
    {
      id: "textNode-be22eeb3-20de-42e1-9331-6ff212a50ea9",
      type: "textNode",
      position: {
        x: 755.0703772789,
        y: 14.892674665366883,
      },
      data: {
        mainText: "энергетическая ценность",
      },
      width: 162,
      height: 64,
      positionAbsolute: {
        x: 755.0703772789,
        y: 14.892674665366883,
      },
      style: {
        width: 162,
        height: 64,
      },
      resizing: false,
      selected: false,
      dragging: false,
    },
    {
      id: "textNodeWithBlocks-5f5c760e-737a-473f-85cf-c3c07968439e",
      type: "textNodeWithBlocks",
      position: {
        x: 905.5783642971471,
        y: 36.38006270760479,
      },
      data: {
        mainText: "высокая калорийность",
        topLeftText: "",
        topRightText: "",
        bottomLeftText: "",
        bottomRightText: "",
      },
      width: 187,
      height: 50,
      positionAbsolute: {
        x: 905.5783642971471,
        y: 36.38006270760479,
      },
      style: {
        width: 187,
        height: 50,
      },
      resizing: false,
      selected: false,
      dragging: false,
    },
    {
      id: "textNodeWithBlocks-24f2a632-f619-4966-bad4-2981bc449b74",
      type: "textNodeWithBlocks",
      position: {
        x: 769.9919951520748,
        y: 97.41678154577812,
      },
      data: {
        mainText: "низкая калорийность",
        topLeftText: "",
        topRightText: "",
        bottomLeftText: "",
        bottomRightText: "",
      },
      width: 142,
      height: 54,
      selected: false,
      positionAbsolute: {
        x: 769.9919951520748,
        y: 97.41678154577812,
      },
      dragging: false,
      style: {
        width: 142,
        height: 54,
      },
      resizing: false,
    },
    {
      id: "textNode-7311144c-9907-466e-b72f-21e0e83b89c6",
      type: "textNode",
      position: {
        x: 329.5243764923926,
        y: 179.45646031875216,
      },
      data: {
        mainText: "натуральность",
      },
      width: 147,
      height: 42,
      positionAbsolute: {
        x: 329.5243764923926,
        y: 179.45646031875216,
      },
      style: {
        width: 147,
        height: 42,
      },
      resizing: false,
      selected: false,
      dragging: false,
    },
    {
      id: "textNodeWithBlocks-3d4ce170-ec46-42ee-ac8a-86a0ef74daf5",
      type: "textNodeWithBlocks",
      position: {
        x: 294.7267478079761,
        y: 212.07923721039242,
      },
      data: {
        mainText: "только натуральные добавки, компоненты",
        topLeftText: "",
        topRightText: "",
        bottomLeftText: "",
        bottomRightText: "",
      },
      width: 182,
      height: 73,
      positionAbsolute: {
        x: 294.7267478079761,
        y: 212.07923721039242,
      },
      selected: false,
      dragging: false,
      style: {
        width: 182,
        height: 73,
      },
      resizing: false,
    },
    {
      id: "textNodeWithBlocks-c6e15f7e-4d92-40f2-94a7-9f0fbce37ef1",
      type: "textNodeWithBlocks",
      position: {
        x: 515.6167649787509,
        y: 200.01620693685066,
      },
      data: {
        mainText: "без ГМИ",
        topLeftText: "",
        topRightText: "",
        bottomLeftText: "",
        bottomRightText: "",
      },
      width: 103,
      height: 50,
      positionAbsolute: {
        x: 515.6167649787509,
        y: 200.01620693685066,
      },
      style: {
        width: 103,
        height: 50,
      },
      resizing: false,
      selected: false,
      dragging: false,
    },
    {
      id: "textNodeWithBlocks-8d64b4d9-0c32-4bc3-b273-c86f70794e9f",
      type: "textNodeWithBlocks",
      position: {
        x: 299.8014019911205,
        y: 289.648951152737,
      },
      data: {
        mainText: "без ГМО",
        topLeftText: "",
        topRightText: "",
        bottomLeftText: "",
        bottomRightText: "",
      },
      width: 103,
      height: 50,
      positionAbsolute: {
        x: 299.8014019911205,
        y: 289.648951152737,
      },
      style: {
        width: 103,
        height: 50,
      },
      resizing: false,
      selected: false,
      dragging: false,
    },
    {
      id: "textNodeWithBlocks-a4cc3dd0-a9b0-4b6a-81d6-f24a85d34054",
      type: "textNodeWithBlocks",
      position: {
        x: 521.7599292264795,
        y: 265.2823681154986,
      },
      data: {
        mainText: "без ароматизаторов",
        topLeftText: "",
        topRightText: "",
        bottomLeftText: "",
        bottomRightText: "",
      },
      width: 184,
      height: 51,
      positionAbsolute: {
        x: 521.7599292264795,
        y: 265.2823681154986,
      },
      style: {
        width: 184,
        height: 51,
      },
      resizing: false,
      selected: false,
      dragging: false,
    },
    {
      id: "textNodeWithBlocks-b0f0cc10-cc24-4bc4-af16-538c1bbe2db5",
      type: "textNodeWithBlocks",
      position: {
        x: 315.02536454055246,
        y: 347.6449989600974,
      },
      data: {
        mainText: "без консерваторов",
        topLeftText: "",
        topRightText: "",
        bottomLeftText: "",
        bottomRightText: "",
      },
      width: 174,
      height: 50,
      positionAbsolute: {
        x: 315.02536454055246,
        y: 347.6449989600974,
      },
      style: {
        width: 174,
        height: 50,
      },
      resizing: false,
      selected: false,
      dragging: false,
    },
    {
      id: "textNodeWithBlocks-6d56ea84-d5f5-4d09-87ba-e66b725c9c07",
      type: "textNodeWithBlocks",
      position: {
        x: 537.2450216614337,
        y: 328.1915940701907,
      },
      data: {
        mainText: "без красителей",
        topLeftText: "",
        topRightText: "",
        bottomLeftText: "",
        bottomRightText: "",
      },
      width: 150,
      height: 50,
      positionAbsolute: {
        x: 537.2450216614337,
        y: 328.1915940701907,
      },
      style: {
        width: 150,
        height: 50,
      },
      resizing: false,
      selected: false,
      dragging: false,
    },
    {
      id: "textNodeWithBlocks-3d87be2e-05bd-4ee6-914c-b2d794f0d58e",
      type: "textNodeWithBlocks",
      position: {
        x: 389.69527609252907,
        y: 407.81589856023385,
      },
      data: {
        mainText: "только молочные компоненты",
        topLeftText: "",
        topRightText: "",
        bottomLeftText: "",
        bottomRightText: "",
      },
      width: 163,
      height: 55,
      positionAbsolute: {
        x: 389.69527609252907,
        y: 407.81589856023385,
      },
      style: {
        width: 163,
        height: 55,
      },
      resizing: false,
      selected: false,
      dragging: false,
    },
    {
      id: "textNode-9175bf79-4f8b-4c95-81df-b077b58cfe1f",
      type: "textNode",
      position: {
        x: 772.4691916211082,
        y: 199.75507705132821,
      },
      data: {
        mainText: "наполнитель",
      },
      width: 114,
      height: 43,
      positionAbsolute: {
        x: 772.4691916211082,
        y: 199.75507705132821,
      },
      style: {
        width: 114,
        height: 43,
      },
      resizing: false,
      selected: false,
      dragging: false,
    },
    {
      id: "textNodeWithBlocks-45261bfe-811c-425b-a535-d2f532ad457c",
      type: "textNodeWithBlocks",
      position: {
        x: 767.3945374379641,
        y: 243.25211290684854,
      },
      data: {
        mainText: "заметные кусочки фруктов",
        topLeftText: "",
        topRightText: "",
        bottomLeftText: "",
        bottomRightText: "",
      },
      width: 158,
      height: 60,
      positionAbsolute: {
        x: 767.3945374379641,
        y: 243.25211290684854,
      },
      style: {
        width: 158,
        height: 60,
      },
      resizing: false,
      selected: false,
      dragging: false,
    },
    {
      id: "textNodeWithBlocks-675e7773-bfc3-4168-b8f0-af423791e99b",
      type: "textNodeWithBlocks",
      position: {
        x: 988.3669841902089,
        y: 184.28720888461356,
      },
      data: {
        mainText: "много\nфруктов",
        topLeftText: "",
        topRightText: "",
        bottomLeftText: "",
        bottomRightText: "",
      },
      width: 131,
      height: 61,
      positionAbsolute: {
        x: 988.3669841902089,
        y: 184.28720888461356,
      },
      selected: false,
      dragging: false,
      style: {
        width: 131,
        height: 61,
      },
      resizing: false,
    },
    {
      id: "textNode-a73b1da4-f69a-4065-98ab-bb7e50894516",
      type: "textNode",
      position: {
        x: 714.6618541239175,
        y: 389.3583481989061,
      },
      data: {
        mainText: "органолептические показатели",
      },
      width: 266,
      height: 41,
      positionAbsolute: {
        x: 714.6618541239175,
        y: 389.3583481989061,
      },
      style: {
        width: 266,
        height: 41,
      },
      resizing: false,
      selected: false,
      dragging: false,
    },
    {
      id: "textNodeWithBlocks-384855e8-3d38-4c2d-bbdd-febf577ffc6e",
      type: "textNodeWithBlocks",
      position: {
        x: 740.9262501491298,
        y: 426.3230537158714,
      },
      data: {
        mainText: "запах приятный",
        topLeftText: "",
        topRightText: "",
        bottomLeftText: "",
        bottomRightText: "",
      },
      width: 154,
      height: 50,
      positionAbsolute: {
        x: 740.9262501491298,
        y: 426.3230537158714,
      },
      style: {
        width: 154,
        height: 50,
      },
      resizing: false,
      selected: false,
      dragging: false,
    },
    {
      id: "textNodeWithBlocks-c24b2bda-b982-4898-85bf-61becb364841",
      type: "textNodeWithBlocks",
      position: {
        x: 963.2195476828578,
        y: 415.8507932779585,
      },
      data: {
        mainText: "сливочный вкус",
        topLeftText: "",
        topRightText: "",
        bottomLeftText: "",
        bottomRightText: "",
      },
      width: 154,
      height: 50,
      positionAbsolute: {
        x: 963.2195476828578,
        y: 415.8507932779585,
      },
      style: {
        width: 154,
        height: 50,
      },
      resizing: false,
      selected: false,
      dragging: false,
    },
    {
      id: "textNodeWithBlocks-c1a0b24d-e33f-426c-90e0-d926ddcd02f4",
      type: "textNodeWithBlocks",
      position: {
        x: 740.9262501491296,
        y: 505.11624179150806,
      },
      data: {
        mainText: "цвет однородный",
        topLeftText: "",
        topRightText: "",
        bottomLeftText: "",
        bottomRightText: "",
      },
      width: 132,
      height: 57,
      positionAbsolute: {
        x: 740.9262501491296,
        y: 505.11624179150806,
      },
      style: {
        width: 132,
        height: 57,
      },
      resizing: false,
      selected: false,
      dragging: false,
    },
    {
      id: "textNodeWithBlocks-69274a12-3e60-4b4c-8c13-4137a327875d",
      type: "textNodeWithBlocks",
      position: {
        x: 951.3545097597761,
        y: 480.46107943658023,
      },
      data: {
        mainText: "не\nслишком\nсладкий",
        topLeftText: "",
        topRightText: "",
        bottomLeftText: "",
        bottomRightText: "",
      },
      width: 124,
      height: 83,
      positionAbsolute: {
        x: 951.3545097597761,
        y: 480.46107943658023,
      },
      style: {
        width: 124,
        height: 83,
      },
      resizing: false,
      selected: false,
      dragging: false,
    },
    {
      id: "textNodeWithBlocks-7fd1b848-3119-4b41-8105-38a2d0a26b2c",
      type: "textNodeWithBlocks",
      position: {
        x: 1236.3208357813567,
        y: 502.08974747392665,
      },
      data: {
        mainText: "вкусный",
        topLeftText: "",
        topRightText: "",
        bottomLeftText: "",
        bottomRightText: "",
      },
      width: 102,
      height: 50,
      positionAbsolute: {
        x: 1236.3208357813567,
        y: 502.08974747392665,
      },
      style: {
        width: 102,
        height: 50,
      },
      resizing: false,
      selected: false,
      dragging: false,
    },
    {
      id: "textNodeWithBlocks-4c5a4bc0-b801-4c0b-8ba6-b8fdfed07178",
      type: "textNodeWithBlocks",
      position: {
        x: 801.2370854662838,
        y: 582.9366744588034,
      },
      data: {
        mainText: "без отделения жидкости (сыворотки)",
        topLeftText: "",
        topRightText: "",
        bottomLeftText: "",
        bottomRightText: "",
      },
      width: 137,
      height: 100,
      positionAbsolute: {
        x: 801.2370854662838,
        y: 582.9366744588034,
      },
      style: {
        width: 137,
        height: 100,
      },
      resizing: false,
      selected: false,
      dragging: false,
    },
    {
      id: "textNodeWithBlocks-f2e074eb-ea95-4ab0-81fa-2c2fc67c0470",
      type: "textNodeWithBlocks",
      position: {
        x: 1039.911327949677,
        y: 582.2641201660077,
      },
      data: {
        mainText: "без комочков",
        topLeftText: "",
        topRightText: "",
        bottomLeftText: "",
        bottomRightText: "",
      },
      width: 152,
      height: 50,
      positionAbsolute: {
        x: 1039.911327949677,
        y: 582.2641201660077,
      },
      selected: false,
      dragging: false,
      style: {
        width: 152,
        height: 50,
      },
      resizing: false,
    },
    {
      id: "textNodeWithBlocks-73c3a36b-0fbe-4495-a394-a5e8cd724556",
      type: "textNodeWithBlocks",
      position: {
        x: 1025.319996824559,
        y: 658.9198244019742,
      },
      data: {
        mainText: "консистенция (структура)",
        topLeftText: "",
        topRightText: "",
        bottomLeftText: "",
        bottomRightText: "",
      },
      width: 163,
      height: 63,
      positionAbsolute: {
        x: 1025.319996824559,
        y: 658.9198244019742,
      },
      style: {
        width: 163,
        height: 63,
      },
      resizing: false,
      selected: false,
      dragging: false,
    },
    {
      id: "textNode-f41325fb-a795-430c-8405-87854087f5c5",
      type: "textNode",
      position: {
        x: 494.81913183880806,
        y: 490.5249106663901,
      },
      data: {
        mainText: "хранимоспособность",
      },
      width: 181,
      height: 48,
      positionAbsolute: {
        x: 494.81913183880806,
        y: 490.5249106663901,
      },
      style: {
        width: 181,
        height: 48,
      },
      resizing: false,
      selected: false,
      dragging: false,
    },
    {
      id: "textNodeWithBlocks-26cb4843-e722-419e-b36e-31d1efff34c4",
      type: "textNodeWithBlocks",
      position: {
        x: 511.35597378060845,
        y: 536.2444148584261,
      },
      data: {
        mainText: "долгий срок годности",
        topLeftText: "",
        topRightText: "",
        bottomLeftText: "",
        bottomRightText: "",
      },
      width: 129,
      height: 53,
      positionAbsolute: {
        x: 511.35597378060845,
        y: 536.2444148584261,
      },
      selected: false,
      dragging: false,
      style: {
        width: 129,
        height: 53,
      },
      resizing: false,
    },
    {
      id: "textNodeWithBlocks-bf4aaabd-0c3c-4740-9069-d52a0490c924",
      type: "textNodeWithBlocks",
      position: {
        x: 511.3559737806082,
        y: 598.5007609922625,
      },
      data: {
        mainText: "можно хранить не в холодильнике",
        topLeftText: "",
        topRightText: "",
        bottomLeftText: "",
        bottomRightText: "",
      },
      width: 170,
      height: 57,
      positionAbsolute: {
        x: 511.3559737806082,
        y: 598.5007609922625,
      },
      style: {
        width: 170,
        height: 57,
      },
      resizing: false,
      selected: false,
      dragging: false,
    },
    {
      id: "textNode-f17ff24b-077a-4b05-8f91-f4ab9d4b9a03",
      type: "textNode",
      position: {
        x: 255.52130138687454,
        y: 522.6258391416494,
      },
      data: {
        mainText: "безопасность",
      },
      width: 127,
      height: 41,
      positionAbsolute: {
        x: 255.52130138687454,
        y: 522.6258391416494,
      },
      style: {
        width: 127,
        height: 41,
      },
      resizing: false,
      selected: false,
      dragging: false,
    },
    {
      id: "textNodeWithBlocks-98e0a94b-91b5-4eb6-b730-6a15ba4ca614",
      type: "textNodeWithBlocks",
      position: {
        x: 259.4123230202392,
        y: 566.3998325170031,
      },
      data: {
        mainText: "безопасность\nбезвредность",
        topLeftText: "",
        topRightText: "",
        bottomLeftText: "",
        bottomRightText: "",
      },
      width: 154,
      height: 65,
      positionAbsolute: {
        x: 259.4123230202392,
        y: 566.3998325170031,
      },
      style: {
        width: 154,
        height: 65,
      },
      resizing: false,
      selected: false,
      dragging: false,
    },
    {
      id: "textNodeWithBlocks-f8e1be48-7f53-4247-89f6-4d9285f99390",
      type: "textNodeWithBlocks",
      position: {
        x: 256.4940567952158,
        y: 647.138531409322,
      },
      data: {
        mainText: "чтобы не оказался некачественным (испорченным)",
        topLeftText: "",
        topRightText: "",
        bottomLeftText: "",
        bottomRightText: "",
      },
      width: 195,
      height: 78,
      positionAbsolute: {
        x: 256.4940567952158,
        y: 647.138531409322,
      },
      style: {
        width: 195,
        height: 78,
      },
      resizing: false,
      selected: false,
      dragging: false,
    },
    {
      id: "textNode-fee8299a-e320-495e-b2f6-1e94bbe6fb31",
      type: "textNode",
      position: {
        x: 532.7565927641145,
        y: 716.2041654015468,
      },
      data: {
        mainText: "удобство упаковки",
      },
      width: 168,
      height: 42,
      positionAbsolute: {
        x: 532.7565927641145,
        y: 716.2041654015468,
      },
      style: {
        width: 168,
        height: 42,
      },
      resizing: false,
      selected: false,
      dragging: false,
    },
    {
      id: "textNodeWithBlocks-09b04512-f110-468b-9298-19e0ff80532a",
      type: "textNodeWithBlocks",
      position: {
        x: 543.4569022558676,
        y: 763.8691804102654,
      },
      data: {
        mainText: "удобно при открывании",
        topLeftText: "",
        topRightText: "",
        bottomLeftText: "",
        bottomRightText: "",
      },
      width: 155,
      height: 50,
      positionAbsolute: {
        x: 543.4569022558676,
        y: 763.8691804102654,
      },
      selected: false,
      dragging: false,
      style: {
        width: 155,
        height: 50,
      },
      resizing: false,
    },
    {
      id: "textNodeWithBlocks-d1774e6e-d3fc-463b-80d5-0e09c927b0fb",
      type: "textNodeWithBlocks",
      position: {
        x: 316.6476976955562,
        y: 754.0694742651481,
      },
      data: {
        mainText: "с крышечкой",
        topLeftText: "",
        topRightText: "",
        bottomLeftText: "",
        bottomRightText: "",
      },
      width: 133,
      height: 50,
      positionAbsolute: {
        x: 316.6476976955562,
        y: 754.0694742651481,
      },
      selected: false,
      dragging: false,
      style: {
        width: 133,
        height: 50,
      },
      resizing: false,
    },
    {
      id: "textNodeWithBlocks-9f3aeb21-4748-4bc1-b091-0e1a919815a4",
      type: "textNodeWithBlocks",
      position: {
        x: 94.76282936993118,
        y: 750.1423766009308,
      },
      data: {
        mainText: "не истек ли срок годности",
        topLeftText: "",
        topRightText: "",
        bottomLeftText: "",
        bottomRightText: "",
      },
      width: 159,
      height: 57,
      positionAbsolute: {
        x: 94.76282936993118,
        y: 750.1423766009308,
      },
      selected: false,
      dragging: false,
      style: {
        width: 159,
        height: 57,
      },
      resizing: false,
    },
    {
      id: "textNodeWithBlocks-09127d7f-b6b0-4d86-a71d-ac01579d8d20",
      type: "textNodeWithBlocks",
      position: {
        x: 112.11652972793769,
        y: 846.9618193273698,
      },
      data: {
        mainText: "целостность упаковки",
        topLeftText: "",
        topRightText: "",
        bottomLeftText: "",
        bottomRightText: "",
      },
      width: 163,
      height: 51,
      positionAbsolute: {
        x: 112.11652972793769,
        y: 846.9618193273698,
      },
      style: {
        width: 163,
        height: 51,
      },
      resizing: false,
      selected: false,
      dragging: false,
    },
    {
      id: "textNodeWithBlocks-7488fa9b-6556-45e1-a216-7415597f50c0",
      type: "textNodeWithBlocks",
      position: {
        x: 348.7009572712382,
        y: 832.3344121713993,
      },
      data: {
        mainText: "с чем-то интересным для детей",
        topLeftText: "",
        topRightText: "",
        bottomLeftText: "",
        bottomRightText: "",
      },
      width: 131,
      height: 74,
      positionAbsolute: {
        x: 348.7009572712382,
        y: 832.3344121713993,
      },
      style: {
        width: 131,
        height: 74,
      },
      resizing: false,
      selected: false,
      dragging: false,
    },
    {
      id: "textNodeWithBlocks-ff2b130f-0710-4bd1-84ee-2a7b38bc1128",
      type: "textNodeWithBlocks",
      position: {
        x: 560.9664996060092,
        y: 831.9620589941488,
      },
      data: {
        mainText: "с ложечкой",
        topLeftText: "",
        topRightText: "",
        bottomLeftText: "",
        bottomRightText: "",
      },
      width: 122,
      height: 50,
      positionAbsolute: {
        x: 560.9664996060092,
        y: 831.9620589941488,
      },
      style: {
        width: 122,
        height: 50,
      },
      resizing: false,
      selected: false,
      dragging: false,
    },
    {
      id: "textNodeWithBlocks-70068740-1c34-43af-ae9b-7efbbd5f7ec2",
      type: "textNodeWithBlocks",
      position: {
        x: 569.4932492272397,
        y: 913.7817413873668,
      },
      data: {
        mainText: "обтекаемая упаковка",
        topLeftText: "",
        topRightText: "",
        bottomLeftText: "",
        bottomRightText: "",
      },
      width: 138,
      height: 58,
      positionAbsolute: {
        x: 569.4932492272397,
        y: 913.7817413873668,
      },
      style: {
        width: 138,
        height: 58,
      },
      resizing: false,
      selected: false,
      dragging: false,
    },
    {
      id: "textNode-4b83e1a3-bd83-4bdd-88c7-4f066673d7a2",
      type: "textNode",
      position: {
        x: 886.8395614003088,
        y: 754.1416263268534,
      },
      data: {
        mainText: "ассортимент",
      },
      width: 135,
      height: 41,
      positionAbsolute: {
        x: 886.8395614003088,
        y: 754.1416263268534,
      },
      style: {
        width: 135,
        height: 41,
      },
      resizing: false,
      selected: false,
      dragging: false,
    },
    {
      id: "textNodeWithBlocks-f137f626-d422-415c-aae5-9002998cc3b7",
      type: "textNodeWithBlocks",
      position: {
        x: 821.6649490414487,
        y: 812.5069508273249,
      },
      data: {
        mainText: "широкий ассортимент по жирности",
        topLeftText: "",
        topRightText: "",
        bottomLeftText: "",
        bottomRightText: "",
      },
      width: 155,
      height: 75,
      positionAbsolute: {
        x: 821.6649490414487,
        y: 812.5069508273249,
      },
      style: {
        width: 155,
        height: 75,
      },
      resizing: false,
      selected: false,
      dragging: false,
    },
    {
      id: "textNodeWithBlocks-1c19ea92-17f8-4aa3-9518-1a682567f055",
      type: "textNodeWithBlocks",
      position: {
        x: 1039.886844787549,
        y: 791.2622288359537,
      },
      data: {
        mainText: "широкий ассортимент по вкусам",
        topLeftText: "",
        topRightText: "",
        bottomLeftText: "",
        bottomRightText: "",
      },
      width: 138,
      height: 82,
      positionAbsolute: {
        x: 1039.886844787549,
        y: 791.2622288359537,
      },
      style: {
        width: 138,
        height: 82,
      },
      resizing: false,
      selected: false,
      dragging: false,
    },
    {
      id: "textNodeWithBlocks-cf1ccaa9-0ec8-46f4-821a-710a24362e6e",
      type: "textNodeWithBlocks",
      position: {
        x: 1042.8051110125728,
        y: 888.5738457009255,
      },
      data: {
        mainText: "широкий\nассортимент по объёму упаковки",
        topLeftText: "",
        topRightText: "",
        bottomLeftText: "",
        bottomRightText: "",
      },
      width: 137,
      height: 96,
      positionAbsolute: {
        x: 1042.8051110125728,
        y: 888.5738457009255,
      },
      style: {
        width: 137,
        height: 96,
      },
      resizing: false,
      selected: false,
      dragging: false,
    },
    {
      id: "textNodeWithBlocks-7d7ea104-61d2-43bb-93d8-240be7f5cb92",
      type: "textNodeWithBlocks",
      position: {
        x: 819.1740826949025,
        y: 914.8955236127266,
      },
      data: {
        mainText: "широкий ассортимент по консистенции",
        topLeftText: "",
        topRightText: "",
        bottomLeftText: "",
        bottomRightText: "",
      },
      width: 175,
      height: 66,
      selected: false,
      positionAbsolute: {
        x: 819.1740826949025,
        y: 914.8955236127266,
      },
      dragging: false,
      resizing: false,
      style: {
        width: 175,
        height: 66,
      },
    },
    {
      id: "textNodeWithBlocks-83f3b76f-9d4d-4f2e-838b-800d04893e18",
      type: "textNodeWithBlocks",
      position: {
        x: 575.3733068795585,
        y: 992.2617988053623,
      },
      data: {
        mainText: "можно закрывать и открывать",
        topLeftText: "",
        topRightText: "",
        bottomLeftText: "",
        bottomRightText: "",
      },
      width: 176,
      height: 58,
      positionAbsolute: {
        x: 575.3733068795585,
        y: 992.2617988053623,
      },
      style: {
        width: 176,
        height: 58,
      },
      resizing: false,
      selected: false,
      dragging: false,
    },
    {
      id: "textNodeWithBlocks-72eb374c-efd6-417b-8374-e95b1e7004ee",
      type: "textNodeWithBlocks",
      position: {
        x: 325.05760497534004,
        y: 972.6263104842759,
      },
      data: {
        mainText: "устойчивая упаковка",
        topLeftText: "",
        topRightText: "",
        bottomLeftText: "",
        bottomRightText: "",
      },
      width: 168,
      height: 50,
      positionAbsolute: {
        x: 325.05760497534004,
        y: 972.6263104842759,
      },
      style: {
        width: 168,
        height: 50,
      },
      resizing: false,
      selected: false,
      dragging: false,
    },
    {
      id: "textNode-d7fad9af-4cfc-40b9-b758-a157a2136b12",
      type: "textNode",
      position: {
        x: 319.0542233748851,
        y: 1053.3890226843914,
      },
      data: {
        mainText: "упаковка",
      },
      width: 120,
      height: 43,
      positionAbsolute: {
        x: 319.0542233748851,
        y: 1053.3890226843914,
      },
      style: {
        width: 120,
        height: 43,
      },
      resizing: false,
      selected: false,
      dragging: false,
    },
    {
      id: "textNodeWithBlocks-d67b64c3-38c7-40fe-8e84-ed0cb7bd0a87",
      type: "textNodeWithBlocks",
      position: {
        x: 234.04656855558443,
        y: 1092.4143918613624,
      },
      data: {
        mainText: "красивая упаковка",
        topLeftText: "",
        topRightText: "",
        bottomLeftText: "",
        bottomRightText: "",
      },
      width: 157,
      height: 55,
      positionAbsolute: {
        x: 234.04656855558443,
        y: 1092.4143918613624,
      },
      style: {
        width: 157,
        height: 55,
      },
      resizing: false,
      selected: false,
      dragging: false,
    },
    {
      id: "textNodeWithBlocks-772d60ec-8b62-4614-9b68-5f1dfeaf1a47",
      type: "textNodeWithBlocks",
      position: {
        x: 459.4617569238534,
        y: 1095.061351966747,
      },
      data: {
        mainText: "экологичная упаковка",
        topLeftText: "",
        topRightText: "",
        bottomLeftText: "",
        bottomRightText: "",
      },
      width: 135,
      height: 63,
      positionAbsolute: {
        x: 459.4617569238534,
        y: 1095.061351966747,
      },
      style: {
        width: 135,
        height: 63,
      },
      resizing: false,
      selected: false,
      dragging: false,
    },
    {
      id: "textNodeWithBlocks-78c50558-d6a2-416c-b619-5c30dee8e319",
      type: "textNodeWithBlocks",
      position: {
        x: 235.2183498797691,
        y: 1170.5951576968828,
      },
      data: {
        mainText: "немятая\nупаковка",
        topLeftText: "",
        topRightText: "",
        bottomLeftText: "",
        bottomRightText: "",
      },
      width: 124,
      height: 68,
      positionAbsolute: {
        x: 235.2183498797691,
        y: 1170.5951576968828,
      },
      style: {
        width: 124,
        height: 68,
      },
      resizing: false,
      selected: false,
      dragging: false,
    },
    {
      id: "textNodeWithBlocks-f8ade5cb-4260-40fd-a32e-bf8685b4fa44",
      type: "textNodeWithBlocks",
      position: {
        x: 467.36651849512396,
        y: 1173.1482090655722,
      },
      data: {
        mainText: "яркая упаковка",
        topLeftText: "",
        topRightText: "",
        bottomLeftText: "",
        bottomRightText: "",
      },
      width: 148,
      height: 50,
      positionAbsolute: {
        x: 467.36651849512396,
        y: 1173.1482090655722,
      },
      style: {
        width: 148,
        height: 50,
      },
      resizing: false,
      selected: false,
      dragging: false,
    },
    {
      id: "textNodeWithBlocks-e137c701-8475-4e78-ba32-0fa580abb7c6",
      type: "textNodeWithBlocks",
      position: {
        x: 394.55913410911444,
        y: 1247.3585347430158,
      },
      data: {
        mainText: "прочная упаковка",
        topLeftText: "",
        topRightText: "",
        bottomLeftText: "",
        bottomRightText: "",
      },
      width: 140,
      height: 52,
      positionAbsolute: {
        x: 394.55913410911444,
        y: 1247.3585347430158,
      },
      style: {
        width: 140,
        height: 52,
      },
      resizing: false,
      selected: false,
      dragging: false,
    },
    {
      id: "textNode-ec2b8bb3-672b-4881-8c90-434231dab528",
      type: "textNode",
      position: {
        x: 709.596865103658,
        y: 1055.9926402959168,
      },
      data: {
        mainText: "этикетка",
      },
      width: 107,
      height: 44,
      positionAbsolute: {
        x: 709.596865103658,
        y: 1055.9926402959168,
      },
      resizing: false,
      style: {
        width: 107,
        height: 44,
      },
      selected: false,
      dragging: false,
    },
    {
      id: "textNodeWithBlocks-444b20c6-bacf-487b-beee-bab46b7a830a",
      type: "textNodeWithBlocks",
      position: {
        x: 701.8871447547543,
        y: 1096.3342657766036,
      },
      data: {
        mainText: "информативность этикетки",
        topLeftText: "",
        topRightText: "",
        bottomLeftText: "",
        bottomRightText: "",
      },
      width: 170,
      height: 66,
      positionAbsolute: {
        x: 701.8871447547543,
        y: 1096.3342657766036,
      },
      style: {
        width: 170,
        height: 66,
      },
      resizing: false,
      selected: false,
      dragging: false,
    },
    {
      id: "textNode-bfe9272b-a381-4ff8-ad65-6de0662c6a6c",
      type: "textNode",
      position: {
        x: 665.3353657077307,
        y: 1193.98437370675,
      },
      data: {
        mainText: "экономический показатель",
      },
      width: 211,
      height: 41,
      positionAbsolute: {
        x: 665.3353657077307,
        y: 1193.98437370675,
      },
      style: {
        width: 211,
        height: 41,
      },
      resizing: false,
      selected: false,
      dragging: false,
    },
    {
      id: "textNodeWithBlocks-05919067-3edf-4dda-941b-9d848031a448",
      type: "textNodeWithBlocks",
      position: {
        x: 617.9934812678263,
        y: 1269.575969428698,
      },
      data: {
        mainText: "цена",
        topLeftText: "",
        topRightText: "",
        bottomLeftText: "",
        bottomRightText: "",
      },
      width: 100,
      height: 50,
      positionAbsolute: {
        x: 617.9934812678263,
        y: 1269.575969428698,
      },
      style: {
        width: 100,
        height: 50,
      },
      resizing: false,
      selected: false,
      dragging: false,
    },
    {
      id: "textNodeWithBlocks-55bb36fc-9daf-480b-9f14-310964c3ef14",
      type: "textNodeWithBlocks",
      position: {
        x: 833.2687016511029,
        y: 1257.773005189116,
      },
      data: {
        mainText: "известность бренда",
        topLeftText: "",
        topRightText: "",
        bottomLeftText: "",
        bottomRightText: "",
      },
      width: 140,
      height: 50,
      positionAbsolute: {
        x: 833.2687016511029,
        y: 1257.773005189116,
      },
      style: {
        width: 140,
        height: 50,
      },
      resizing: false,
      selected: false,
      dragging: false,
    },
    {
      id: "textNode-b313b457-2fcb-4013-aed8-a9ffa6c33b5d",
      type: "textNode",
      position: {
        x: 995.9948023714253,
        y: 1078.1233899938804,
      },
      data: {
        mainText: "стабильность качества",
      },
      width: 200,
      height: 41,
      positionAbsolute: {
        x: 995.9948023714253,
        y: 1078.1233899938804,
      },
      style: {
        width: 200,
        height: 41,
      },
      resizing: false,
      selected: false,
      dragging: false,
    },
    {
      id: "textNodeWithBlocks-1d98a325-4669-45ce-b993-4aded22558df",
      type: "textNodeWithBlocks",
      position: {
        x: 1055.7960355414807,
        y: 1120.961205132023,
      },
      data: {
        mainText:
          "стабильность качества (беру только свой, проверенный продукт)",
        topLeftText: "",
        topRightText: "",
        bottomLeftText: "",
        bottomRightText: "",
      },
      width: 163,
      height: 138,
      positionAbsolute: {
        x: 1055.7960355414807,
        y: 1120.961205132023,
      },
      style: {
        width: 163,
        height: 138,
      },
      resizing: false,
      selected: true,
      dragging: false,
    },
  ],
  edges: [],
};

export const Temp3 = {
  nodes: [
    {
      id: "textNodeWithBlocks-e86a67c7-4f46-4d9c-9c47-602aceb409b9",
      type: "textNodeWithBlocks",
      position: {
        x: 459.0040211514977,
        y: -58.23845103254607,
      },
      data: {
        mainText: "наличие на полке в магазине",
        topLeftText: "",
        topRightText: "",
        bottomLeftText: "",
        bottomRightText: "",
      },
      width: 185,
      height: 53,
      positionAbsolute: {
        x: 459.0040211514977,
        y: -58.23845103254607,
      },
      style: {
        width: 185,
        height: 53,
      },
      resizing: false,
      selected: true,
      dragging: false,
    },
    {
      id: "textNodeWithBlocks-bc675bfb-e8fe-49a3-896d-dbc4e1196b3f",
      type: "textNodeWithBlocks",
      position: {
        x: 241.74933489687123,
        y: 12.48002467006512,
      },
      data: {
        mainText: "реклама",
        topLeftText: "",
        topRightText: "",
        bottomLeftText: "",
        bottomRightText: "",
      },
      width: 114,
      height: 50,
      positionAbsolute: {
        x: 241.74933489687123,
        y: 12.48002467006512,
      },
      style: {
        width: 114,
        height: 50,
      },
      resizing: false,
      selected: false,
      dragging: false,
    },
    {
      id: "textNodeWithBlocks-d71e08ac-5d89-46a6-b9f6-e34aaab7e27d",
      type: "textNodeWithBlocks",
      position: {
        x: 335.61081480059363,
        y: 191.99597355855025,
      },
      data: {
        mainText: "известность бренда",
        topLeftText: "",
        topRightText: "",
        bottomLeftText: "",
        bottomRightText: "",
      },
      width: 159,
      height: 50,
      positionAbsolute: {
        x: 335.61081480059363,
        y: 191.99597355855025,
      },
      style: {
        width: 159,
        height: 50,
      },
      resizing: false,
      selected: false,
      dragging: false,
    },
    {
      id: "textNodeWithBlocks-74c6e10c-db27-4279-a500-0a19d209f6fb",
      type: "textNodeWithBlocks",
      position: {
        x: 51.85119344138076,
        y: 118.78170168047546,
      },
      data: {
        mainText: "посоветовал знакомый",
        topLeftText: "",
        topRightText: "",
        bottomLeftText: "",
        bottomRightText: "",
      },
      width: 162,
      height: 54,
      positionAbsolute: {
        x: 51.85119344138076,
        y: 118.78170168047546,
      },
      selected: false,
      dragging: false,
      style: {
        width: 162,
        height: 54,
      },
      resizing: false,
    },
    {
      id: "textNodeWithBlocks-456f4b05-db15-4993-921f-ae9a16330763",
      type: "textNodeWithBlocks",
      position: {
        x: 656.8178698937727,
        y: 45.44182481052127,
      },
      data: {
        mainText: "приемлемая цена",
        topLeftText: "",
        topRightText: "",
        bottomLeftText: "",
        bottomRightText: "",
      },
      width: 137,
      height: 53,
      positionAbsolute: {
        x: 656.8178698937727,
        y: 45.44182481052127,
      },
      style: {
        width: 137,
        height: 53,
      },
      resizing: false,
      selected: false,
      dragging: false,
    },
    {
      id: "textNodeWithBlocks-867a2602-df45-4b38-b4da-7eaa39875170",
      type: "textNodeWithBlocks",
      position: {
        x: 502.85927939817043,
        y: 132.19841819635246,
      },
      data: {
        mainText: "доступность",
        topLeftText: "",
        topRightText: "",
        bottomLeftText: "",
        bottomRightText: "",
      },
      width: 136,
      height: 50,
      positionAbsolute: {
        x: 502.85927939817043,
        y: 132.19841819635246,
      },
      style: {
        width: 136,
        height: 50,
      },
      resizing: false,
      selected: false,
      dragging: false,
    },
    {
      id: "textNodeWithBlocks-38104654-f38b-479a-88bc-c16a17c74621",
      type: "textNodeWithBlocks",
      position: {
        x: -113.27762521556713,
        y: 221.98721334106784,
      },
      data: {
        mainText: "продукция долго на рынке",
        topLeftText: "",
        topRightText: "",
        bottomLeftText: "",
        bottomRightText: "",
      },
      width: 134,
      height: 77,
      positionAbsolute: {
        x: -113.27762521556713,
        y: 221.98721334106784,
      },
      style: {
        width: 134,
        height: 77,
      },
      resizing: false,
      selected: false,
      dragging: false,
    },
    {
      id: "textNodeWithBlocks-a57d5e68-87fa-4fbd-bad8-b18aa09021bd",
      type: "textNodeWithBlocks",
      position: {
        x: 170.53753185106234,
        y: 262.2373628886992,
      },
      data: {
        mainText: "известность продукта потребителю",
        topLeftText: "",
        topRightText: "",
        bottomLeftText: "",
        bottomRightText: "",
      },
      width: 156,
      height: 84,
      positionAbsolute: {
        x: 170.53753185106234,
        y: 262.2373628886992,
      },
      style: {
        width: 156,
        height: 84,
      },
      resizing: false,
      selected: false,
      dragging: false,
    },
    {
      id: "textNodeWithBlocks-84b109a4-37e5-4fd9-b75e-2d1911236568",
      type: "textNodeWithBlocks",
      position: {
        x: 418.2307598364844,
        y: 278.7502447543939,
      },
      data: {
        mainText: "Выбор потребителя йогурта",
        topLeftText: "",
        topRightText: "",
        bottomLeftText: "",
        bottomRightText: "",
      },
      width: 150,
      height: 82,
      positionAbsolute: {
        x: 418.2307598364844,
        y: 278.7502447543939,
      },
      selected: false,
      dragging: false,
      style: {
        width: 150,
        height: 82,
      },
      resizing: false,
    },
    {
      id: "textNodeWithBlocks-2c04ff6c-df7a-464a-bf90-94d24ad658fc",
      type: "textNodeWithBlocks",
      position: {
        x: -68.87836429495081,
        y: 465.5139208647037,
      },
      data: {
        mainText: "стабильное качество",
        topLeftText: "",
        topRightText: "",
        bottomLeftText: "",
        bottomRightText: "",
      },
      width: 130,
      height: 55,
      positionAbsolute: {
        x: -68.87836429495081,
        y: 465.5139208647037,
      },
      style: {
        width: 130,
        height: 55,
      },
      resizing: false,
      selected: false,
      dragging: false,
    },
    {
      id: "textNodeWithBlocks-87aec0e0-19a4-4bba-bfaf-f1e205f76a8d",
      type: "textNodeWithBlocks",
      position: {
        x: 202.53124046584605,
        y: 428.398236662253,
      },
      data: {
        mainText: "постоянно покупаю",
        topLeftText: "",
        topRightText: "",
        bottomLeftText: "",
        bottomRightText: "",
      },
      width: 137,
      height: 57,
      positionAbsolute: {
        x: 202.53124046584605,
        y: 428.398236662253,
      },
      style: {
        width: 137,
        height: 57,
      },
      resizing: false,
      selected: false,
      dragging: false,
    },
    {
      id: "textNodeWithBlocks-2c14a5ab-de74-4093-800b-dda4d522302d",
      type: "textNodeWithBlocks",
      position: {
        x: 172.1555773457278,
        y: 562.6947903164447,
      },
      data: {
        mainText: "нравится продукт",
        topLeftText: "",
        topRightText: "",
        bottomLeftText: "",
        bottomRightText: "",
      },
      width: 128,
      height: 57,
      positionAbsolute: {
        x: 172.1555773457278,
        y: 562.6947903164447,
      },
      style: {
        width: 128,
        height: 57,
      },
      resizing: false,
      selected: false,
      dragging: false,
    },
    {
      id: "textNodeWithBlocks-28fd4dd0-e7be-413c-ad7a-668ec34cf830",
      type: "textNodeWithBlocks",
      position: {
        x: 419.27571564204794,
        y: 488.25743342539675,
      },
      data: {
        mainText: "покупал раньше",
        topLeftText: "",
        topRightText: "",
        bottomLeftText: "",
        bottomRightText: "",
      },
      width: 119,
      height: 63,
      positionAbsolute: {
        x: 419.27571564204794,
        y: 488.25743342539675,
      },
      style: {
        width: 119,
        height: 63,
      },
      resizing: false,
      selected: false,
      dragging: false,
    },
    {
      id: "textNodeWithBlocks-ee4f27fe-4f6f-40ac-9d84-ddfac97330c3",
      type: "textNodeWithBlocks",
      position: {
        x: 426.5001014582894,
        y: 607.9758269516842,
      },
      data: {
        mainText: "хорошие органолептически свойства",
        topLeftText: "",
        topRightText: "",
        bottomLeftText: "",
        bottomRightText: "",
      },
      width: 176,
      height: 76,
      positionAbsolute: {
        x: 426.5001014582894,
        y: 607.9758269516842,
      },
      style: {
        width: 176,
        height: 76,
      },
      resizing: false,
      selected: false,
      dragging: false,
    },
    {
      id: "textNodeWithBlocks-de37db15-52dc-42b0-8a19-8e5d298f707d",
      type: "textNodeWithBlocks",
      position: {
        x: 642.1996208289277,
        y: 490.3215436586086,
      },
      data: {
        mainText: "интересно попробовать новое",
        topLeftText: "",
        topRightText: "",
        bottomLeftText: "",
        bottomRightText: "",
      },
      width: 154,
      height: 69,
      positionAbsolute: {
        x: 642.1996208289277,
        y: 490.3215436586086,
      },
      selected: false,
      dragging: false,
      style: {
        width: 154,
        height: 69,
      },
      resizing: false,
    },
    {
      id: "textNodeWithBlocks-bb075242-59f4-4505-8ac2-dd230301527c",
      type: "textNodeWithBlocks",
      position: {
        x: 851.7068094999307,
        y: 264.30147312191104,
      },
      data: {
        mainText: "упаковка продукта",
        topLeftText: "",
        topRightText: "",
        bottomLeftText: "",
        bottomRightText: "",
      },
      width: 129,
      height: 61,
      positionAbsolute: {
        x: 851.7068094999307,
        y: 264.30147312191104,
      },
      style: {
        width: 129,
        height: 61,
      },
      resizing: false,
      selected: false,
      dragging: false,
    },
    {
      id: "textNodeWithBlocks-d3ba29f4-63bc-4ee8-b9e3-b8a4275f365e",
      type: "textNodeWithBlocks",
      position: {
        x: 850.6747543833249,
        y: 130.13430796314077,
      },
      data: {
        mainText: "срок годности",
        topLeftText: "",
        topRightText: "",
        bottomLeftText: "",
        bottomRightText: "",
      },
      width: 112,
      height: 50,
      positionAbsolute: {
        x: 850.6747543833249,
        y: 130.13430796314077,
      },
      style: {
        width: 112,
        height: 50,
      },
      resizing: false,
      selected: false,
      dragging: false,
    },
    {
      id: "textNodeWithBlocks-c1e49655-6961-4a6b-9e2d-c32d0804dddc",
      type: "textNodeWithBlocks",
      position: {
        x: 1079.4766775715805,
        y: 136.841139797697,
      },
      data: {
        mainText: "удобная упаковка",
        topLeftText: "",
        topRightText: "",
        bottomLeftText: "",
        bottomRightText: "",
      },
      width: 121,
      height: 58,
      positionAbsolute: {
        x: 1079.4766775715805,
        y: 136.841139797697,
      },
      style: {
        width: 121,
        height: 58,
      },
      resizing: false,
      selected: false,
      dragging: false,
    },
    {
      id: "textNodeWithBlocks-a5310ebb-d06a-4311-9f82-5ad28fd4a8a8",
      type: "textNodeWithBlocks",
      position: {
        x: 1115.9129193510473,
        y: 253.98092195585178,
      },
      data: {
        mainText: "красивая упаковка",
        topLeftText: "",
        topRightText: "",
        bottomLeftText: "",
        bottomRightText: "",
      },
      width: 142,
      height: 59,
      positionAbsolute: {
        x: 1115.9129193510473,
        y: 253.98092195585178,
      },
      style: {
        width: 142,
        height: 59,
      },
      resizing: false,
      selected: false,
      dragging: false,
    },
    {
      id: "textNodeWithBlocks-eb49439d-a07d-4c66-abfc-66ea29b5a85e",
      type: "textNodeWithBlocks",
      position: {
        x: 920.8545023125275,
        y: 434.59056736188865,
      },
      data: {
        mainText: "этикетная надпись",
        topLeftText: "",
        topRightText: "",
        bottomLeftText: "",
        bottomRightText: "",
      },
      width: 122,
      height: 61,
      positionAbsolute: {
        x: 920.8545023125275,
        y: 434.59056736188865,
      },
      style: {
        width: 122,
        height: 61,
      },
      resizing: false,
      selected: false,
      dragging: false,
    },
    {
      id: "textNodeWithBlocks-f60233a9-7854-43f2-9b21-5d1aff2a1c99",
      type: "textNodeWithBlocks",
      position: {
        x: 1194.440647757058,
        y: 376.58442812163844,
      },
      data: {
        mainText: "калорийность",
        topLeftText: "",
        topRightText: "",
        bottomLeftText: "",
        bottomRightText: "",
      },
      width: 148,
      height: 50,
      positionAbsolute: {
        x: 1194.440647757058,
        y: 376.58442812163844,
      },
      style: {
        width: 148,
        height: 50,
      },
      resizing: false,
      selected: false,
      dragging: false,
    },
    {
      id: "textNodeWithBlocks-d02dfac8-bc74-4350-81ef-089bbf0e7a5b",
      type: "textNodeWithBlocks",
      position: {
        x: 898.149289747197,
        y: 599.7193860188369,
      },
      data: {
        mainText: "натуральный",
        topLeftText: "",
        topRightText: "",
        bottomLeftText: "",
        bottomRightText: "",
      },
      width: 134,
      height: 50,
      positionAbsolute: {
        x: 898.149289747197,
        y: 599.7193860188369,
      },
      selected: false,
      dragging: false,
      style: {
        width: 134,
        height: 50,
      },
      resizing: false,
    },
    {
      id: "textNodeWithBlocks-59e19488-ef5e-46aa-af91-2e6bc59dbc5f",
      type: "textNodeWithBlocks",
      position: {
        x: 1146.874572849225,
        y: 553.2769057715701,
      },
      data: {
        mainText: "полезный",
        topLeftText: "",
        topRightText: "",
        bottomLeftText: "",
        bottomRightText: "",
      },
      width: 111,
      height: 50,
      positionAbsolute: {
        x: 1146.874572849225,
        y: 553.2769057715701,
      },
      style: {
        width: 111,
        height: 50,
      },
      resizing: false,
      selected: false,
      dragging: false,
    },
    {
      id: "textNodeWithBlocks-753ef6e0-2f2a-447f-b4ed-a5f0c8dc6933",
      type: "textNodeWithBlocks",
      position: {
        x: 1114.8808642344413,
        y: 686.4120158137345,
      },
      data: {
        mainText: "без вредных добавок",
        topLeftText: "",
        topRightText: "",
        bottomLeftText: "",
        bottomRightText: "",
      },
      width: 133,
      height: 59,
      positionAbsolute: {
        x: 1114.8808642344413,
        y: 686.4120158137345,
      },
      style: {
        width: 133,
        height: 59,
      },
      resizing: false,
      selected: false,
      dragging: false,
    },
    {
      id: "textNodeWithBlocks-2fc8820f-6a9d-4eb6-be2b-eeed2ac7c5e3",
      type: "textNodeWithBlocks",
      position: {
        x: 853.7709197331423,
        y: 710.1492834956707,
      },
      data: {
        mainText: "наличие полезных компонентов",
        topLeftText: "",
        topRightText: "",
        bottomLeftText: "",
        bottomRightText: "",
      },
      width: 150,
      height: 70,
      positionAbsolute: {
        x: 853.7709197331423,
        y: 710.1492834956707,
      },
      style: {
        width: 150,
        height: 70,
      },
      resizing: false,
    },
  ],
  edges: [
    {
      markerStart: {
        type: "arrowclosed",
      },
      markerEnd: {
        type: "arrowclosed",
      },
      type: "defaultEdge",
      data: {
        color: "#000",
        countArrow: 2,
        edgeType: "Straight",
      },
      source: "textNodeWithBlocks-e86a67c7-4f46-4d9c-9c47-602aceb409b9",
      sourceHandle: "bottom-3",
      target: "textNodeWithBlocks-d71e08ac-5d89-46a6-b9f6-e34aaab7e27d",
      targetHandle: "top-4",
      id: "reactflow__edge-textNodeWithBlocks-e86a67c7-4f46-4d9c-9c47-602aceb409b9bottom-3-textNodeWithBlocks-d71e08ac-5d89-46a6-b9f6-e34aaab7e27dtop-4",
      selected: false,
    },
    {
      markerStart: {
        type: "arrowclosed",
      },
      markerEnd: {
        type: "arrowclosed",
      },
      type: "defaultEdge",
      data: {
        color: "#000",
        countArrow: 2,
        edgeType: "Straight",
      },
      source: "textNodeWithBlocks-bc675bfb-e8fe-49a3-896d-dbc4e1196b3f",
      sourceHandle: "bottom-6",
      target: "textNodeWithBlocks-d71e08ac-5d89-46a6-b9f6-e34aaab7e27d",
      targetHandle: "top-3",
      id: "reactflow__edge-textNodeWithBlocks-bc675bfb-e8fe-49a3-896d-dbc4e1196b3fbottom-6-textNodeWithBlocks-d71e08ac-5d89-46a6-b9f6-e34aaab7e27dtop-3",
      selected: false,
    },
    {
      markerStart: {
        type: "arrowclosed",
      },
      markerEnd: {
        type: "arrowclosed",
      },
      type: "defaultEdge",
      data: {
        color: "#000",
        countArrow: 2,
        edgeType: "Straight",
      },
      source: "textNodeWithBlocks-bc675bfb-e8fe-49a3-896d-dbc4e1196b3f",
      sourceHandle: "left-4",
      target: "textNodeWithBlocks-74c6e10c-db27-4279-a500-0a19d209f6fb",
      targetHandle: "top-3",
      id: "reactflow__edge-textNodeWithBlocks-bc675bfb-e8fe-49a3-896d-dbc4e1196b3fleft-4-textNodeWithBlocks-74c6e10c-db27-4279-a500-0a19d209f6fbtop-3",
      selected: false,
    },
    {
      markerStart: {
        type: "arrowclosed",
      },
      markerEnd: {
        type: "arrowclosed",
      },
      type: "defaultEdge",
      data: {
        color: "#000",
        countArrow: 2,
        edgeType: "Straight",
      },
      source: "textNodeWithBlocks-456f4b05-db15-4993-921f-ae9a16330763",
      sourceHandle: "left-4",
      target: "textNodeWithBlocks-74c6e10c-db27-4279-a500-0a19d209f6fb",
      targetHandle: "right-1",
      id: "reactflow__edge-textNodeWithBlocks-456f4b05-db15-4993-921f-ae9a16330763left-4-textNodeWithBlocks-74c6e10c-db27-4279-a500-0a19d209f6fbright-1",
      selected: false,
    },
    {
      markerStart: {
        type: "arrowclosed",
      },
      markerEnd: {
        type: "arrowclosed",
      },
      type: "defaultEdge",
      data: {
        color: "#000",
        countArrow: 2,
        edgeType: "Straight",
      },
      source: "textNodeWithBlocks-456f4b05-db15-4993-921f-ae9a16330763",
      sourceHandle: "bottom-1",
      target: "textNodeWithBlocks-867a2602-df45-4b38-b4da-7eaa39875170",
      targetHandle: "top-8",
      id: "reactflow__edge-textNodeWithBlocks-456f4b05-db15-4993-921f-ae9a16330763bottom-1-textNodeWithBlocks-867a2602-df45-4b38-b4da-7eaa39875170top-8",
      selected: false,
    },
    {
      markerStart: {
        type: "arrowclosed",
      },
      markerEnd: {
        type: "arrowclosed",
      },
      type: "defaultEdge",
      data: {
        color: "#000",
        countArrow: 2,
        edgeType: "Straight",
      },
      source: "textNodeWithBlocks-e86a67c7-4f46-4d9c-9c47-602aceb409b9",
      sourceHandle: "bottom-6",
      target: "textNodeWithBlocks-867a2602-df45-4b38-b4da-7eaa39875170",
      targetHandle: "top-5",
      id: "reactflow__edge-textNodeWithBlocks-e86a67c7-4f46-4d9c-9c47-602aceb409b9bottom-6-textNodeWithBlocks-867a2602-df45-4b38-b4da-7eaa39875170top-5",
      selected: false,
    },
    {
      markerStart: {
        type: "arrowclosed",
      },
      markerEnd: {
        type: "arrowclosed",
      },
      type: "defaultEdge",
      data: {
        color: "#000",
        countArrow: 2,
        edgeType: "Straight",
      },
      source: "textNodeWithBlocks-38104654-f38b-479a-88bc-c16a17c74621",
      sourceHandle: "top-4",
      target: "textNodeWithBlocks-74c6e10c-db27-4279-a500-0a19d209f6fb",
      targetHandle: "bottom-4",
      id: "reactflow__edge-textNodeWithBlocks-38104654-f38b-479a-88bc-c16a17c74621top-4-textNodeWithBlocks-74c6e10c-db27-4279-a500-0a19d209f6fbbottom-4",
      selected: false,
    },
    {
      markerStart: {
        type: "arrowclosed",
      },
      markerEnd: {
        type: "arrowclosed",
      },
      type: "defaultEdge",
      data: {
        color: "#000",
        countArrow: 2,
        edgeType: "Straight",
      },
      source: "textNodeWithBlocks-38104654-f38b-479a-88bc-c16a17c74621",
      sourceHandle: "right-3",
      target: "textNodeWithBlocks-a57d5e68-87fa-4fbd-bad8-b18aa09021bd",
      targetHandle: "left-3",
      id: "reactflow__edge-textNodeWithBlocks-38104654-f38b-479a-88bc-c16a17c74621right-3-textNodeWithBlocks-a57d5e68-87fa-4fbd-bad8-b18aa09021bdleft-3",
      selected: false,
    },
    {
      markerStart: {
        type: "arrowclosed",
      },
      markerEnd: {
        type: "arrowclosed",
      },
      type: "defaultEdge",
      data: {
        color: "#000",
        countArrow: 2,
        edgeType: "Straight",
      },
      source: "textNodeWithBlocks-74c6e10c-db27-4279-a500-0a19d209f6fb",
      sourceHandle: "bottom-6",
      target: "textNodeWithBlocks-a57d5e68-87fa-4fbd-bad8-b18aa09021bd",
      targetHandle: "top-2",
      id: "reactflow__edge-textNodeWithBlocks-74c6e10c-db27-4279-a500-0a19d209f6fbbottom-6-textNodeWithBlocks-a57d5e68-87fa-4fbd-bad8-b18aa09021bdtop-2",
      selected: false,
    },
    {
      markerStart: {
        type: "arrowclosed",
      },
      markerEnd: {
        type: "arrowclosed",
      },
      type: "defaultEdge",
      data: {
        color: "#000",
        countArrow: 2,
        edgeType: "Straight",
      },
      source: "textNodeWithBlocks-bc675bfb-e8fe-49a3-896d-dbc4e1196b3f",
      sourceHandle: "bottom-2",
      target: "textNodeWithBlocks-a57d5e68-87fa-4fbd-bad8-b18aa09021bd",
      targetHandle: "top-4",
      id: "reactflow__edge-textNodeWithBlocks-bc675bfb-e8fe-49a3-896d-dbc4e1196b3fbottom-2-textNodeWithBlocks-a57d5e68-87fa-4fbd-bad8-b18aa09021bdtop-4",
      selected: false,
    },
    {
      markerStart: {
        type: "arrowclosed",
      },
      markerEnd: {
        type: "arrowclosed",
      },
      type: "defaultEdge",
      data: {
        color: "#000",
        countArrow: 2,
        edgeType: "Straight",
      },
      source: "textNodeWithBlocks-d71e08ac-5d89-46a6-b9f6-e34aaab7e27d",
      sourceHandle: "left-5",
      target: "textNodeWithBlocks-a57d5e68-87fa-4fbd-bad8-b18aa09021bd",
      targetHandle: "top-5",
      id: "reactflow__edge-textNodeWithBlocks-d71e08ac-5d89-46a6-b9f6-e34aaab7e27dleft-5-textNodeWithBlocks-a57d5e68-87fa-4fbd-bad8-b18aa09021bdtop-5",
      selected: false,
    },
    {
      markerStart: {
        type: "arrowclosed",
      },
      markerEnd: {
        type: "arrowclosed",
      },
      type: "defaultEdge",
      data: {
        color: "#000",
        countArrow: 2,
        edgeType: "Straight",
      },
      source: "textNodeWithBlocks-a57d5e68-87fa-4fbd-bad8-b18aa09021bd",
      sourceHandle: "right-3",
      target: "textNodeWithBlocks-84b109a4-37e5-4fd9-b75e-2d1911236568",
      targetHandle: "left-4",
      id: "reactflow__edge-textNodeWithBlocks-a57d5e68-87fa-4fbd-bad8-b18aa09021bdright-3-textNodeWithBlocks-84b109a4-37e5-4fd9-b75e-2d1911236568left-4",
      selected: false,
    },
    {
      markerStart: {
        type: "arrowclosed",
      },
      markerEnd: {
        type: "arrowclosed",
      },
      type: "defaultEdge",
      data: {
        color: "#000",
        countArrow: 2,
        edgeType: "Straight",
      },
      source: "textNodeWithBlocks-d71e08ac-5d89-46a6-b9f6-e34aaab7e27d",
      sourceHandle: "bottom-5",
      target: "textNodeWithBlocks-84b109a4-37e5-4fd9-b75e-2d1911236568",
      targetHandle: "top-2",
      id: "reactflow__edge-textNodeWithBlocks-d71e08ac-5d89-46a6-b9f6-e34aaab7e27dbottom-5-textNodeWithBlocks-84b109a4-37e5-4fd9-b75e-2d1911236568top-2",
      selected: false,
    },
    {
      markerStart: {
        type: "arrowclosed",
      },
      markerEnd: {
        type: "arrowclosed",
      },
      type: "defaultEdge",
      data: {
        color: "#000",
        countArrow: 2,
        edgeType: "Straight",
      },
      source: "textNodeWithBlocks-867a2602-df45-4b38-b4da-7eaa39875170",
      sourceHandle: "bottom-5",
      target: "textNodeWithBlocks-84b109a4-37e5-4fd9-b75e-2d1911236568",
      targetHandle: "top-5",
      id: "reactflow__edge-textNodeWithBlocks-867a2602-df45-4b38-b4da-7eaa39875170bottom-5-textNodeWithBlocks-84b109a4-37e5-4fd9-b75e-2d1911236568top-5",
      selected: false,
    },
    {
      markerStart: {
        type: "arrowclosed",
      },
      markerEnd: {
        type: "arrowclosed",
      },
      type: "defaultEdge",
      data: {
        color: "#000",
        countArrow: 2,
        edgeType: "Straight",
      },
      source: "textNodeWithBlocks-2c04ff6c-df7a-464a-bf90-94d24ad658fc",
      sourceHandle: "top-1",
      target: "textNodeWithBlocks-38104654-f38b-479a-88bc-c16a17c74621",
      targetHandle: "bottom-2",
      id: "reactflow__edge-textNodeWithBlocks-2c04ff6c-df7a-464a-bf90-94d24ad658fctop-1-textNodeWithBlocks-38104654-f38b-479a-88bc-c16a17c74621bottom-2",
      selected: false,
    },
    {
      markerStart: {
        type: "arrowclosed",
      },
      markerEnd: {
        type: "arrowclosed",
      },
      type: "defaultEdge",
      data: {
        color: "#000",
        countArrow: 2,
        edgeType: "Straight",
      },
      source: "textNodeWithBlocks-2c04ff6c-df7a-464a-bf90-94d24ad658fc",
      sourceHandle: "top-8",
      target: "textNodeWithBlocks-74c6e10c-db27-4279-a500-0a19d209f6fb",
      targetHandle: "bottom-5",
      id: "reactflow__edge-textNodeWithBlocks-2c04ff6c-df7a-464a-bf90-94d24ad658fctop-8-textNodeWithBlocks-74c6e10c-db27-4279-a500-0a19d209f6fbbottom-5",
      selected: false,
    },
    {
      markerStart: {
        type: "arrowclosed",
      },
      markerEnd: {
        type: "arrowclosed",
      },
      type: "defaultEdge",
      data: {
        color: "#000",
        countArrow: 0,
        edgeType: "Straight",
      },
      source: "textNodeWithBlocks-87aec0e0-19a4-4bba-bfaf-f1e205f76a8d",
      sourceHandle: "top-5",
      target: "textNodeWithBlocks-a57d5e68-87fa-4fbd-bad8-b18aa09021bd",
      targetHandle: "bottom-6",
      id: "reactflow__edge-textNodeWithBlocks-87aec0e0-19a4-4bba-bfaf-f1e205f76a8dtop-5-textNodeWithBlocks-a57d5e68-87fa-4fbd-bad8-b18aa09021bdbottom-6",
      selected: false,
    },
    {
      markerStart: {
        type: "arrowclosed",
      },
      markerEnd: {
        type: "arrowclosed",
      },
      type: "defaultEdge",
      data: {
        color: "#000",
        countArrow: 2,
        edgeType: "Straight",
      },
      source: "textNodeWithBlocks-38104654-f38b-479a-88bc-c16a17c74621",
      sourceHandle: "bottom-7",
      target: "textNodeWithBlocks-87aec0e0-19a4-4bba-bfaf-f1e205f76a8d",
      targetHandle: "top-2",
      id: "reactflow__edge-textNodeWithBlocks-38104654-f38b-479a-88bc-c16a17c74621bottom-7-textNodeWithBlocks-87aec0e0-19a4-4bba-bfaf-f1e205f76a8dtop-2",
      selected: false,
    },
    {
      markerStart: {
        type: "arrowclosed",
      },
      markerEnd: {
        type: "arrowclosed",
      },
      type: "defaultEdge",
      data: {
        color: "#000",
        countArrow: 2,
        edgeType: "Straight",
      },
      source: "textNodeWithBlocks-87aec0e0-19a4-4bba-bfaf-f1e205f76a8d",
      sourceHandle: "right-2",
      target: "textNodeWithBlocks-84b109a4-37e5-4fd9-b75e-2d1911236568",
      targetHandle: "bottom-1",
      id: "reactflow__edge-textNodeWithBlocks-87aec0e0-19a4-4bba-bfaf-f1e205f76a8dright-2-textNodeWithBlocks-84b109a4-37e5-4fd9-b75e-2d1911236568bottom-1",
      selected: false,
    },
    {
      markerStart: {
        type: "arrowclosed",
      },
      markerEnd: {
        type: "arrowclosed",
      },
      type: "defaultEdge",
      data: {
        color: "#000",
        countArrow: 2,
        edgeType: "Straight",
      },
      source: "textNodeWithBlocks-2c14a5ab-de74-4093-800b-dda4d522302d",
      sourceHandle: "top-3",
      target: "textNodeWithBlocks-87aec0e0-19a4-4bba-bfaf-f1e205f76a8d",
      targetHandle: "bottom-4",
      id: "reactflow__edge-textNodeWithBlocks-2c14a5ab-de74-4093-800b-dda4d522302dtop-3-textNodeWithBlocks-87aec0e0-19a4-4bba-bfaf-f1e205f76a8dbottom-4",
      selected: false,
    },
    {
      markerStart: {
        type: "arrowclosed",
      },
      markerEnd: {
        type: "arrowclosed",
      },
      type: "defaultEdge",
      data: {
        color: "#000",
        countArrow: 2,
        edgeType: "Straight",
      },
      source: "textNodeWithBlocks-2c14a5ab-de74-4093-800b-dda4d522302d",
      sourceHandle: "top-7",
      target: "textNodeWithBlocks-28fd4dd0-e7be-413c-ad7a-668ec34cf830",
      targetHandle: "left-3",
      id: "reactflow__edge-textNodeWithBlocks-2c14a5ab-de74-4093-800b-dda4d522302dtop-7-textNodeWithBlocks-28fd4dd0-e7be-413c-ad7a-668ec34cf830left-3",
      selected: false,
    },
    {
      markerStart: {
        type: "arrowclosed",
      },
      markerEnd: {
        type: "arrowclosed",
      },
      type: "defaultEdge",
      data: {
        color: "#000",
        countArrow: 2,
        edgeType: "Straight",
      },
      source: "textNodeWithBlocks-28fd4dd0-e7be-413c-ad7a-668ec34cf830",
      sourceHandle: "top-5",
      target: "textNodeWithBlocks-84b109a4-37e5-4fd9-b75e-2d1911236568",
      targetHandle: "bottom-5",
      id: "reactflow__edge-textNodeWithBlocks-28fd4dd0-e7be-413c-ad7a-668ec34cf830top-5-textNodeWithBlocks-84b109a4-37e5-4fd9-b75e-2d1911236568bottom-5",
      selected: false,
    },
    {
      markerStart: {
        type: "arrowclosed",
      },
      markerEnd: {
        type: "arrowclosed",
      },
      type: "defaultEdge",
      data: {
        color: "#000",
        countArrow: 2,
        edgeType: "Straight",
      },
      source: "textNodeWithBlocks-ee4f27fe-4f6f-40ac-9d84-ddfac97330c3",
      sourceHandle: "left-4",
      target: "textNodeWithBlocks-2c14a5ab-de74-4093-800b-dda4d522302d",
      targetHandle: "right-6",
      id: "reactflow__edge-textNodeWithBlocks-ee4f27fe-4f6f-40ac-9d84-ddfac97330c3left-4-textNodeWithBlocks-2c14a5ab-de74-4093-800b-dda4d522302dright-6",
      selected: false,
    },
    {
      markerStart: {
        type: "arrowclosed",
      },
      markerEnd: {
        type: "arrowclosed",
      },
      type: "defaultEdge",
      data: {
        color: "#000",
        countArrow: 2,
        edgeType: "Straight",
      },
      source: "textNodeWithBlocks-ee4f27fe-4f6f-40ac-9d84-ddfac97330c3",
      sourceHandle: "top-5",
      target: "textNodeWithBlocks-28fd4dd0-e7be-413c-ad7a-668ec34cf830",
      targetHandle: "bottom-4",
      id: "reactflow__edge-textNodeWithBlocks-ee4f27fe-4f6f-40ac-9d84-ddfac97330c3top-5-textNodeWithBlocks-28fd4dd0-e7be-413c-ad7a-668ec34cf830bottom-4",
      selected: false,
    },
    {
      markerStart: {
        type: "arrowclosed",
      },
      markerEnd: {
        type: "arrowclosed",
      },
      type: "defaultEdge",
      data: {
        color: "#000",
        countArrow: 2,
        edgeType: "Straight",
      },
      source: "textNodeWithBlocks-de37db15-52dc-42b0-8a19-8e5d298f707d",
      sourceHandle: "top-3",
      target: "textNodeWithBlocks-84b109a4-37e5-4fd9-b75e-2d1911236568",
      targetHandle: "bottom-8",
      id: "reactflow__edge-textNodeWithBlocks-de37db15-52dc-42b0-8a19-8e5d298f707dtop-3-textNodeWithBlocks-84b109a4-37e5-4fd9-b75e-2d1911236568bottom-8",
      selected: false,
    },
    {
      markerStart: {
        type: "arrowclosed",
      },
      markerEnd: {
        type: "arrowclosed",
      },
      type: "defaultEdge",
      data: {
        color: "#000",
        countArrow: 2,
        edgeType: "Straight",
      },
      source: "textNodeWithBlocks-bb075242-59f4-4505-8ac2-dd230301527c",
      sourceHandle: "left-4",
      target: "textNodeWithBlocks-84b109a4-37e5-4fd9-b75e-2d1911236568",
      targetHandle: "right-4",
      id: "reactflow__edge-textNodeWithBlocks-bb075242-59f4-4505-8ac2-dd230301527cleft-4-textNodeWithBlocks-84b109a4-37e5-4fd9-b75e-2d1911236568right-4",
      selected: false,
    },
    {
      markerStart: {
        type: "arrowclosed",
      },
      markerEnd: {
        type: "arrowclosed",
      },
      type: "defaultEdge",
      data: {
        color: "#000",
        countArrow: 0,
        edgeType: "Straight",
      },
      source: "textNodeWithBlocks-d71e08ac-5d89-46a6-b9f6-e34aaab7e27d",
      sourceHandle: "right-6",
      target: "textNodeWithBlocks-bb075242-59f4-4505-8ac2-dd230301527c",
      targetHandle: "left-1",
      id: "reactflow__edge-textNodeWithBlocks-d71e08ac-5d89-46a6-b9f6-e34aaab7e27dright-6-textNodeWithBlocks-bb075242-59f4-4505-8ac2-dd230301527cleft-1",
      selected: false,
    },
    {
      markerStart: {
        type: "arrowclosed",
      },
      markerEnd: {
        type: "arrowclosed",
      },
      type: "defaultEdge",
      data: {
        color: "#000",
        countArrow: 2,
        edgeType: "Straight",
      },
      source: "textNodeWithBlocks-bb075242-59f4-4505-8ac2-dd230301527c",
      sourceHandle: "left-7",
      target: "textNodeWithBlocks-de37db15-52dc-42b0-8a19-8e5d298f707d",
      targetHandle: "top-6",
      id: "reactflow__edge-textNodeWithBlocks-bb075242-59f4-4505-8ac2-dd230301527cleft-7-textNodeWithBlocks-de37db15-52dc-42b0-8a19-8e5d298f707dtop-6",
      selected: false,
    },
    {
      markerStart: {
        type: "arrowclosed",
      },
      markerEnd: {
        type: "arrowclosed",
      },
      type: "defaultEdge",
      data: {
        color: "#000",
        countArrow: 2,
        edgeType: "Straight",
      },
      source: "textNodeWithBlocks-d3ba29f4-63bc-4ee8-b9e3-b8a4275f365e",
      sourceHandle: "bottom-4",
      target: "textNodeWithBlocks-bb075242-59f4-4505-8ac2-dd230301527c",
      targetHandle: "top-3",
      id: "reactflow__edge-textNodeWithBlocks-d3ba29f4-63bc-4ee8-b9e3-b8a4275f365ebottom-4-textNodeWithBlocks-bb075242-59f4-4505-8ac2-dd230301527ctop-3",
      selected: false,
    },
    {
      markerStart: {
        type: "arrowclosed",
      },
      markerEnd: {
        type: "arrowclosed",
      },
      type: "defaultEdge",
      data: {
        color: "#000",
        countArrow: 2,
        edgeType: "Straight",
      },
      source: "textNodeWithBlocks-c1e49655-6961-4a6b-9e2d-c32d0804dddc",
      sourceHandle: "bottom-1",
      target: "textNodeWithBlocks-bb075242-59f4-4505-8ac2-dd230301527c",
      targetHandle: "right-1",
      id: "reactflow__edge-textNodeWithBlocks-c1e49655-6961-4a6b-9e2d-c32d0804dddcbottom-1-textNodeWithBlocks-bb075242-59f4-4505-8ac2-dd230301527cright-1",
      selected: false,
    },
    {
      markerStart: {
        type: "arrowclosed",
      },
      markerEnd: {
        type: "arrowclosed",
      },
      type: "defaultEdge",
      data: {
        color: "#000",
        countArrow: 2,
        edgeType: "Straight",
      },
      source: "textNodeWithBlocks-a5310ebb-d06a-4311-9f82-5ad28fd4a8a8",
      sourceHandle: "left-5",
      target: "textNodeWithBlocks-bb075242-59f4-4505-8ac2-dd230301527c",
      targetHandle: "right-5",
      id: "reactflow__edge-textNodeWithBlocks-a5310ebb-d06a-4311-9f82-5ad28fd4a8a8left-5-textNodeWithBlocks-bb075242-59f4-4505-8ac2-dd230301527cright-5",
      selected: false,
    },
    {
      markerStart: {
        type: "arrowclosed",
      },
      markerEnd: {
        type: "arrowclosed",
      },
      type: "defaultEdge",
      data: {
        color: "#000",
        countArrow: 2,
        edgeType: "Straight",
      },
      source: "textNodeWithBlocks-eb49439d-a07d-4c66-abfc-66ea29b5a85e",
      sourceHandle: "left-6",
      target: "textNodeWithBlocks-de37db15-52dc-42b0-8a19-8e5d298f707d",
      targetHandle: "right-4",
      id: "reactflow__edge-textNodeWithBlocks-eb49439d-a07d-4c66-abfc-66ea29b5a85eleft-6-textNodeWithBlocks-de37db15-52dc-42b0-8a19-8e5d298f707dright-4",
      selected: false,
    },
    {
      markerStart: {
        type: "arrowclosed",
      },
      markerEnd: {
        type: "arrowclosed",
      },
      type: "defaultEdge",
      data: {
        color: "#000",
        countArrow: 2,
        edgeType: "Straight",
      },
      source: "textNodeWithBlocks-eb49439d-a07d-4c66-abfc-66ea29b5a85e",
      sourceHandle: "top-3",
      target: "textNodeWithBlocks-bb075242-59f4-4505-8ac2-dd230301527c",
      targetHandle: "bottom-4",
      id: "reactflow__edge-textNodeWithBlocks-eb49439d-a07d-4c66-abfc-66ea29b5a85etop-3-textNodeWithBlocks-bb075242-59f4-4505-8ac2-dd230301527cbottom-4",
      selected: false,
    },
    {
      markerStart: {
        type: "arrowclosed",
      },
      markerEnd: {
        type: "arrowclosed",
      },
      type: "defaultEdge",
      data: {
        color: "#000",
        countArrow: 2,
        edgeType: "Straight",
      },
      source: "textNodeWithBlocks-f60233a9-7854-43f2-9b21-5d1aff2a1c99",
      sourceHandle: "left-6",
      target: "textNodeWithBlocks-eb49439d-a07d-4c66-abfc-66ea29b5a85e",
      targetHandle: "right-4",
      id: "reactflow__edge-textNodeWithBlocks-f60233a9-7854-43f2-9b21-5d1aff2a1c99left-6-textNodeWithBlocks-eb49439d-a07d-4c66-abfc-66ea29b5a85eright-4",
      selected: false,
    },
    {
      markerStart: {
        type: "arrowclosed",
      },
      markerEnd: {
        type: "arrowclosed",
      },
      type: "defaultEdge",
      data: {
        color: "#000",
        countArrow: 2,
        edgeType: "Straight",
      },
      source: "textNodeWithBlocks-d02dfac8-bc74-4350-81ef-089bbf0e7a5b",
      sourceHandle: "top-5",
      target: "textNodeWithBlocks-eb49439d-a07d-4c66-abfc-66ea29b5a85e",
      targetHandle: "bottom-4",
      id: "reactflow__edge-textNodeWithBlocks-d02dfac8-bc74-4350-81ef-089bbf0e7a5btop-5-textNodeWithBlocks-eb49439d-a07d-4c66-abfc-66ea29b5a85ebottom-4",
      selected: false,
    },
    {
      markerStart: {
        type: "arrowclosed",
      },
      markerEnd: {
        type: "arrowclosed",
      },
      type: "defaultEdge",
      data: {
        color: "#000",
        countArrow: 2,
        edgeType: "Straight",
      },
      source: "textNodeWithBlocks-d02dfac8-bc74-4350-81ef-089bbf0e7a5b",
      sourceHandle: "left-4",
      target: "textNodeWithBlocks-ee4f27fe-4f6f-40ac-9d84-ddfac97330c3",
      targetHandle: "right-4",
      id: "reactflow__edge-textNodeWithBlocks-d02dfac8-bc74-4350-81ef-089bbf0e7a5bleft-4-textNodeWithBlocks-ee4f27fe-4f6f-40ac-9d84-ddfac97330c3right-4",
      selected: false,
    },
    {
      markerStart: {
        type: "arrowclosed",
      },
      markerEnd: {
        type: "arrowclosed",
      },
      type: "defaultEdge",
      data: {
        color: "#000",
        countArrow: 2,
        edgeType: "Straight",
      },
      source: "textNodeWithBlocks-59e19488-ef5e-46aa-af91-2e6bc59dbc5f",
      sourceHandle: "top-4",
      target: "textNodeWithBlocks-eb49439d-a07d-4c66-abfc-66ea29b5a85e",
      targetHandle: "right-7",
      id: "reactflow__edge-textNodeWithBlocks-59e19488-ef5e-46aa-af91-2e6bc59dbc5ftop-4-textNodeWithBlocks-eb49439d-a07d-4c66-abfc-66ea29b5a85eright-7",
      selected: false,
    },
    {
      markerStart: {
        type: "arrowclosed",
      },
      markerEnd: {
        type: "arrowclosed",
      },
      type: "defaultEdge",
      data: {
        color: "#000",
        countArrow: 0,
        edgeType: "Straight",
      },
      source: "textNodeWithBlocks-59e19488-ef5e-46aa-af91-2e6bc59dbc5f",
      sourceHandle: "left-3",
      target: "textNodeWithBlocks-d02dfac8-bc74-4350-81ef-089bbf0e7a5b",
      targetHandle: "right-4",
      id: "reactflow__edge-textNodeWithBlocks-59e19488-ef5e-46aa-af91-2e6bc59dbc5fleft-3-textNodeWithBlocks-d02dfac8-bc74-4350-81ef-089bbf0e7a5bright-4",
      selected: false,
    },
    {
      markerStart: {
        type: "arrowclosed",
      },
      markerEnd: {
        type: "arrowclosed",
      },
      type: "defaultEdge",
      data: {
        color: "#000",
        countArrow: 2,
        edgeType: "Straight",
      },
      source: "textNodeWithBlocks-753ef6e0-2f2a-447f-b4ed-a5f0c8dc6933",
      sourceHandle: "top-8",
      target: "textNodeWithBlocks-59e19488-ef5e-46aa-af91-2e6bc59dbc5f",
      targetHandle: "bottom-8",
      id: "reactflow__edge-textNodeWithBlocks-753ef6e0-2f2a-447f-b4ed-a5f0c8dc6933top-8-textNodeWithBlocks-59e19488-ef5e-46aa-af91-2e6bc59dbc5fbottom-8",
      selected: false,
    },
    {
      markerStart: {
        type: "arrowclosed",
      },
      markerEnd: {
        type: "arrowclosed",
      },
      type: "defaultEdge",
      data: {
        color: "#000",
        countArrow: 2,
        edgeType: "Straight",
      },
      source: "textNodeWithBlocks-753ef6e0-2f2a-447f-b4ed-a5f0c8dc6933",
      sourceHandle: "top-1",
      target: "textNodeWithBlocks-d02dfac8-bc74-4350-81ef-089bbf0e7a5b",
      targetHandle: "right-7",
      id: "reactflow__edge-textNodeWithBlocks-753ef6e0-2f2a-447f-b4ed-a5f0c8dc6933top-1-textNodeWithBlocks-d02dfac8-bc74-4350-81ef-089bbf0e7a5bright-7",
      selected: false,
    },
    {
      markerStart: {
        type: "arrowclosed",
      },
      markerEnd: {
        type: "arrowclosed",
      },
      type: "defaultEdge",
      data: {
        color: "#000",
        countArrow: 2,
        edgeType: "Straight",
      },
      source: "textNodeWithBlocks-2fc8820f-6a9d-4eb6-be2b-eeed2ac7c5e3",
      sourceHandle: "top-5",
      target: "textNodeWithBlocks-d02dfac8-bc74-4350-81ef-089bbf0e7a5b",
      targetHandle: "bottom-4",
      id: "reactflow__edge-textNodeWithBlocks-2fc8820f-6a9d-4eb6-be2b-eeed2ac7c5e3top-5-textNodeWithBlocks-d02dfac8-bc74-4350-81ef-089bbf0e7a5bbottom-4",
      selected: false,
    },
    {
      markerStart: {
        type: "arrowclosed",
      },
      markerEnd: {
        type: "arrowclosed",
      },
      type: "defaultEdge",
      data: {
        color: "#000",
        countArrow: 2,
        edgeType: "Straight",
      },
      source: "textNodeWithBlocks-2c04ff6c-df7a-464a-bf90-94d24ad658fc",
      sourceHandle: "right-5",
      target: "textNodeWithBlocks-87aec0e0-19a4-4bba-bfaf-f1e205f76a8d",
      targetHandle: "left-5",
      id: "reactflow__edge-textNodeWithBlocks-2c04ff6c-df7a-464a-bf90-94d24ad658fcright-5-textNodeWithBlocks-87aec0e0-19a4-4bba-bfaf-f1e205f76a8dleft-5",
      selected: false,
    },
    {
      markerStart: {
        type: "arrowclosed",
      },
      markerEnd: {
        type: "arrowclosed",
      },
      type: "defaultEdge",
      data: {
        color: "#000",
        countArrow: 2,
      },
      source: "textNodeWithBlocks-456f4b05-db15-4993-921f-ae9a16330763",
      sourceHandle: "bottom-4",
      target: "textNodeWithBlocks-87aec0e0-19a4-4bba-bfaf-f1e205f76a8d",
      targetHandle: "right-4",
      id: "reactflow__edge-textNodeWithBlocks-456f4b05-db15-4993-921f-ae9a16330763bottom-4-textNodeWithBlocks-87aec0e0-19a4-4bba-bfaf-f1e205f76a8dright-4",
    },
  ],
};

export const Temp4 = {
  nodes: [
    {
      id: "circleNode-cc979b61-fee8-4ae1-8729-0d382f85f925",
      type: "circleNode",
      position: {
        x: 27.446378319355205,
        y: 150.2634061316424,
      },
      style: {
        width: 80,
        height: 80,
        zIndex: 0,
        position: "absolute",
      },
      data: {
        mainText: "1",
      },
      width: 80,
      height: 80,
      resizing: false,
      positionAbsolute: {
        x: 27.446378319355205,
        y: 150.2634061316424,
      },
      selected: false,
      dragging: false,
    },
    {
      id: "circleNode-3931a4c0-d983-4f00-9db8-cc769091205e",
      type: "circleNode",
      position: {
        x: 154.61677845373814,
        y: 0.029651361119775288,
      },
      style: {
        width: 80,
        height: 80,
        zIndex: 0,
        position: "absolute",
      },
      data: {
        mainText: "2",
      },
      width: 80,
      height: 80,
      positionAbsolute: {
        x: 154.61677845373814,
        y: 0.029651361119775288,
      },
      selected: false,
      dragging: false,
      resizing: false,
    },
    {
      id: "circleNode-1bb832bb-30bf-49f0-b28b-98ee8ee45799",
      type: "circleNode",
      position: {
        x: 154.07401867738284,
        y: 98.02713798553334,
      },
      style: {
        width: 80,
        height: 80,
        zIndex: 0,
        position: "absolute",
      },
      data: {
        mainText: "3",
      },
      width: 80,
      height: 80,
      positionAbsolute: {
        x: 154.07401867738284,
        y: 98.02713798553334,
      },
      selected: false,
      dragging: false,
      resizing: false,
    },
    {
      id: "circleNode-607341bb-5feb-4e30-b5d1-d5e332b8648b",
      type: "circleNode",
      position: {
        x: 154.57401867738287,
        y: 195.33847070690715,
      },
      style: {
        width: 80,
        height: 80,
        zIndex: 0,
        position: "absolute",
      },
      data: {
        mainText: "4",
      },
      width: 80,
      height: 80,
      positionAbsolute: {
        x: 154.57401867738287,
        y: 195.33847070690715,
      },
      selected: false,
      dragging: false,
      resizing: false,
    },
    {
      id: "circleNode-9b5a7fd7-67de-4804-85b9-199fb41a0fce",
      type: "circleNode",
      position: {
        x: 154.6921563078434,
        y: 292.12235395012283,
      },
      style: {
        width: 80,
        height: 80,
        zIndex: 0,
        position: "absolute",
      },
      data: {
        mainText: "5",
      },
      width: 80,
      height: 80,
      positionAbsolute: {
        x: 154.6921563078434,
        y: 292.12235395012283,
      },
      selected: false,
      dragging: false,
      resizing: false,
    },
    {
      id: "circleNode-6430706e-d753-4cba-9490-0a211eb95f12",
      type: "circleNode",
      position: {
        x: 289.28009310840946,
        y: 148.45904119568155,
      },
      style: {
        width: 80,
        height: 80,
        zIndex: 0,
        position: "absolute",
      },
      data: {
        mainText: "6",
      },
      width: 80,
      height: 80,
      positionAbsolute: {
        x: 289.28009310840946,
        y: 148.45904119568155,
      },
      selected: false,
      dragging: false,
      resizing: false,
    },
    {
      id: "circleNode-55e730b8-0a2d-44a8-b034-af26f9f3cb7c",
      type: "circleNode",
      position: {
        x: 527.5057446195596,
        y: 20.158352070729904,
      },
      style: {
        width: 80,
        height: 80,
        zIndex: 0,
        position: "absolute",
      },
      data: {
        mainText: "7",
      },
      width: 80,
      height: 80,
      positionAbsolute: {
        x: 527.5057446195596,
        y: 20.158352070729904,
      },
      selected: false,
      dragging: false,
      resizing: false,
    },
    {
      id: "circleNode-8a23e4b0-a5fc-42fb-9b4f-12cfeb00df81",
      type: "circleNode",
      position: {
        x: 444.9764285153469,
        y: 147.50533572429188,
      },
      style: {
        width: 80,
        height: 80,
        zIndex: 0,
        position: "absolute",
      },
      data: {
        mainText: "8",
      },
      width: 80,
      height: 80,
      selected: false,
      positionAbsolute: {
        x: 444.9764285153469,
        y: 147.50533572429188,
      },
      dragging: false,
      resizing: false,
    },
    {
      id: "circleNode-9fcdb024-af5a-43b8-943a-e76126d60b0a",
      type: "circleNode",
      position: {
        x: 601.1064139003245,
        y: 147.50533572429188,
      },
      style: {
        width: 80,
        height: 80,
        zIndex: 0,
        position: "absolute",
      },
      data: {
        mainText: "9",
      },
      width: 80,
      height: 80,
      positionAbsolute: {
        x: 601.1064139003245,
        y: 147.50533572429188,
      },
      selected: false,
      dragging: false,
      resizing: false,
    },
    {
      id: "circleNode-f8cc4a65-0595-4c37-9d9f-24eb7e0e481c",
      type: "circleNode",
      position: {
        x: 756.5422348401396,
        y: 145.33805927498676,
      },
      style: {
        width: 80,
        height: 80,
        zIndex: 0,
        position: "absolute",
      },
      data: {
        mainText: "10",
      },
      width: 80,
      height: 80,
      positionAbsolute: {
        x: 756.5422348401396,
        y: 145.33805927498676,
      },
      selected: false,
      dragging: false,
      resizing: false,
    },
    {
      id: "circleNode-dc5d8bf5-81f1-4de5-a2c7-9d27cfc181b4",
      type: "circleNode",
      position: {
        x: 916.6715712645204,
        y: 147.83805927498676,
      },
      style: {
        width: 80,
        height: 80,
        zIndex: 0,
        position: "absolute",
      },
      data: {
        mainText: "11",
      },
      width: 80,
      height: 80,
      positionAbsolute: {
        x: 916.6715712645204,
        y: 147.83805927498676,
      },
      selected: true,
      dragging: false,
      resizing: false,
    },
  ],
  edges: [
    {
      markerStart: {
        type: "arrowclosed",
      },
      markerEnd: {
        type: "arrowclosed",
      },
      type: "defaultEdge",
      data: {
        edgeType: "Straight",
        color: "#000",
        countArrow: 2,
      },
      source: "circleNode-cc979b61-fee8-4ae1-8729-0d382f85f925",
      sourceHandle: "top-5",
      target: "circleNode-3931a4c0-d983-4f00-9db8-cc769091205e",
      targetHandle: "left-4",
      id: "reactflow__edge-circleNode-cc979b61-fee8-4ae1-8729-0d382f85f925top-5-circleNode-3931a4c0-d983-4f00-9db8-cc769091205eleft-4",
      selected: false,
    },
    {
      markerStart: {
        type: "arrowclosed",
      },
      markerEnd: {
        type: "arrowclosed",
      },
      type: "defaultEdge",
      data: {
        edgeType: "Straight",
        color: "#000",
        countArrow: 2,
      },
      source: "circleNode-cc979b61-fee8-4ae1-8729-0d382f85f925",
      sourceHandle: "right-3",
      target: "circleNode-1bb832bb-30bf-49f0-b28b-98ee8ee45799",
      targetHandle: "left-4",
      id: "reactflow__edge-circleNode-cc979b61-fee8-4ae1-8729-0d382f85f925right-3-circleNode-1bb832bb-30bf-49f0-b28b-98ee8ee45799left-4",
      selected: false,
    },
    {
      markerStart: {
        type: "arrowclosed",
      },
      markerEnd: {
        type: "arrowclosed",
      },
      type: "defaultEdge",
      data: {
        edgeType: "Straight",
        color: "#000",
        countArrow: 2,
      },
      source: "circleNode-cc979b61-fee8-4ae1-8729-0d382f85f925",
      sourceHandle: "right-5",
      target: "circleNode-607341bb-5feb-4e30-b5d1-d5e332b8648b",
      targetHandle: "left-4",
      id: "reactflow__edge-circleNode-cc979b61-fee8-4ae1-8729-0d382f85f925right-5-circleNode-607341bb-5feb-4e30-b5d1-d5e332b8648bleft-4",
      selected: false,
    },
    {
      markerStart: {
        type: "arrowclosed",
      },
      markerEnd: {
        type: "arrowclosed",
      },
      type: "defaultEdge",
      data: {
        edgeType: "Straight",
        color: "#000",
        countArrow: 2,
      },
      source: "circleNode-cc979b61-fee8-4ae1-8729-0d382f85f925",
      sourceHandle: "bottom-5",
      target: "circleNode-9b5a7fd7-67de-4804-85b9-199fb41a0fce",
      targetHandle: "left-4",
      id: "reactflow__edge-circleNode-cc979b61-fee8-4ae1-8729-0d382f85f925bottom-5-circleNode-9b5a7fd7-67de-4804-85b9-199fb41a0fceleft-4",
      selected: false,
    },
    {
      markerStart: {
        type: "arrowclosed",
      },
      markerEnd: {
        type: "arrowclosed",
      },
      type: "defaultEdge",
      data: {
        edgeType: "Straight",
        color: "#000",
        countArrow: 2,
      },
      source: "circleNode-3931a4c0-d983-4f00-9db8-cc769091205e",
      sourceHandle: "right-6",
      target: "circleNode-6430706e-d753-4cba-9490-0a211eb95f12",
      targetHandle: "top-3",
      selected: false,
      id: "reactflow__edge-circleNode-3931a4c0-d983-4f00-9db8-cc769091205eright-6-circleNode-6430706e-d753-4cba-9490-0a211eb95f12top-3",
    },
    {
      markerStart: {
        type: "arrowclosed",
      },
      markerEnd: {
        type: "arrowclosed",
      },
      type: "defaultEdge",
      data: {
        edgeType: "Straight",
        color: "#000",
        countArrow: 2,
      },
      source: "circleNode-1bb832bb-30bf-49f0-b28b-98ee8ee45799",
      sourceHandle: "right-5",
      target: "circleNode-6430706e-d753-4cba-9490-0a211eb95f12",
      targetHandle: "left-4",
      selected: false,
      id: "reactflow__edge-circleNode-1bb832bb-30bf-49f0-b28b-98ee8ee45799right-5-circleNode-6430706e-d753-4cba-9490-0a211eb95f12left-4",
    },
    {
      markerStart: {
        type: "arrowclosed",
      },
      markerEnd: {
        type: "arrowclosed",
      },
      type: "defaultEdge",
      data: {
        edgeType: "Straight",
        color: "#000",
        countArrow: 2,
      },
      source: "circleNode-607341bb-5feb-4e30-b5d1-d5e332b8648b",
      sourceHandle: "right-4",
      target: "circleNode-6430706e-d753-4cba-9490-0a211eb95f12",
      targetHandle: "left-5",
      id: "reactflow__edge-circleNode-607341bb-5feb-4e30-b5d1-d5e332b8648bright-4-circleNode-6430706e-d753-4cba-9490-0a211eb95f12left-5",
      selected: false,
    },
    {
      markerStart: {
        type: "arrowclosed",
      },
      markerEnd: {
        type: "arrowclosed",
      },
      type: "defaultEdge",
      data: {
        edgeType: "Straight",
        color: "#000",
        countArrow: 2,
      },
      source: "circleNode-9b5a7fd7-67de-4804-85b9-199fb41a0fce",
      sourceHandle: "right-5",
      target: "circleNode-6430706e-d753-4cba-9490-0a211eb95f12",
      targetHandle: "bottom-4",
      id: "reactflow__edge-circleNode-9b5a7fd7-67de-4804-85b9-199fb41a0fceright-5-circleNode-6430706e-d753-4cba-9490-0a211eb95f12bottom-4",
      selected: false,
    },
    {
      markerStart: {
        type: "arrowclosed",
      },
      markerEnd: {
        type: "arrowclosed",
      },
      type: "defaultEdge",
      data: {
        edgeType: "Straight",
        color: "#000",
        countArrow: 2,
      },
      source: "circleNode-6430706e-d753-4cba-9490-0a211eb95f12",
      sourceHandle: "top-6",
      target: "circleNode-55e730b8-0a2d-44a8-b034-af26f9f3cb7c",
      targetHandle: "left-4",
      id: "reactflow__edge-circleNode-6430706e-d753-4cba-9490-0a211eb95f12top-6-circleNode-55e730b8-0a2d-44a8-b034-af26f9f3cb7cleft-4",
      selected: false,
    },
    {
      markerStart: {
        type: "arrowclosed",
      },
      markerEnd: {
        type: "arrowclosed",
      },
      type: "defaultEdge",
      data: {
        edgeType: "Straight",
        color: "#000",
        countArrow: 2,
      },
      source: "circleNode-6430706e-d753-4cba-9490-0a211eb95f12",
      sourceHandle: "right-4",
      target: "circleNode-8a23e4b0-a5fc-42fb-9b4f-12cfeb00df81",
      targetHandle: "left-4",
      id: "reactflow__edge-circleNode-6430706e-d753-4cba-9490-0a211eb95f12right-4-circleNode-8a23e4b0-a5fc-42fb-9b4f-12cfeb00df81left-4",
      selected: false,
    },
    {
      markerStart: {
        type: "arrowclosed",
      },
      markerEnd: {
        type: "arrowclosed",
      },
      type: "defaultEdge",
      data: {
        edgeType: "Straight",
        color: "#000",
        countArrow: 2,
      },
      source: "circleNode-8a23e4b0-a5fc-42fb-9b4f-12cfeb00df81",
      sourceHandle: "right-4",
      target: "circleNode-9fcdb024-af5a-43b8-943a-e76126d60b0a",
      targetHandle: "left-4",
      id: "reactflow__edge-circleNode-8a23e4b0-a5fc-42fb-9b4f-12cfeb00df81right-4-circleNode-9fcdb024-af5a-43b8-943a-e76126d60b0aleft-4",
      selected: false,
    },
    {
      markerStart: {
        type: "arrowclosed",
      },
      markerEnd: {
        type: "arrowclosed",
      },
      type: "defaultEdge",
      data: {
        edgeType: "Straight",
        color: "#000",
        countArrow: 2,
      },
      source: "circleNode-9fcdb024-af5a-43b8-943a-e76126d60b0a",
      sourceHandle: "right-4",
      target: "circleNode-f8cc4a65-0595-4c37-9d9f-24eb7e0e481c",
      targetHandle: "left-4",
      id: "reactflow__edge-circleNode-9fcdb024-af5a-43b8-943a-e76126d60b0aright-4-circleNode-f8cc4a65-0595-4c37-9d9f-24eb7e0e481cleft-4",
      selected: false,
    },
    {
      markerStart: {
        type: "arrowclosed",
      },
      markerEnd: {
        type: "arrowclosed",
      },
      type: "defaultEdge",
      data: {
        edgeType: "Straight",
        color: "#000",
        countArrow: 2,
      },
      source: "circleNode-55e730b8-0a2d-44a8-b034-af26f9f3cb7c",
      sourceHandle: "right-5",
      target: "circleNode-f8cc4a65-0595-4c37-9d9f-24eb7e0e481c",
      targetHandle: "top-3",
      id: "reactflow__edge-circleNode-55e730b8-0a2d-44a8-b034-af26f9f3cb7cright-5-circleNode-f8cc4a65-0595-4c37-9d9f-24eb7e0e481ctop-3",
      selected: false,
    },
    {
      markerStart: {
        type: "arrowclosed",
      },
      markerEnd: {
        type: "arrowclosed",
      },
      type: "defaultEdge",
      data: {
        edgeType: "Straight",
        color: "#000",
        countArrow: 2,
      },
      source: "circleNode-f8cc4a65-0595-4c37-9d9f-24eb7e0e481c",
      sourceHandle: "right-4",
      target: "circleNode-dc5d8bf5-81f1-4de5-a2c7-9d27cfc181b4",
      targetHandle: "left-4",
      id: "reactflow__edge-circleNode-f8cc4a65-0595-4c37-9d9f-24eb7e0e481cright-4-circleNode-dc5d8bf5-81f1-4de5-a2c7-9d27cfc181b4left-4",
      selected: false,
    },
  ],
};

export const Temp5 = {
  nodes: [
    {
      id: "textNodeWithBlocks-621c57ec-0115-454f-be06-6dd673c0b640",
      type: "textNodeWithBlocks",
      position: {
        x: 314.21978201622034,
        y: 166.8413450047109,
      },
      data: {
        mainText: "этап процесса",
        topLeftText: "",
        topRightText: "",
        bottomLeftText: "",
        bottomRightText: "",
      },
      width: 206,
      height: 52,
      positionAbsolute: {
        x: 314.21978201622034,
        y: 166.8413450047109,
      },
      selected: false,
      dragging: false,
    },
    {
      id: "ovalNode-b88b59a0-6225-41ec-b39c-d55e4c1f7c3d",
      type: "ovalNode",
      position: {
        x: 306.3886675403802,
        y: 22.358038635223437,
      },
      data: {
        mainText: "начало",
      },
      width: 220,
      height: 80,
      selected: false,
      positionAbsolute: {
        x: 306.3886675403802,
        y: 22.358038635223437,
      },
      dragging: false,
    },
    {
      id: "hexagonNode-48330d7c-7dc1-4f81-b49b-96f39737daad",
      type: "hexagonNode",
      position: {
        x: 361.3662981119065,
        y: 283.21926887563467,
      },
      data: {
        mainText: "контроль\nкачества",
      },
      width: 120,
      height: 60,
      selected: false,
      positionAbsolute: {
        x: 361.3662981119065,
        y: 283.21926887563467,
      },
      dragging: false,
    },
    {
      id: "parallelogramNode-c2db79e5-e8e3-40dc-b31b-d8751731e89d",
      type: "parallelogramNode",
      position: {
        x: 119.89681497443672,
        y: 284.022735504172,
      },
      data: {
        mainText: "комментарий",
      },
      width: 154,
      height: 60,
      selected: false,
      positionAbsolute: {
        x: 119.89681497443672,
        y: 284.022735504172,
      },
      dragging: false,
      style: {
        width: 154,
        height: 60,
      },
      resizing: false,
    },
    {
      id: "diamondNode-54382952-5e62-452b-89a3-aa0d46c59299",
      type: "diamondNode",
      position: {
        x: 317.55883947159947,
        y: 410.10217998898077,
      },
      data: {
        mainText: "решение",
      },
      width: 201,
      height: 100,
      style: {
        width: 201,
        height: 100,
      },
      resizing: false,
      selected: false,
      positionAbsolute: {
        x: 317.55883947159947,
        y: 410.10217998898077,
      },
      dragging: false,
    },
    {
      id: "ovalNode-fdfd9c75-b29a-4ab5-a9cb-5368aea31327",
      type: "ovalNode",
      position: {
        x: 306.65913691652315,
        y: 587.6346956094359,
      },
      data: {
        mainText: "конец",
      },
      width: 220,
      height: 80,
      selected: false,
      positionAbsolute: {
        x: 306.65913691652315,
        y: 587.6346956094359,
      },
      dragging: false,
    },
    {
      id: "documentNode-a571416e-dc85-4dcd-8c58-2b9c5d0411a7",
      type: "documentNode",
      position: {
        x: 598.5248535353049,
        y: 343.06622463810015,
      },
      data: {
        mainText: "документ",
      },
      width: 168,
      height: 100,
      selected: true,
      positionAbsolute: {
        x: 598.5248535353049,
        y: 343.06622463810015,
      },
      dragging: false,
      style: {
        width: 168,
        height: 100,
      },
      resizing: false,
    },
  ],
  edges: [
    {
      markerStart: {
        type: "arrowclosed",
      },
      markerEnd: {
        type: "arrowclosed",
      },
      type: "defaultEdge",
      data: {
        edgeType: "Straight",
        color: "#000",
        countArrow: 2,
      },
      source: "ovalNode-b88b59a0-6225-41ec-b39c-d55e4c1f7c3d",
      sourceHandle: "bottom-5",
      target: "textNodeWithBlocks-621c57ec-0115-454f-be06-6dd673c0b640",
      targetHandle: "top-5",
      id: "reactflow__edge-ovalNode-b88b59a0-6225-41ec-b39c-d55e4c1f7c3dbottom-5-textNodeWithBlocks-621c57ec-0115-454f-be06-6dd673c0b640top-5",
      selected: false,
    },
    {
      markerStart: {
        type: "arrowclosed",
      },
      markerEnd: {
        type: "arrowclosed",
      },
      type: "defaultEdge",
      data: {
        edgeType: "Straight",
        color: "#000",
        countArrow: 2,
      },
      source: "textNodeWithBlocks-621c57ec-0115-454f-be06-6dd673c0b640",
      sourceHandle: "bottom-5",
      target: "hexagonNode-48330d7c-7dc1-4f81-b49b-96f39737daad",
      targetHandle: "top-5",
      id: "reactflow__edge-textNodeWithBlocks-621c57ec-0115-454f-be06-6dd673c0b640bottom-5-hexagonNode-48330d7c-7dc1-4f81-b49b-96f39737daadtop-5",
      selected: false,
    },
    {
      markerStart: {
        type: "arrowclosed",
      },
      markerEnd: {
        type: "arrowclosed",
      },
      type: "defaultEdge",
      data: {
        edgeType: "Straight",
        color: "#000",
        countArrow: 3,
      },
      source: "hexagonNode-48330d7c-7dc1-4f81-b49b-96f39737daad",
      sourceHandle: "left-4",
      target: "parallelogramNode-c2db79e5-e8e3-40dc-b31b-d8751731e89d",
      targetHandle: "right-4",
      id: "reactflow__edge-hexagonNode-48330d7c-7dc1-4f81-b49b-96f39737daadleft-4-parallelogramNode-c2db79e5-e8e3-40dc-b31b-d8751731e89dright-4",
      selected: false,
    },
    {
      markerStart: {
        type: "arrowclosed",
      },
      markerEnd: {
        type: "arrowclosed",
      },
      type: "defaultEdge",
      data: {
        edgeType: "Straight",
        color: "#000",
        countArrow: 2,
      },
      source: "hexagonNode-48330d7c-7dc1-4f81-b49b-96f39737daad",
      sourceHandle: "bottom-5",
      target: "diamondNode-54382952-5e62-452b-89a3-aa0d46c59299",
      targetHandle: "top-5",
      id: "reactflow__edge-hexagonNode-48330d7c-7dc1-4f81-b49b-96f39737daadbottom-5-diamondNode-54382952-5e62-452b-89a3-aa0d46c59299top-5",
      selected: false,
    },
    {
      markerStart: {
        type: "arrowclosed",
      },
      markerEnd: {
        type: "arrowclosed",
      },
      type: "defaultEdge",
      data: {
        edgeType: "SmoothStep",
        color: "#000",
        countArrow: 0,
      },
      source: "diamondNode-54382952-5e62-452b-89a3-aa0d46c59299",
      sourceHandle: "left-5",
      target: "ovalNode-b88b59a0-6225-41ec-b39c-d55e4c1f7c3d",
      targetHandle: "left-4",
      id: "reactflow__edge-diamondNode-54382952-5e62-452b-89a3-aa0d46c59299left-5-ovalNode-b88b59a0-6225-41ec-b39c-d55e4c1f7c3dleft-4",
    },
    {
      markerStart: {
        type: "arrowclosed",
      },
      markerEnd: {
        type: "arrowclosed",
      },
      type: "defaultEdge",
      data: {
        edgeType: "Straight",
        color: "#000",
        countArrow: 2,
      },
      source: "diamondNode-54382952-5e62-452b-89a3-aa0d46c59299",
      sourceHandle: "bottom-5",
      target: "ovalNode-fdfd9c75-b29a-4ab5-a9cb-5368aea31327",
      targetHandle: "top-5",
      id: "reactflow__edge-diamondNode-54382952-5e62-452b-89a3-aa0d46c59299bottom-5-ovalNode-fdfd9c75-b29a-4ab5-a9cb-5368aea31327top-5",
      selected: false,
    },
    {
      markerStart: {
        type: "arrowclosed",
      },
      markerEnd: {
        type: "arrowclosed",
      },
      type: "defaultEdge",
      data: {
        edgeType: "SmoothStep",
        color: "#000",
        countArrow: 0,
      },
      source: "hexagonNode-48330d7c-7dc1-4f81-b49b-96f39737daad",
      sourceHandle: "right-4",
      target: "documentNode-a571416e-dc85-4dcd-8c58-2b9c5d0411a7",
      targetHandle: "top-5",
      id: "reactflow__edge-hexagonNode-48330d7c-7dc1-4f81-b49b-96f39737daadright-4-documentNode-a571416e-dc85-4dcd-8c58-2b9c5d0411a7top-5",
    },
  ],
};

export const Temp6 = {
  nodes: [
    {
      id: "ovalNode-0a1c9cd3-87a2-4f18-9d42-3bc1b5a7478a",
      type: "ovalNode",
      position: {
        x: 342.671875,
        y: -110.09375,
      },
      data: {
        mainText: "Начало процесса",
      },
      width: 220,
      height: 80,
      positionAbsolute: {
        x: 342.671875,
        y: -110.09375,
      },
      resizing: false,
      selected: false,
      dragging: false,
    },
    {
      id: "textNodeWithBlocks-cd3d0e94-d294-41c9-a2ec-8f2bcb504a90",
      type: "textNodeWithBlocks",
      position: {
        x: 350.0078125,
        y: 28.359375,
      },
      data: {
        mainText: "сбор информации о процессе",
        topLeftText: "",
        topRightText: "",
        bottomLeftText: "",
        bottomRightText: "",
      },
      width: 206,
      height: 74,
      selected: false,
      positionAbsolute: {
        x: 350.0078125,
        y: 28.359375,
      },
      dragging: false,
    },
    {
      id: "textNodeWithBlocks-e4e1259b-18d8-4d8d-9d38-58c74388c848",
      type: "textNodeWithBlocks",
      position: {
        x: 349.63097533158657,
        y: 157.51928119985467,
      },
      data: {
        mainText: "анализ данных о процессе",
        topLeftText: "",
        topRightText: "",
        bottomLeftText: "",
        bottomRightText: "",
      },
      width: 206,
      height: 74,
      selected: false,
      positionAbsolute: {
        x: 349.63097533158657,
        y: 157.51928119985467,
      },
      dragging: false,
    },
    {
      id: "textNodeWithBlocks-ace479a9-74f2-4fa6-9bd1-cf00e33e3838",
      type: "textNodeWithBlocks",
      position: {
        x: 344.79431098433554,
        y: 286.95363290483715,
      },
      data: {
        mainText: "планирование процесса",
        topLeftText: "",
        topRightText: "",
        bottomLeftText: "",
        bottomRightText: "",
      },
      width: 213,
      height: 52,
      selected: false,
      positionAbsolute: {
        x: 344.79431098433554,
        y: 286.95363290483715,
      },
      dragging: false,
      style: {
        width: 213,
        height: 52,
      },
      resizing: false,
    },
    {
      id: "textNodeWithBlocks-6193d0a3-6a0b-4dd8-addb-51281f15fd89",
      type: "textNodeWithBlocks",
      position: {
        x: 340.1071957691438,
        y: 398.48835921850076,
      },
      data: {
        mainText: "осуществление процесса",
        topLeftText: "",
        topRightText: "",
        bottomLeftText: "",
        bottomRightText: "",
      },
      width: 221,
      height: 55,
      selected: false,
      positionAbsolute: {
        x: 340.1071957691438,
        y: 398.48835921850076,
      },
      dragging: false,
      style: {
        width: 221,
        height: 55,
      },
      resizing: false,
    },
    {
      id: "diamondNode-42dd7250-3100-4c82-ba93-43cf2da53cb2",
      type: "diamondNode",
      position: {
        x: 364.4625713472413,
        y: 529.1802502696287,
      },
      data: {
        mainText: "соответсвует ли процесс требованиям?",
      },
      width: 178,
      height: 157,
      selected: false,
      positionAbsolute: {
        x: 364.4625713472413,
        y: 529.1802502696287,
      },
      dragging: false,
      style: {
        width: 178,
        height: 157,
      },
      resizing: false,
    },
    {
      id: "diamondNode-dca897f2-a4c6-481f-a005-a6bbd79ab79a",
      type: "diamondNode",
      position: {
        x: 383.98558799467634,
        y: 768.1498130933396,
      },
      data: {
        mainText: "нужно ли улучшение процесса?",
      },
      width: 142,
      height: 145,
      selected: false,
      positionAbsolute: {
        x: 383.98558799467634,
        y: 768.1498130933396,
      },
      dragging: false,
      style: {
        width: 142,
        height: 145,
      },
      resizing: false,
    },
    {
      id: "ovalNode-0bb80fc4-85ca-43a1-b79c-000e2eabc334",
      type: "ovalNode",
      position: {
        x: 339.72404752225634,
        y: 985.1422429607965,
      },
      data: {
        mainText: "конец процесса",
      },
      width: 220,
      height: 80,
      selected: false,
      positionAbsolute: {
        x: 339.72404752225634,
        y: 985.1422429607965,
      },
      dragging: false,
      resizing: false,
    },
    {
      id: "textNodeWithBlocks-10fcaddb-6cb0-453a-ae43-77f29ced05e2",
      type: "textNodeWithBlocks",
      position: {
        x: 21.304037990709787,
        y: 790.3260830439574,
      },
      data: {
        mainText: "разработка мероприятий по улучшению процесса",
        topLeftText: "",
        topRightText: "",
        bottomLeftText: "",
        bottomRightText: "",
      },
      width: 206,
      height: 96,
      positionAbsolute: {
        x: 21.304037990709787,
        y: 790.3260830439574,
      },
      selected: false,
      dragging: false,
    },
    {
      id: "textNode-d6ae6eb1-26dd-4620-a1d5-99ba318adeb8",
      type: "textNode",
      position: {
        x: 513.0029421289373,
        y: 566.8533266700113,
      },
      data: {
        mainText: "нет",
      },
      width: 100,
      height: 39,
      positionAbsolute: {
        x: 513.0029421289373,
        y: 566.8533266700113,
      },
      style: {
        width: 100,
        height: 39,
      },
      resizing: false,
      selected: false,
      dragging: false,
    },
    {
      id: "textNode-f0a07b93-eb3c-420a-8236-572a413d00ec",
      type: "textNode",
      position: {
        x: 435.96865209629914,
        y: 684.7244832799745,
      },
      data: {
        mainText: "да",
      },
      width: 100,
      height: 41,
      positionAbsolute: {
        x: 435.96865209629914,
        y: 684.7244832799745,
      },
      style: {
        width: 100,
        height: 41,
      },
      resizing: false,
      selected: false,
      dragging: false,
    },
    {
      id: "textNode-45f9dc31-d44a-4e14-954f-dd500defe066",
      type: "textNode",
      position: {
        x: 302.2061520962992,
        y: 800.1557332799745,
      },
      data: {
        mainText: "да",
      },
      width: 100,
      height: 37,
      selected: false,
      positionAbsolute: {
        x: 302.2061520962992,
        y: 800.1557332799745,
      },
      dragging: false,
      style: {
        width: 100,
        height: 37,
      },
      resizing: false,
    },
    {
      id: "textNode-b1c32510-4ba8-4f68-b4ff-16fdc4e1ebde",
      type: "textNode",
      position: {
        x: 433.0672533898123,
        y: 908.7652200454303,
      },
      data: {
        mainText: "нет",
      },
      width: 100,
      height: 41,
      positionAbsolute: {
        x: 433.0672533898123,
        y: 908.7652200454303,
      },
      resizing: false,
      style: {
        width: 100,
        height: 41,
      },
      selected: false,
      dragging: false,
    },
  ],
  edges: [
    {
      markerStart: {
        type: "arrowclosed",
      },
      markerEnd: {
        type: "arrowclosed",
      },
      type: "defaultEdge",
      data: {
        edgeType: "Straight",
        color: "#000",
        countArrow: 2,
      },
      source: "ovalNode-0a1c9cd3-87a2-4f18-9d42-3bc1b5a7478a",
      sourceHandle: "bottom-5",
      target: "textNodeWithBlocks-cd3d0e94-d294-41c9-a2ec-8f2bcb504a90",
      targetHandle: "top-5",
      id: "reactflow__edge-ovalNode-0a1c9cd3-87a2-4f18-9d42-3bc1b5a7478abottom-5-textNodeWithBlocks-cd3d0e94-d294-41c9-a2ec-8f2bcb504a90top-5",
      selected: false,
    },
    {
      markerStart: {
        type: "arrowclosed",
      },
      markerEnd: {
        type: "arrowclosed",
      },
      type: "defaultEdge",
      data: {
        edgeType: "Straight",
        color: "#000",
        countArrow: 2,
      },
      source: "textNodeWithBlocks-cd3d0e94-d294-41c9-a2ec-8f2bcb504a90",
      sourceHandle: "bottom-5",
      target: "textNodeWithBlocks-e4e1259b-18d8-4d8d-9d38-58c74388c848",
      targetHandle: "top-5",
      id: "reactflow__edge-textNodeWithBlocks-cd3d0e94-d294-41c9-a2ec-8f2bcb504a90bottom-5-textNodeWithBlocks-e4e1259b-18d8-4d8d-9d38-58c74388c848top-5",
      selected: false,
    },
    {
      markerStart: {
        type: "arrowclosed",
      },
      markerEnd: {
        type: "arrowclosed",
      },
      type: "defaultEdge",
      data: {
        edgeType: "Straight",
        color: "#000",
        countArrow: 2,
      },
      source: "textNodeWithBlocks-e4e1259b-18d8-4d8d-9d38-58c74388c848",
      sourceHandle: "bottom-5",
      target: "textNodeWithBlocks-ace479a9-74f2-4fa6-9bd1-cf00e33e3838",
      targetHandle: "top-5",
      id: "reactflow__edge-textNodeWithBlocks-e4e1259b-18d8-4d8d-9d38-58c74388c848bottom-5-textNodeWithBlocks-ace479a9-74f2-4fa6-9bd1-cf00e33e3838top-5",
      selected: false,
    },
    {
      markerStart: {
        type: "arrowclosed",
      },
      markerEnd: {
        type: "arrowclosed",
      },
      type: "defaultEdge",
      data: {
        edgeType: "Straight",
        color: "#000",
        countArrow: 2,
      },
      source: "textNodeWithBlocks-ace479a9-74f2-4fa6-9bd1-cf00e33e3838",
      sourceHandle: "bottom-5",
      target: "textNodeWithBlocks-6193d0a3-6a0b-4dd8-addb-51281f15fd89",
      targetHandle: "top-5",
      id: "reactflow__edge-textNodeWithBlocks-ace479a9-74f2-4fa6-9bd1-cf00e33e3838bottom-5-textNodeWithBlocks-6193d0a3-6a0b-4dd8-addb-51281f15fd89top-5",
      selected: false,
    },
    {
      markerStart: {
        type: "arrowclosed",
      },
      markerEnd: {
        type: "arrowclosed",
      },
      type: "defaultEdge",
      data: {
        edgeType: "Straight",
        color: "#000",
        countArrow: 2,
      },
      source: "textNodeWithBlocks-6193d0a3-6a0b-4dd8-addb-51281f15fd89",
      sourceHandle: "bottom-5",
      target: "diamondNode-42dd7250-3100-4c82-ba93-43cf2da53cb2",
      targetHandle: "top-5",
      id: "reactflow__edge-textNodeWithBlocks-6193d0a3-6a0b-4dd8-addb-51281f15fd89bottom-5-diamondNode-42dd7250-3100-4c82-ba93-43cf2da53cb2top-5",
      selected: false,
    },
    {
      markerStart: {
        type: "arrowclosed",
      },
      markerEnd: {
        type: "arrowclosed",
      },
      type: "defaultEdge",
      data: {
        edgeType: "SmoothStep",
        color: "#000",
        countArrow: 0,
      },
      source: "diamondNode-42dd7250-3100-4c82-ba93-43cf2da53cb2",
      sourceHandle: "right-4",
      target: "textNodeWithBlocks-cd3d0e94-d294-41c9-a2ec-8f2bcb504a90",
      targetHandle: "right-3",
      id: "reactflow__edge-diamondNode-42dd7250-3100-4c82-ba93-43cf2da53cb2right-4-textNodeWithBlocks-cd3d0e94-d294-41c9-a2ec-8f2bcb504a90right-3",
    },
    {
      markerStart: {
        type: "arrowclosed",
      },
      markerEnd: {
        type: "arrowclosed",
      },
      type: "defaultEdge",
      data: {
        edgeType: "Straight",
        color: "#000",
        countArrow: 2,
      },
      source: "diamondNode-42dd7250-3100-4c82-ba93-43cf2da53cb2",
      sourceHandle: "bottom-5",
      target: "diamondNode-dca897f2-a4c6-481f-a005-a6bbd79ab79a",
      targetHandle: "top-5",
      id: "reactflow__edge-diamondNode-42dd7250-3100-4c82-ba93-43cf2da53cb2bottom-5-diamondNode-dca897f2-a4c6-481f-a005-a6bbd79ab79atop-5",
      selected: false,
    },
    {
      markerStart: {
        type: "arrowclosed",
      },
      markerEnd: {
        type: "arrowclosed",
      },
      type: "defaultEdge",
      data: {
        edgeType: "Straight",
        color: "#000",
        countArrow: 2,
      },
      source: "diamondNode-dca897f2-a4c6-481f-a005-a6bbd79ab79a",
      sourceHandle: "left-4",
      target: "textNodeWithBlocks-10fcaddb-6cb0-453a-ae43-77f29ced05e2",
      targetHandle: "right-4",
      id: "reactflow__edge-diamondNode-dca897f2-a4c6-481f-a005-a6bbd79ab79aleft-4-textNodeWithBlocks-10fcaddb-6cb0-453a-ae43-77f29ced05e2right-4",
      selected: false,
    },
    {
      markerStart: {
        type: "arrowclosed",
      },
      markerEnd: {
        type: "arrowclosed",
      },
      type: "defaultEdge",
      data: {
        edgeType: "SmoothStep",
        color: "#000",
        countArrow: 0,
      },
      source: "textNodeWithBlocks-10fcaddb-6cb0-453a-ae43-77f29ced05e2",
      sourceHandle: "left-4",
      target: "textNodeWithBlocks-6193d0a3-6a0b-4dd8-addb-51281f15fd89",
      targetHandle: "left-4",
      id: "reactflow__edge-textNodeWithBlocks-10fcaddb-6cb0-453a-ae43-77f29ced05e2left-4-textNodeWithBlocks-6193d0a3-6a0b-4dd8-addb-51281f15fd89left-4",
    },
    {
      markerStart: {
        type: "arrowclosed",
      },
      markerEnd: {
        type: "arrowclosed",
      },
      type: "defaultEdge",
      data: {
        edgeType: "Straight",
        color: "#000",
        countArrow: 2,
      },
      source: "diamondNode-dca897f2-a4c6-481f-a005-a6bbd79ab79a",
      sourceHandle: "bottom-5",
      target: "ovalNode-0bb80fc4-85ca-43a1-b79c-000e2eabc334",
      targetHandle: "top-5",
      id: "reactflow__edge-diamondNode-dca897f2-a4c6-481f-a005-a6bbd79ab79abottom-5-ovalNode-0bb80fc4-85ca-43a1-b79c-000e2eabc334top-5",
      selected: false,
    },
  ],
};
