import { Card, Select } from "antd";
import React, { useRef, useState } from "react";
import { Diagram } from "./Diagram";
import { TableData } from "./TableData";
import { SavePNGButton } from "../../../components/SavePNGButton";
import { TableResult } from "./TableResult";

const ContentFMEA = ({ selectItem }) => {
  const [currentLength, setCurrentLength] = useState([]);

  const title = selectItem === "product" ? "Изделие" : "Процессы";
  const titleSecond =
    selectItem === "product" ? "Компоненты" : "Элементы процесса";
  const cardTitle = selectItem === "product" ? "Узел" : "Процесс";
  const titleTable =
    selectItem === "product"
      ? "Признаки качества / требования Риски процесса"
      : "Критерии FMEA:";
  const subTable =
    selectItem === "product"
      ? "Элементы процесса"
      : "Процесс (Группы процессов)";

  return (
    <>
      <Diagram title={title} cardTitle={cardTitle} />
      <Diagram title={titleSecond} changeLength={setCurrentLength} />
      <TableData
        key={selectItem}
        type={selectItem}
        columnBlocks={currentLength}
        title={titleTable}
        subTitle={subTable}
        titleType={selectItem === "product" ? "letter" : "number"}
      />
      <TableResult selectItem={selectItem} />
    </>
  );
};

export const FMEA = () => {
  const ref = useRef(null);
  const [selectItem, setSelectItem] = useState("");

  return (
    <>
      <Card ref={ref}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 20,
          }}
        >
          <Select
            onChange={setSelectItem}
            placeholder="Выберете тип"
            options={[
              {
                value: "product",
                label: "Изделия",
              },
              {
                value: "process",
                label: "Процессы",
              },
            ]}
          />
          {selectItem && (
            <ContentFMEA selectItem={selectItem} key={selectItem} />
          )}
        </div>
      </Card>
      <Card>
        <SavePNGButton containerRef={ref} fullScreen name="fmea" />
      </Card>
    </>
  );
};
