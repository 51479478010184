import React from "react";
import { Card } from "antd";

import html from "./html/risk_calculation.html";
import { SavePNGButton } from "../../components/SavePNGButton";

const Basic = () => {
  return (
    <Card>
      <SavePNGButton frame />
      <iframe
        title="test"
        style={{ width: "100%", height: "100vh" }}
        srcDoc={html}
      ></iframe>
    </Card>
  );
};

export default Basic;
