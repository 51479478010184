import React from "react";
import { Form, Input, Button, Card } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import styled from "styled-components";
import { fetchAuth } from "../../redux/authSlice";
import router from "../../utils/router";

import background from "../../assets/back.jpg";

const LoginPage = () => {
  const dispatch = useDispatch();
  const { isAuth, loadingAuth, error } = useSelector((state) => state.auth);

  if (isAuth) {
    return <Navigate to={{ pathname: router.main }} />;
  }

  const handleSubmit = (form) => {
    dispatch(fetchAuth(form));
  };

  return (
    <Main img={background}>
      <Card>
        <Form name="auth" onFinish={handleSubmit}>
          <Form.Item
            name="login"
            validateStatus={error ? "error" : "success"}
            rules={[{ required: true, message: "Введите свой логин!" }]}
          >
            <Input prefix={<UserOutlined />} placeholder="Логин" />
          </Form.Item>

          <Form.Item
            name="password"
            validateStatus={error ? "error" : "success"}
            rules={[{ required: true, message: "Введите пароль!" }]}
          >
            <Input
              prefix={<LockOutlined />}
              type="password"
              placeholder="Пароль"
            />
          </Form.Item>

          <Button
            style={{ width: "100%" }}
            type="primary"
            loading={loadingAuth}
            disabled={loadingAuth}
            htmlType="submit"
          >
            Войти
          </Button>
        </Form>
      </Card>
    </Main>
  );
};

export default LoginPage;

const Main = styled.main`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background-image: url(${(img) => img.img});
`;
