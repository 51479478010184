import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Button, Card, DatePicker, Descriptions, Table } from "antd";
import { Layout } from "../../components";
import {
  getWorker,
  getWorkerAnalytics,
  getWorkerAnalyticsByFilter,
} from "../../redux/workersSlice";
import "./style.css";
import ModalAnalytics from "./Modal";

const columns = [{ title: "Дата", dataIndex: "date" }];

const AnalyticAbout = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState({ open: false, item: {} });
  const { worker, analysis, loading } = useSelector((state) => state.workers);
  const [dateStart, setDateStart] = useState("");
  const [dateEnd, setDateEnd] = useState("");

  useEffect(() => {
    dispatch(getWorker(id));
    dispatch(getWorkerAnalytics(id));
  }, [dispatch, id]);

  const columns1 = [
    {
      title: "Просмотр",
      dataIndex: "chart",
      render: (_, item) => (
        <Button
          onClick={() =>
            setModalOpen({ open: true, item: { ...item, workerId: id } })
          }
        >
          График
        </Button>
      ),
    },
    { title: "Модель", dataIndex: ["model", "title"] },
    { title: "Технический процесс", dataIndex: "tech_process" },
    { title: "Выполнено", dataIndex: "performed" },
    { title: "Брак", dataIndex: "defects" },
  ];

  const changeStart = (val) => val && setDateStart(moment(val));

  const changeEnd = (val) => val && setDateEnd(moment(val));

  const find = () => {
    dispatch(
      getWorkerAnalyticsByFilter({
        id,
        dateStart: dateStart && dateStart.format("YYYY-MM-DD"),
        dateEnd: dateEnd && dateEnd.format("YYYY-MM-DD"),
      })
    );
  };

  const cancel = () => {
    dispatch(getWorkerAnalytics(id));
    setDateStart("");
    setDateEnd("");
  };

  const expandedRowRender = ({ items }) => (
    <Table
      columns={columns1}
      dataSource={items}
      rowKey="id"
      pagination={false}
    />
  );

  return (
    <Layout title="Аналитика">
      <Card>
        <Descriptions title="Инфо">
          <Descriptions.Item label="ФИО">{worker.name}</Descriptions.Item>
          <Descriptions.Item label="Должность">{worker.post}</Descriptions.Item>
        </Descriptions>
      </Card>
      <Card>
        <div className="filters">
          <div className="filter">
            С <DatePicker value={dateStart} onChange={changeStart} />
          </div>
          <div className="filter">
            До <DatePicker value={dateEnd} onChange={changeEnd} />
          </div>
          <div className="filter">
            <Button onClick={find}>Применить</Button>
          </div>
          <div className="filter">
            <Button onClick={cancel}>Сбросить</Button>
          </div>
        </div>
        <Table
          dataSource={analysis}
          columns={columns}
          expandable={{ expandedRowRender }}
          loading={loading}
          rowKey="id"
          pagination={false}
        />
      </Card>
      {modalOpen.open && (
        <ModalAnalytics modalOpen={modalOpen} setModalOpen={setModalOpen} />
      )}
    </Layout>
  );
};

export default AnalyticAbout;
