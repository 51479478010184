import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../api/api";

const initialState = {
  workers: [],
  worker: {},
  chartInfo: [],
  infoLoading: false,
  analysis: [],
  workersOperations: [],
  defectsList: [],
  defectsLoading: [],
  loading: false,
};

export const getWorkers = createAsyncThunk("workers/getWorkers", async () => {
  const response = await api.get("/api/workers/");
  return response.data;
});

export const getWorker = createAsyncThunk("workers/getWorker", async (id) => {
  const response = await api.get(`/api/workers/${id}`);
  return response.data;
});

export const getWorkerAnalytics = createAsyncThunk(
  "workers/getWorkerAnalytics",
  async (id) => {
    const response = await api.get(`/api/workers/${id}/analysis/`);
    return response.data;
  }
);

export const getWorkerAnalyticsChart = createAsyncThunk(
  "workers/getWorkerAnalyticsChart",
  async (data) => {
    const response = await api.post(`/api/analysis/chart/`, data);
    return response.data;
  }
);

export const getWorkerAnalyticsByFilter = createAsyncThunk(
  "workers/getWorkerAnalytics",
  async ({ id, dateStart, dateEnd }) => {
    const response = await api.get(
      `/api/workers/${id}/analysis?${
        dateStart ? "&dateStart=" + dateStart : ""
      }${dateEnd ? "&dateEnd=" + dateEnd : ""}`
    );
    return response.data;
  }
);

export const getWorkersOperations = createAsyncThunk(
  "workers/getWorkersOperations",
  async () => {
    const response = await api.get("/api/worker-operations/");
    return response.data;
  }
);

export const doneWorkersOperations = createAsyncThunk(
  "workers/doneWorkersOperations",
  async (data) => {
    const response = await api.post("/api/worker-operations/", data);
    return response.data;
  }
);

export const setWorker = createAsyncThunk(
  "workers/setWorker",
  async ({ id, idWorker }) => {
    const response = await api.post("/api/set-worker-to-ool/", {
      [id]: idWorker,
    });
    return response;
  }
);

export const defectWorkersOperations = createAsyncThunk(
  "workers/defectWorkersOperations",
  async (data) => {
    const response = await api.post("/api/worker-operations/", data);
    return response.data;
  }
);

export const getDefectsList = createAsyncThunk(
  "workers/getDefectsList",
  async (_, thunkAPI) => {
    const { workers } = thunkAPI.getState();
    if (workers.defectsList.length) {
      return workers.defectsList;
    }
    const response = await api.get("/api/defect_types/");
    return response.data;
  }
);

export const workersSlice = createSlice({
  name: "workers",
  initialState,
  reducers: {},
  extraReducers: {
    [getWorkers.pending]: (state) => {
      state.loading = true;
    },
    [getWorkers.rejected]: (state) => {
      state.loading = false;
    },
    [getWorkers.fulfilled]: (state, action) => {
      state.workers = action.payload;
      state.loading = false;
    },
    [getDefectsList.pending]: (state) => {
      state.defectsLoading = true;
    },
    [getDefectsList.rejected]: (state) => {
      state.defectsLoading = false;
    },
    [getDefectsList.fulfilled]: (state, action) => {
      state.defectsList = action.payload;
      state.defectsLoading = false;
    },
    [getWorker.pending]: (state) => {
      state.loading = true;
    },
    [getWorker.rejected]: (state) => {
      state.loading = false;
    },
    [getWorker.fulfilled]: (state, action) => {
      state.worker = action.payload;
      state.loading = false;
    },
    [defectWorkersOperations.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [getWorkerAnalytics.fulfilled]: (state, action) => {
      state.analysis = action.payload;
      state.loading = false;
    },
    [getWorkerAnalyticsByFilter.fulfilled]: (state, action) => {
      state.analysis = action.payload;
      state.loading = false;
    },
    [getWorkersOperations.fulfilled]: (state, action) => {
      state.workersOperations = action.payload;
      state.loading = false;
    },
    [doneWorkersOperations.fulfilled]: (state, action) => {
      state.workersOperations = action.payload;
      state.loading = false;
    },
    [getWorkerAnalyticsChart.pending]: (state, action) => {
      state.chartInfo = [];
      state.infoLoading = true;
    },
    [getWorkerAnalyticsChart.rejected]: (state, action) => {
      state.chartInfo = [];
      state.infoLoading = false;
    },
    [getWorkerAnalyticsChart.fulfilled]: (state, action) => {
      state.chartInfo = action.payload;
      state.infoLoading = false;
    },
  },
});

export const act = workersSlice.actions;

export default workersSlice.reducer;
