import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../api/api";

const initialState = {
  materials: [],
  history: [],
  techEquip: [],
  loading: false,
};

export const getWarehouseMaterial = createAsyncThunk(
  "warehouse/getWarehouseMaterial",
  async () => {
    const response = await api.get("/api/storage/materials/");
    return response.data;
  }
);

export const sendMaterial = createAsyncThunk(
  "warehouse/sendMaterial",
  async (data) => {
    const response = await api.post("/api/storage/materials/", data);
    return response.data;
  }
);

export const getTech = createAsyncThunk("warehouse/getTech", async () => {
  const response = await api.get("/api/storage/tech-equip/");
  return response.data;
});

export const sendTech = createAsyncThunk("warehouse/sendTech", async (data) => {
  const response = await api.post("/api/storage/tech-equip/", data);
  return response.data;
});

export const getHistory = createAsyncThunk("warehouse/getHistory", async () => {
  const response = await api.get("/api/storage/issues/");
  return response.data;
});

export const warehouseSlice = createSlice({
  name: "warehouse",
  initialState,
  reducers: {},
  extraReducers: {
    [getWarehouseMaterial.pending]: (state) => {
      state.loading = true;
    },
    [getWarehouseMaterial.rejected]: (state) => {
      state.loading = false;
    },
    [getWarehouseMaterial.fulfilled]: (state, action) => {
      state.materials = action.payload;
      state.loading = false;
    },
    [sendMaterial.fulfilled]: (state, action) => {
      state.materials = action.payload;
      state.loading = false;
    },
    [getHistory.pending]: (state) => {
      state.loading = true;
    },
    [getHistory.fulfilled]: (state, action) => {
      state.history = action.payload;
      state.loading = false;
    },
    [getTech.pending]: (state) => {
      state.loading = true;
    },
    [getTech.fulfilled]: (state, action) => {
      state.loading = false;
      state.techEquip = action.payload;
    },
  },
});

export const act = warehouseSlice.actions;

export default warehouseSlice.reducer;
