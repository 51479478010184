import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../api/api";

const initialState = {
  models: [],
  model: {
    tech_processes: [],
  },
  loading: false,
};

export const fetchModels = createAsyncThunk("models/fetchModels", async () => {
  const response = await api.get("/api/shoes-models/");
  return response.data;
});

export const getModelDetail = createAsyncThunk("models/id", async (id) => {
  const response = await api.get(`/api/shoes-models/${id}/`);
  return response.data;
});

export const modelsSlice = createSlice({
  name: "models",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchModels.pending]: (state) => {
      state.loading = true;
    },
    [fetchModels.rejected]: (state) => {
      state.loading = false;
    },
    [fetchModels.fulfilled]: (state, action) => {
      state.models = action.payload;
      state.loading = false;
    },
    [getModelDetail.pending]: (state) => {
      state.loading = true;
    },
    [getModelDetail.rejected]: (state) => {
      state.loading = false;
    },
    [getModelDetail.fulfilled]: (state, action) => {
      state.model = action.payload;
      state.loading = false;
    },
  },
});

export const act = modelsSlice.actions;

export default modelsSlice.reducer;
