import { Input, Table } from "antd";
import React, { useState } from "react";
import { columns, rows } from "./helper";

const EditableCell = ({
  editing,
  dataIndex,
  record,
  children,
  ...restProps
}) => {
  return (
    <td {...restProps}>
      {dataIndex !== "name" ? (
        <Input type="radio" bordered={false} name={`radio-${record.key}`} />
      ) : (
        children
      )}
    </td>
  );
};
export const TableSecond = () => {
  const [data] = useState(rows);

  const mergedColumns = columns.map((col) => {
    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        editing: true,
      }),
    };
  });

  return (
    <>
      <h5>Первичная анкета потенциального поставщика</h5>
      <Table
        size="small"
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        bordered
        dataSource={data}
        columns={mergedColumns}
        pagination={false}
      />
    </>
  );
};
