import { Card } from "antd";
import React, { useEffect, useRef } from "react";
import { useLocation, useParams } from "react-router";
import { Layout } from "../../components";
import statRoute from "./route";
import pareto from "./html/pareto.html";
import dispersion from "./html/dispersion.html";
import shewhart from "./html/shewhart.html";
import inconsistencies from "./html/inconsistencies.html";
import prop_of_inconsistencies from "./html/prop_of_inconsistencies.html";
import FishboneMy from "./FishboneMy/FishboneMy";
import ReactFlowPage from "./ReactFlow";
import { ReactFlowProvider } from "reactflow";
import Header from "./ReactFlow/components/Header";

import Risk from "../Calculator/Risk";
import Basic from "../Calculator/Basic";
import Assessment from "../Calculator/Assessment";
import HierarchyAnalysis from "../Calculator/HierarchyAnalysis";
import Regression from "../Calculator/Regression";
import Expert from "../Calculator/Expert";
import TimeSeries from "../Calculator/TimeSeries";
import MotivationManagement from "../Calculator/MotivationManagement";
import { MethodologySuppliers } from "./MethodologySuppliers";
import { EvaluationPaper } from "./EvaluationPaper";
import { SWOT } from "./SWOT";
import { FMEA } from "./FMEA";
import { Gantt } from "./Gantt";
import { MatrixDiagram } from "./MatrixDiagram";
import { Temp1, Temp2, Temp3, Temp4, Temp5, Temp6 } from "./ReactFlow/mockData";
import { SavePNGButton } from "../../components/SavePNGButton";

const StatMethodsRender = () => {
  const { pathname } = useLocation();
  const ref = useRef(null);
  const { stat } = useParams();

  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }, [pathname]);

  useEffect(() => {
    if (`/${stat}` === statRoute.pareto) {
      ref.current.addEventListener("load", () => {
        ref.current.contentWindow.postMessage("send success", "*");
      });
    }
  }, [stat]);

  const render = () => {
    switch ("/" + stat) {
      case statRoute.pareto:
        return (
          <Layout title="Диаграмма Парето">
            <Card>
              <SavePNGButton frame />
              <iframe
                ref={ref}
                title="pareto"
                style={{ width: "100%", height: "100vh" }}
                srcDoc={pareto}
              />
            </Card>
          </Layout>
        );
      case statRoute.dispersion:
        return (
          <Layout title="Диаграмма разброса">
            <SavePNGButton frame />
            <Card>
              <iframe
                id="frame"
                title="dispersion"
                style={{ width: "100%", height: "100vh" }}
                srcDoc={dispersion}
              />
            </Card>
          </Layout>
        );
      case statRoute.shewhart:
        return (
          <Layout title="Контрольные карты Шухарта">
            <Card>
              <SavePNGButton frame />
              <iframe
                title="shewhart"
                style={{ width: "100%", height: "100vh" }}
                srcDoc={shewhart}
              ></iframe>
            </Card>
          </Layout>
        );
      case statRoute.inconsistencies:
        return (
          <Layout title="Контрольные карты числа несоответствий">
            <Card>
              <SavePNGButton frame />
              <iframe
                title="inconsistencies"
                style={{ width: "100%", height: "100vh" }}
                srcDoc={inconsistencies}
              ></iframe>
            </Card>
          </Layout>
        );
      case statRoute.prop_of_inconsistencies:
        return (
          <Layout title="Контрольные карты числа и долей несоответствий">
            <Card>
              <SavePNGButton frame />
              <iframe
                title="prop_of_inconsistencies"
                style={{ width: "100%", height: "100vh" }}
                srcDoc={prop_of_inconsistencies}
              ></iframe>
            </Card>
          </Layout>
        );
      case statRoute.fishbone:
        return (
          <Layout title="Диаграмма Исикавы">
            <Card>
              <SavePNGButton fullScreen style={{ marginBottom: "10px" }} />
              <FishboneMy />
            </Card>
          </Layout>
        );
      case statRoute.reactFlow:
        return (
          <Layout title="Проектирование бизнес-процессов (DFD, IDEF0, IDEF3)">
            <ReactFlowProvider>
              <Header />
              <ReactFlowPage />
            </ReactFlowProvider>
          </Layout>
        );
      case statRoute.risk:
        return (
          <Layout title="Количество факторов">
            <Risk />
          </Layout>
        );
      case statRoute.basicDefinitions:
        return (
          <Layout title="Количество интервалов">
            <Basic />
          </Layout>
        );
      case statRoute.generalizedAssessment:
        return (
          <Layout title="Количество критериев">
            <Assessment />
          </Layout>
        );
      case statRoute.hierarchyAnalysis:
        return (
          <Layout title="Количество критериев">
            <HierarchyAnalysis />
          </Layout>
        );
      case statRoute.regression:
        return (
          <Layout title="Количество измерений">
            <Regression />
          </Layout>
        );
      case statRoute.expert:
        return (
          <Layout title="Количество экспертов">
            <Expert />
          </Layout>
        );
      case statRoute.timeSeries:
        return (
          <Layout title="Количество факторов">
            <TimeSeries />
          </Layout>
        );
      case statRoute.motivationManagement:
        return (
          <Layout title="Количество факторов">
            <MotivationManagement />
          </Layout>
        );
      case statRoute.methodologySuppliers:
        return (
          <Layout title="Методика оценки поставщиков">
            <MethodologySuppliers />
          </Layout>
        );
      case statRoute.evaluationPaper:
        return (
          <Layout title="Оценочный лист">
            <EvaluationPaper />
          </Layout>
        );
      case statRoute.swot:
        return (
          <Layout title="SWOT">
            <SWOT />
          </Layout>
        );
      case statRoute.fmea:
        return (
          <Layout title="FMEA">
            <FMEA />
          </Layout>
        );
      case statRoute.gantt:
        return (
          <Layout title="Диаграмма Ганта">
            <Gantt />
          </Layout>
        );
      case statRoute.matrixDiagram:
        return (
          <Layout title="Матричная диаграмма">
            <MatrixDiagram />
          </Layout>
        );
      case statRoute.diagramAndRelation:
        return (
          <Layout title="Диаграмма и сродства">
            <ReactFlowProvider>
              <Header />
              <ReactFlowPage
                startNodes={Temp2.nodes}
                startEdges={Temp2.edges}
              />
            </ReactFlowProvider>
          </Layout>
        );
      case statRoute.connectionDiagram:
        return (
          <Layout title="Диаграмма связей">
            <ReactFlowProvider>
              <Header />
              <ReactFlowPage
                startNodes={Temp3.nodes}
                startEdges={Temp3.edges}
              />
            </ReactFlowProvider>
          </Layout>
        );
      case statRoute.treeDiagram:
        return (
          <Layout title="Древовидная диаграмма">
            <ReactFlowProvider>
              <Header />
              <ReactFlowPage
                startNodes={Temp1.nodes}
                startEdges={Temp1.edges}
              />
            </ReactFlowProvider>
          </Layout>
        );
      case statRoute.arrowDiagram:
        return (
          <Layout title="Стрелочная диаграмма">
            <ReactFlowProvider>
              <Header />
              <ReactFlowPage
                startNodes={Temp4.nodes}
                startEdges={Temp4.edges}
              />
            </ReactFlowProvider>
          </Layout>
        );
      case statRoute.flowDiagram:
        return (
          <Layout title="Поточная диаграмма">
            <ReactFlowProvider>
              <Header />
              <ReactFlowPage
                startNodes={Temp5.nodes}
                startEdges={Temp5.edges}
              />
            </ReactFlowProvider>
          </Layout>
        );
      case statRoute.programDiagram:
        return (
          <Layout title="Диаграмма осуществления программы">
            <ReactFlowProvider>
              <Header />
              <ReactFlowPage
                startNodes={Temp6.nodes}
                startEdges={Temp6.edges}
              />
            </ReactFlowProvider>
          </Layout>
        );
      default:
        return <Layout title="Количество факторов">345</Layout>;
    }
  };

  return render();
};

export default StatMethodsRender;
