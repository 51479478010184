export const chooseNumber = [
  {
    label: "1",
    value: 1,
  },
  {
    label: "2",
    value: 2,
  },
  {
    label: "3",
    value: 3,
  },
];

export const chooseOperation = [
  {
    label: "+1",
    value: +1,
  },
  {
    label: "-1",
    value: -1,
  },
];

export const locale = {
  emptyText: <div />,
};
