import React, { memo, useEffect, useState } from 'react';
import { getIncomers, Handle, Position, useEdges, useNodes } from 'reactflow';
import clsx from 'clsx';

import styles from "./styles.module.css";
import "./style.css";

export default memo((props) => {
  const nodes = useNodes();
  const edges = useEdges();
  const [show, setShow] = useState([]);

  useEffect(() => {
    setShow(getIncomers(props, nodes, edges));
  }, [edges, props, nodes])

  return (
    <div className={styles.node} style={{ padding: '10px 10px 0 10px', width: '6px', height: '6px', position: 'relative' }}>
      <Handle
        type="source"
        isConnectableStart={false}
        isConnectableEnd={false}
        className={clsx(styles.pointHandle, {
          [styles.handle]: show.length
        })}
        position={Position.Top}
        id="a"
      />
      <Handle
        type="source"
        isConnectableStart={false}
        isConnectableEnd={false}
        className={clsx(styles.pointHandle, {
          [styles.handle]: show.length
        })}
        position={Position.Right}
        id="b"
      />
      <Handle
        type="source"
        isConnectableStart={false}
        isConnectableEnd={false}
        className={clsx(styles.pointHandle, {
          [styles.handle]: show.length
        })}
        position={Position.Bottom}
        id="c"
      />
      <Handle
        type="source"
        isConnectableStart={false}
        isConnectableEnd={false}
        className={clsx(styles.pointHandle, {
          [styles.handle]: show.length
        })}
        position={Position.Left}
        id="d"
      />
    </div>
  );
});
