import React from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import router from "../utils/router";

const WithProtectedPage = ({ children, allowRoles, ...props }) => {
  const location = useLocation();
  const { isAuth, user } = useSelector((state) => state.auth);

  if (!isAuth) {
    return (
      <Navigate to={{ pathname: router.login, state: { from: location } }} />
    );
  }

  if (!allowRoles.includes(user.role)) {
    return <Navigate to={{ pathname: router.main }} />;
  }

  return children;
};

export default WithProtectedPage;
