import { Card } from "antd";
import moment from "moment";

export const Content = ({ task }) => {
  return (
    <Card>
      {[
        moment(task.start).format("DD.MM.YYYY"),
        moment(task.end).format("DD.MM.YYYY"),
      ].join(" - ")}
    </Card>
  );
};
