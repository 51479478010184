import { MarkerType } from "reactflow";
import CustomDefaultEdge from "./Edges/CustomDefaultEdge";
import PointEdge from "./Edges/PointEdge";
import DoubleTextNode from "./Nodes/DoubleTextNode";
import DoubleTextNodeVertical from "./Nodes/DoubleTextNodeVertical";
import SymbolNode from "./Nodes/SymbolNode";
import PointNode from "./Nodes/PointNode";
import TextNode from "./Nodes/TextNode";
import TextWithBlocks from "./Nodes/TextWithBlocks";
import TextWithBlocksBorder from "./Nodes/TextWithBlocksBorder";
import TripleTextNode from "./Nodes/TripleTextNode";
import TripleTextNodeVertical from "./Nodes/TripleTextNodeVertical";
import CircleNode from "./Nodes/CircleNode";
import DiamondNode from "./Nodes/DiamondNode";
import HexagonNode from "./Nodes/HexagonNode";
import ParallelogramNode from "./Nodes/ParallelogramNode";
import OvalNode from "./Nodes/OvalNode";
import DocumentNode from "./Nodes/DocumentNode";

export const nodeTypes = {
  textNode: TextNode,
  pointNode: PointNode,
  circleNode: CircleNode,
  ovalNode: OvalNode,
  diamondNode: DiamondNode,
  hexagonNode: HexagonNode,
  parallelogramNode: ParallelogramNode,
  documentNode: DocumentNode,
  doubleTextNode: DoubleTextNode,
  textNodeWithBlocks: TextWithBlocks,
  textNodeWithBlocksBorder: TextWithBlocksBorder,
  doubleTextNodeVertical: DoubleTextNodeVertical,
  tripleTextNode: TripleTextNode,
  tripleTextNodeVertical: TripleTextNodeVertical,
  orNode: SymbolNode,
};

export const nodeTypesKeys = {
  textNode: "textNode",
  pointNode: "pointNode",
  circleNode: "circleNode",
  ovalNode: "ovalNode",
  diamondNode: "diamondNode",
  hexagonNode: "hexagonNode",
  parallelogramNode: "parallelogramNode",
  documentNode: "documentNode",
  doubleTextNode: "doubleTextNode",
  textNodeWithBlocks: "textNodeWithBlocks",
  textNodeWithBlocksBorder: "textNodeWithBlocksBorder",
  doubleTextNodeVertical: "doubleTextNodeVertical",
  tripleTextNodeVertical: "tripleTextNodeVertical",
  tripleTextNode: "tripleTextNode",
  orNode: "orNode",
};

export const edgeTypes = {
  pointEdge: PointEdge,
  defaultEdge: CustomDefaultEdge,
};

export const centerPorts = ["a", "b", "c", "d"];

export const defaultNode = {
  textNode: {
    data: {
      mainText: "",
    },
  },
  circleNode: {
    data: {
      mainText: "",
    },
  },
  ovalNode: {
    data: {
      mainText: "",
    },
  },
  diamondNode: {
    data: {
      mainText: "",
    },
  },
  textNodeWithBlocks: {
    data: {
      mainText: "",
      topLeftText: "",
      topRightText: "",
      bottomLeftText: "",
      bottomRightText: "",
    },
  },
  textNodeWithBlocksBorder: {
    data: {
      mainText: "",
      topLeftText: "",
      topRightText: "",
      bottomLeftText: "",
      bottomRightText: "",
    },
  },
  doubleTextNode: {
    data: {
      mainText: "",
      secondText: "",
    },
  },
  doubleTextNodeVertical: {
    data: {
      mainText: "",
      secondText: "",
    },
  },
  tripleTextNode: {
    data: {
      mainText: "",
      secondLeftText: "",
      secondRightText: "",
    },
  },
  tripleTextNodeVertical: {
    data: {
      mainText: "",
      secondTopText: "",
      secondBottomText: "",
    },
  },
};

export const defaultEdge = {
  markerStart: { type: MarkerType.ArrowClosed },
  markerEnd: { type: MarkerType.ArrowClosed },
  type: "defaultEdge",
  data: {
    edgeType: "SmoothStep",
    color: "#000",
    countArrow: 0,
  },
};
