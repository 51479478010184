import React, { memo } from "react";
import { useStore } from "reactflow";
import clsx from "clsx";

import styles from "./styles.module.css";
import "./style.css";
import Ports from "../components/Ports";

export default memo(({ data }) => {
  const start = useStore((state) => state.connectionStartHandle);

  return (
    <div
      className={styles.node}
      style={{
        backgroundColor: "white",
        border: "1px solid black",
        position: "relative",
      }}
    >
      <div style={{ display: "flex" }}>
        <div style={{ padding: "4px" }} />
        <div style={{ borderLeft: "1px solid black", padding: "1px 5px" }}>
          {data.content}
        </div>
      </div>
      <Ports
        size={5}
        className={clsx(styles.handle, {
          [styles.show]: start,
        })}
      />
    </div>
  );
});
