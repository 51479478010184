import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../api/api";

const initialState = {
  typeDefects: [],
};

export const getTypeDefectsPage = createAsyncThunk(
  "typeDefects/getTypeDefectsPage",
  async () => {
    const response = await api.get("/api/directories/defect_types/");
    return response.data;
  }
);

export const typeDefectsSlice = createSlice({
  name: "typeDefects",
  initialState,
  reducers: {},
  extraReducers: {
    [getTypeDefectsPage.fulfilled]: (state, action) => {
      state.typeDefects = action.payload;
    },
  },
});

export const act = typeDefectsSlice.actions;

export default typeDefectsSlice.reducer;
