import React, { Fragment, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Avatar, Card, Table, Tabs } from "antd";
import { Alert } from "react-bootstrap";

import { Layout } from "../../components";
import { getModelDetail } from "../../redux/modelsSlice";
import { additionalColumnsProcess, columns } from "./config";
import { defaultImage } from "../../constants/project";
import "./style.css";

const Description = ({ model }) => (
  <div>
    <p>{model.description}</p>
    <p>Род обуви: {model.kind}</p>
    <p>Метод креплений: {model.fastening}</p>
    <p>Фасон каблука: {model.heel_style}</p>
    <p>Фасон колодки: {model.shoe_style}</p>
  </div>
);

const ModelDetailPage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { model, loading } = useSelector((state) => state.models, shallowEqual);

  useEffect(() => {
    dispatch(getModelDetail(id));
  }, [dispatch, id]);

  if (!Object.keys(model).length && !loading)
    return (
      <Layout>
        <Alert variant="danger">Модель не найдена</Alert>
      </Layout>
    );

  return (
    <Layout>
      <Card loading={loading}>
        <Card.Meta
          avatar={
            <>
              {model.images?.length ? (
                model.images.map((im) => (
                  <Avatar
                    size={256}
                    shape="square"
                    key={im.id}
                    src={im.image}
                  />
                ))
              ) : (
                <Avatar size={256} shape="square" src={defaultImage} />
              )}
            </>
          }
          title={model.title}
          description={<Description model={model} />}
        />
      </Card>
      <Card>
        <Tabs type="card">
          {(model.shoes || []).map((item) => (
            <Tabs.TabPane tab={item.size} key={item.id}>
              <div className="title">Детали</div>
              <Table
                columns={columns}
                dataSource={item.details}
                rowKey="id"
                loading={loading}
                pagination={false}
              />
            </Tabs.TabPane>
          ))}
        </Tabs>
        {model &&
          Object.keys(model.tech_processes).map((title) => (
            <Fragment key={title}>
              <div className="title">{title}</div>
              <Table
                columns={additionalColumnsProcess}
                dataSource={model.tech_processes[title]}
                rowKey="id"
                loading={loading}
                pagination={false}
              />
            </Fragment>
          ))}
      </Card>
    </Layout>
  );
};

export default ModelDetailPage;
