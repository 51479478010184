import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../api/api";

const initialState = {
  orders: [],
  order: {},
  chartInfo: [],
  infoLoading: false,
  loading: false,
};

export const getOrders = createAsyncThunk("orders/getOrders", async () => {
  const response = await api.get("/api/orders/");
  return response.data;
});

export const getOrderDetail = createAsyncThunk("orders/id", async (id) => {
  const response = await api.get(`/api/orders/${id}/`);
  return response.data;
});

export const readyOrder = createAsyncThunk("orders/id", async (id) => {
  const response = await api.post("/api/send-order-to-warehouse/", { id });
  return response.data;
});

export const getOrderAnalyticsChart = createAsyncThunk(
  "orders/getOrderAnalyticsChart",
  async (data) => {
    const response = await api.post(`/api/order/chart/`, data);
    return response.data;
  }
);

function saveData(blob, fileName) {
  // does the same as FileSaver.js
  var a = document.createElement("a");
  document.body.appendChild(a);
  a.style = "display: none";

  var url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = fileName;
  a.click();
  window.URL.revokeObjectURL(url);
}

export const downloadOrder = createAsyncThunk(
  "orders/downloadOrder",
  async (id) => {
    const response = await api.get(`/api/download-order?order-id=${id}`);
    var headers = response.headers;
    var blob = new Blob([response.data], {
      type: headers["content-type"],
    });
    saveData(blob, "file.docx");
    return response.data;
  }
);

export const ordersSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {},
  extraReducers: {
    [getOrders.pending]: (state) => {
      state.loading = true;
    },
    [getOrders.rejected]: (state) => {
      state.loading = false;
    },
    [getOrders.fulfilled]: (state, action) => {
      state.orders = action.payload;
      state.loading = false;
    },
    [getOrderDetail.pending]: (state) => {
      state.loading = true;
    },
    [getOrderDetail.rejected]: (state) => {
      state.loading = false;
    },
    [getOrderDetail.fulfilled]: (state, action) => {
      state.order = action.payload;
      state.loading = false;
    },
    [getOrderAnalyticsChart.pending]: (state, action) => {
      state.chartInfo = [];
      state.infoLoading = true;
    },
    [getOrderAnalyticsChart.rejected]: (state, action) => {
      state.chartInfo = [];
      state.infoLoading = false;
    },
    [getOrderAnalyticsChart.fulfilled]: (state, action) => {
      state.chartInfo = action.payload;
      state.infoLoading = false;
    },
  },
});

export const act = ordersSlice.actions;

export default ordersSlice.reducer;
