import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Button,
  Card,
  Form,
  InputNumber,
  Modal,
  Select,
  Table,
  Tag,
} from "antd";

import { Layout } from "../../components";
import {
  doneWorkersOperations,
  getWorkersOperations,
  defectWorkersOperations,
  getDefectsList,
} from "../../redux/workersSlice";

import "./style.css";

const MasterPage = () => {
  const dispatch = useDispatch();
  const { workersOperations, defectsList, defectsLoading } = useSelector(
    (state) => state.workers
  );
  const [modal, setModal] = useState({ open: false, task: {} });
  const [modalDefect, setModalDefect] = useState({
    open: false,
    defects_all: {
      defects: 6,
      defects_type: "Скользкая жопа",
    },
  });

  const columns = [
    {
      title: "Модель: размер",
      dataIndex: "model",
    },
    {
      title: "Организация",
      dataIndex: "customer",
    },
    {
      title: "Операция",
      dataIndex: "operation",
    },
    {
      title: "Работа",
      dataIndex: "",
      render: (_, item) => {
        const color =
          item.current_amount === item.quantity ? "#198754" : "#0d6efd";
        return (
          <Tag className="tag" color={color}>
            {item.current_amount}/{item.quantity}
          </Tag>
        );
      },
    },
    {
      title: "Дефекты",
      dataIndex: "",
      render: (_, record) => {
        return (
          <Button
            onClick={(e) => {
              e.stopPropagation();
              dispatch(getDefectsList());
              setModalDefect({ open: true, defects_all: record });
            }}
          >
            Зарегистрировать дефект
          </Button>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(getWorkersOperations());
  }, [dispatch]);

  const checkPrice = (_, value) => {
    if (value > 0) {
      return Promise.resolve();
    }
    return Promise.reject(new Error("Количество должно быть больше 0"));
  };

  const checkNumber = (_, value) => {
    const num = value?.toString()?.match(/\.(\d+)/)?.[1].length;
    if (!num || num < 3) {
      return Promise.resolve();
    }
    return Promise.reject(
      new Error("После запятой должно быть меньше 2 знаков")
    );
  };

  const doneJob = (val) => {
    const { id } = modal.task;
    dispatch(doneWorkersOperations({ id, ...val }));
    setModal({ open: false, task: {} });
  };

  const defectJob = (val) => {
    const { id } = modalDefect.defects_all;
    dispatch(defectWorkersOperations({ id, ...val }));
    setModalDefect({ open: false, defects_all: {} });
  };

  return (
    <Layout title="Список задач">
      <Card>
        <Table
          dataSource={workersOperations}
          columns={columns}
          className="table"
          pagination={false}
          rowKey="id"
          onRow={(record) => ({
            onClick: () => setModal({ open: true, task: record }),
          })}
        />
      </Card>
      <Modal
        title="Информация о задаче"
        visible={modal.open}
        destroyOnClose
        onCancel={() => setModal({ open: false, task: {} })}
        okText="Сохранить"
        cancelText="Отмена"
        okButtonProps={{
          htmlType: "submit",
          form: "task",
        }}
      >
        <Form
          layout="vertical"
          name="task"
          id="task"
          initialValues={modal.task}
          onFinish={doneJob}
        >
          <Form.Item
            label="Количество выполненных операций"
            name="current_amount"
            rules={[{ required: true, message: "Введите данные" }]}
          >
            <InputNumber min={0} max={modal.task.quantity} />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Информация о дефектах"
        visible={modalDefect.open}
        destroyOnClose
        onCancel={() => setModalDefect({ open: false, defects_all: {} })}
        okText="Сохранить"
        cancelText="Отмена"
        okButtonProps={{
          htmlType: "submit",
          form: "defects_all",
        }}
      >
        <Form
          layout="vertical"
          name="defects_all"
          id="defects_all"
          initialValues={modalDefect.defects_all}
          onFinish={defectJob}
        >
          <Form.Item
            rules={[
              { required: true, message: "Введите данные" },
              { validator: checkPrice },
            ]}
            label="Количество бракованных деталей"
            name="defects"
          >
            <InputNumber min={0} />
          </Form.Item>
          <Form.Item
            label="Значение"
            name="defect_value"
            rules={[
              { required: true, message: "Введите данные" },
              { validator: checkNumber },
            ]}
          >
            <InputNumber min={0} step="0.1" />
          </Form.Item>
          <Form.Item
            rules={[{ required: true, message: "Выберите из списка" }]}
            label="Тип дефекта"
            name="defects_type"
          >
            <Select
              showSearch
              filterOption={(val, op) => op.label.includes(val)}
              options={defectsList}
              loading={defectsLoading}
            />
          </Form.Item>
        </Form>
      </Modal>
    </Layout>
  );
};

export default MasterPage;
