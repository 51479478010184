import React from "react";
import { Navigate } from "react-router";
import { Alert } from "react-bootstrap";
import { useSelector } from "react-redux";
import router from "../../utils/router";

import { Layout } from "../../components";
import { UserRoles } from "../../utils/roles";

const HomePage = () => {
  const { isAuth, user } = useSelector((state) => state.auth);

  if (!isAuth) {
    return <Navigate to={{ pathname: router.login }} />;
  }

  if (user.role === UserRoles.superuser || user.role === UserRoles.observer) {
    return <Navigate to={{ pathname: router.modules }} />;
  }

  if (user.role === UserRoles.worker) {
    return <Navigate to={{ pathname: router.master }} />;
  }

  if (user.role === UserRoles.warehouseman) {
    return <Navigate to={{ pathname: router.warehouse }} />;
  }

  if (user.role === UserRoles.calcman) {
    return <Navigate to={{ pathname: router.stat }} />;
  }

  return (
    <Layout>
      <Alert className="w-75 mx-auto fs-3 text-center" variant="success">
        Добро пожаловать!
      </Alert>
    </Layout>
  );
};

export default HomePage;
