import { Button, Input } from "antd";
import React, { useState } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./FishboneMy.css";

const FishboneMy = () => {
  const [causes, setCauses] = useState([]);
  const [headType, setHeadType] = useState();
  const location = useLocation();
  const state = location.state;

  useEffect(() => {
    if (state && state.from) {
      setHeadType(state.from);
    }
  }, [state]);

  const addMain = () => {
    setCauses((old) => [...old, []]);
  };

  const removeMain = () => {
    const newArr = [...causes];
    newArr.pop();
    setCauses(newArr);
  };

  const addSecond = (cat) => {
    const newArr = [...causes];
    newArr[cat] = [...newArr[cat], []];
    setCauses(newArr);
  };

  const removeSecond = (cat) => {
    const newArr = [...causes];
    newArr[cat].pop();
    setCauses(newArr);
  };

  const addThird = (cat, ind) => {
    const newArr = [...causes];
    newArr[cat][ind] = [...newArr[cat][ind], <Input />];
    setCauses(newArr);
  };

  const removeThird = (cat, ind) => {
    const newArr = [...causes];
    newArr[cat][ind].pop();
    setCauses(newArr);
  };

  const getRootCauses = (cat, ind, pos) => (
    <div
      className="rootCauses"
      style={{ flexDirection: pos === "top" ? "column" : "column-reverse" }}
    >
      <Input
        addonBefore={
          <Button
            onClick={() => removeThird(cat, ind)}
            disabled={causes[cat][ind].length === 0}
          >
            -
          </Button>
        }
        addonAfter={
          <Button
            onClick={() => addThird(cat, ind)}
            disabled={causes[cat][ind].length === 3}
          >
            +
          </Button>
        }
      />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: pos === "top" ? "column" : "column-reverse",
        }}
      >
        {(causes[cat][ind] || []).map((rootCause, index) => (
          <React.Fragment key={`${cat}_${ind}_${index}`}>
            {rootCause}
          </React.Fragment>
        ))}
      </div>
    </div>
  );

  const getRootCausesMain = (cat, pos) =>
    (causes[cat] || []).map((_, index) => (
      <React.Fragment key={`${cat}_${index}`}>
        {getRootCauses(cat, index, pos)}
      </React.Fragment>
    ));

  const getPosCauses = (pos) => {
    const categories = Object.keys(causes);
    const middle = parseInt(categories.length / 2);

    const arr = {
      top: categories.slice(0, middle),
      bottom: categories.slice(middle),
    };

    const topCauses = (arr[pos] || []).map((category, index) => (
      <div key={`"${pos}_${index}`} className="causeContent">
        {pos === "top" ? (
          <>
            <div className="causeFirstLine">
              <Button
                onClick={() => removeSecond(category)}
                disabled={causes[category].length === 0}
              >
                -
              </Button>
              <Input.TextArea
                className="cause"
                autoSize={{ minRows: 1, maxRows: 5 }}
              />
              <Button
                onClick={() => addSecond(category)}
                disabled={causes[category].length === 3}
              >
                +
              </Button>
            </div>
            <div className="causeAndLine">
              <div style={{ display: "flex", flexDirection: "column" }}>
                {getRootCausesMain(category, "top")}
              </div>
              <div className="diagonalLine blueTopBottom" />
            </div>
          </>
        ) : (
          <>
            <div className="causeAndLine">
              <div style={{ display: "flex", flexDirection: "column-reverse" }}>
                {getRootCausesMain(category, "bottom")}
              </div>
              <div className="diagonalLine blueBottomTop" />
            </div>
            <div className="causeFirstLine">
              <Button
                onClick={() => removeSecond(category)}
                disabled={causes[category].length === 0}
              >
                -
              </Button>
              <Input.TextArea
                className="cause"
                autoSize={{ minRows: 1, maxRows: 5 }}
              />
              <Button
                onClick={() => addSecond(category)}
                disabled={causes[category].length === 3}
              >
                +
              </Button>
            </div>
          </>
        )}
      </div>
    ));

    return <div className="causesGroup">{topCauses}</div>;
  };

  return (
    <div className="fishboneChart">
      <div className="causes">
        {getPosCauses("top")}
        <div className="lineEffect blueBorder" />
        {getPosCauses("bottom")}
      </div>
      <div className="effect blue_">
        <Button onClick={removeMain} disabled={causes.length === 0}>
          -
        </Button>
        <Input.TextArea
          autoSize={{ minRows: 1, maxRows: 10 }}
          value={headType}
          onChange={(e) => setHeadType(e.target.value)}
        />
        <Button onClick={addMain} disabled={causes.length === 6}>
          +
        </Button>
      </div>
    </div>
  );
};

export default FishboneMy;
