export const columnsFirst = [
  {
    title: "Критерий оценки",
    dataIndex: "name",
  },
  {
    title: "Выполнение критерия поставщиком",
    dataIndex: "suppliers",
  },
  {
    title: "Оценка (баллы)",
    dataIndex: "points",
  },
];

export const columnsSecond = [
  {
    title: "",
    dataIndex: "name",
  },
  {
    title: "Выполнение критерия поставщиком",
    dataIndex: "suppliers",
  },
  {
    title: "Оценка (баллы)",
    dataIndex: "points",
  },
];

export const rowsFirst = [
  {
    key: "10",
    name: "Репутация поставщика как делового партнера",
    suppliers: [
      {
        value: "4",
        label:
          "Полное соблюдение контрактных обязательств по качеству условиям поставки, производственным возможностям, экономическому состоянию, цене.",
      },
      {
        value: "3",
        label: "Имеются незначительные отклонения в факторах приемлемости.",
      },
      {
        value: "2",
        label: "Репутация поставщика противоречива по разным источникам.",
      },
      {
        value: "0",
        label: "Поставщик не заслуживает доверия как деловой партнер.",
      },
    ],
    points: false,
  },
  {
    key: "11",
    name: "Наличие и состояние системы менеджмента качества (СМК)",
    suppliers: [
      {
        value: "4",
        label:
          "Система качества соответствует ГОСТ Р ИСО 9001-2013, имеется сертификат на систему качества.",
      },
      {
        value: "3",
        label: "СМК документирована, внедрена и подготовлена к сертификации.",
      },
      {
        value: "2",
        label: "СМК находиться на стадии доработки.",
      },
      {
        value: "0",
        label: "СМК не документирована.",
      },
    ],
    points: false,
  },
  {
    key: "12",
    name: "Результаты оценки образцов",
    suppliers: [
      {
        value: "4",
        label: "Положительные.",
      },
      {
        value: "1-2",
        label: "Имеются устранимые дефекты.",
      },
      {
        value: "0",
        label: "Отрицательные.",
      },
    ],
    points: false,
  },
  {
    key: "13",
    name: "Цена",
    suppliers: [
      {
        value: "4",
        label: "Приемлемая.",
      },
      {
        value: "1-2",
        label: "Не приемлемая, но других альтернатив нет.",
      },
      {
        value: "0",
        label: "Не приемлемая.",
      },
    ],
    points: false,
  },
];

export const rowsSecond = [
  {
    key: "20",
    name: "Состояние СМК (результаты оценки на предприятии поставщика)",
    suppliers: [
      {
        value: "4",
        label: "СМК функционирует и эффективна.",
      },
      {
        value: "2-3",
        label: "СМК функционирует, но требует совершенствования.",
      },
      {
        value: "0",
        label: "СМК документирована, но не эффективна.",
      },
    ],
    points: false,
  },
  {
    key: "21",
    name: "Стабильность качества закупленной продукции",
    suppliers: [
      {
        value: "4",
        label: "Претензий к качеству и комплектности продукции нет.",
      },
      {
        value: "3",
        label: "Количество претензий не превышает 5 %.",
      },
      {
        value: "1-2",
        label: "Количество претензий достигает 10 %.",
      },
      {
        value: "0",
        label: "Количество претензий превышает 15 %.",
      },
    ],
    points: false,
  },
  {
    key: "22",
    name: "Реакция поставщика на претензии к качеству его продукции",
    suppliers: [
      {
        value: "4",
        label: "Меры принимаются по 100 % претензий.",
      },
      {
        value: "2-3",
        label: "Меры принимаются по 80 % претензий.",
      },
      {
        value: "1",
        label: "Меры принимаются по 60 % претензий.",
      },
      {
        value: "0",
        label: "Меры принимаются менее чем в 60 % претензий.",
      },
    ],
    points: false,
  },
  {
    key: "23",
    name: "Стабильность объемов и сроков поставки",
    suppliers: [
      {
        value: "4",
        label: "Объемы и сроки поставок соблюдаются полностью.",
      },
      {
        value: "2-3",
        label: "Имеются единичные срывы в поставках.",
      },
      {
        value: "0",
        label: "Объемы и сроки поставок систематически нарушаются.",
      },
    ],
    points: false,
  },
];

export const results = {
  90: {
    title: "Предпочтение при заключении контракта",
    description:
      "Поставщики продукции/услуг, имеющие с предприятием длительные деловые связи, стабильно выполняющие контрактные обязательства по качеству продукции и условиям поставки. Вероятность срывов мала.",
  },
  80: {
    title: "Контракт возможен",
    description:
      "Поставщики, в качестве поставок которых бывают незначительные сбои в сроках/объёмах поставок или отклонения коммерческого плана при сохранении стабильного качества продукции.",
  },
  60: {
    title: "Контракт только по особому разрешению",
    description:
      "Поставщики, имеющие с предприятием деловые связи, но не в полном объёме удовлетворяющие требованиям к качеству (цене/условиям поставки), но других альтернатив нет.",
  },
  0: {
    title: "Контракт не возможен",
    description:
      "Поставщики, которые без значительных изменений не могут деловыми партнёрами предприятия.",
  },
};
