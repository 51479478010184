import React, { useCallback, useEffect, useState } from "react";
import { Button, Form, Input, Popover, Select, Table } from "antd";
import { CloseOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { ABCTitle, defaultRowValues } from "./helpers";

const content = (
  <div>
    <div>2 - Полная новизна</div>
    <div>1 - Частично есть элементы новизны</div>
    <div>0 - Новизны нет</div>
  </div>
);

const TitlePopup = ({ children }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      {children}
      <Popover content={content} title="Оценка:">
        <InfoCircleOutlined />
      </Popover>
    </div>
  );
};

export const TableData = ({
  columnBlocks,
  type,
  title,
  subTitle,
  titleType,
}) => {
  const [count, setCount] = useState(defaultRowValues[type].length + 1);
  const [hasIndex, setHasIndex] = useState({});
  const [rows, setRows] = useState(defaultRowValues[type]);
  const [result, setResult] = useState({});
  const [form] = Form.useForm();

  const arrValSelect = Object.values(hasIndex);

  const handleDelete = useCallback(
    (key) => {
      setRows((prev) => prev.filter((item) => item.key !== key));
      form.submit();
    },
    [form]
  );

  const [columns, setColumns] = useState([
    {
      title,
      dataIndex: "name",
      key: "name",
    },
    {
      title: <TitlePopup>{subTitle}</TitlePopup>,
      dataIndex: "process",
      children: [],
    },
    {
      key: "operation",
      render: (_, record) => (
        <Button
          icon={<CloseOutlined />}
          onClick={() => handleDelete(record.key)}
        />
      ),
    },
  ]);

  useEffect(() => {
    const newGroup = columnBlocks.map(({ id }, index) => ({
      title: titleType === "letter" ? ABCTitle.charAt(index) : index + 1,
      dataIndex: index + 1,
      key: id,
      render: (_, record) => (
        <Form.Item
          name={`${index}-${record.key}-${id}`}
          preserve={false}
          key={id}
          style={{ margin: 0 }}
        >
          <Select
            options={[
              {
                label: "2",
                value: 2,
              },
              {
                label: "1",
                value: 1,
              },
              {
                label: "0",
                value: 0,
              },
            ]}
          />
        </Form.Item>
      ),
    }));

    setColumns([
      {
        title,
        dataIndex: "name",
        key: "name",
        render: (name) => <Input defaultValue={name} />,
      },
      {
        title: <TitlePopup>{subTitle}</TitlePopup>,
        dataIndex: "process",
        children: newGroup,
      },
      {
        key: "operation",
        render: (_, record) => (
          <Button
            icon={<CloseOutlined />}
            onClick={() => handleDelete(record.key)}
          />
        ),
      },
    ]);
  }, [columnBlocks, handleDelete, subTitle, title, titleType]);

  const handleAdd = () => {
    const newData = {
      key: count,
      name: "",
    };
    setRows([...rows, newData]);
    setCount(count + 1);
  };

  const handleChangeIndex = (value, index) => {
    if (value) {
      setHasIndex((prev) => ({
        ...prev,
        [index]: value,
      }));
    }
  };

  const handleClearIndex = (index) => {
    setHasIndex((prev) => ({
      ...prev,
      [index]: null,
    }));
  };

  const handleChange = useCallback((_, allFields) => {
    const allSum = allFields.reduce((acc, item) => {
      const index = item.name[0].split("-")[0];
      const sum = (acc[index] ?? 0) + (item.value ?? 0);

      return {
        ...acc,
        [index]: sum,
      };
    }, {});

    setResult(allSum);
  }, []);

  const handleFinish = useCallback((values) => {
    const allSum = Object.keys(values).reduce((acc, item) => {
      const index = item.split("-")[0];
      const sum = (acc[index] ?? 0) + (values[item] ?? 0);

      return {
        ...acc,
        [index]: sum,
      };
    }, {});

    setResult(allSum);
  }, []);

  return (
    <div style={{ width: "100%" }}>
      <Button onClick={handleAdd} type="primary" style={{ marginBottom: 16 }}>
        Добавить
      </Button>
      <Form onFieldsChange={handleChange} onFinish={handleFinish} form={form}>
        <Table
          columns={columns}
          dataSource={rows}
          pagination={false}
          bordered
          size="small"
          summary={() => (
            <Table.Summary>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}>Сумма пунктов</Table.Summary.Cell>
                {columnBlocks.length === 0 ? (
                  <Table.Summary.Cell index={1} />
                ) : (
                  columnBlocks.map((_, index) => (
                    <Table.Summary.Cell index={index + 1} key={index}>
                      {result[index] ?? ""}
                    </Table.Summary.Cell>
                  ))
                )}
                <Table.Summary.Cell
                  index={
                    columnBlocks.length === 0 ? 2 : columnBlocks.length + 1
                  }
                />
              </Table.Summary.Row>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}>
                  Последовательность обработки для проведения FMEA процесса
                </Table.Summary.Cell>
                {columnBlocks.length === 0 ? (
                  <Table.Summary.Cell index={1} />
                ) : (
                  columnBlocks.map((_, index) => (
                    <Table.Summary.Cell index={index + 1} key={index}>
                      <Select
                        allowClear
                        onClear={() => handleClearIndex(index)}
                        onChange={(val) => handleChangeIndex(val, index)}
                        options={columnBlocks.map((_, index) => ({
                          label: index + 1,
                          value: index + 1,
                          disabled: arrValSelect.includes(index + 1),
                        }))}
                        style={{ width: "100%" }}
                      />
                    </Table.Summary.Cell>
                  ))
                )}
                <Table.Summary.Cell
                  index={
                    columnBlocks.length === 0 ? 2 : columnBlocks.length + 1
                  }
                />
              </Table.Summary.Row>
            </Table.Summary>
          )}
        />
      </Form>
    </div>
  );
};
