export const table1 = [
  "1. Среди предметов нет тех, которые не нужны для запланированной на сегодня работы (кроме аккуратно сложенной документации, комплекта канцтоваров, телефона, стандартной таблички владельца места).",
  "2. Среди предметов нет тех, количество которых превышает необходимое.",
  "3. Все углы кабинета, стены, зазоры между шкафами и столами очищены от ненужных для кабинета вещей.",
  "4. Полки, шкафы, столы не загружены неиспользуемыми предметами.",
  "5. В компьютере нет лишних документов. Рабочий стол очищен от ненужных файлов (папок, ярлыков).",
];

export const table2 = [
  "1. Все используемые предметы и документы имеют свое постоянное местонахождение (места их хранения определены). Их перемешивание исключается (существует их классификация).",
  "2. Рабочее место эргономично (предметы, используемые часто, на расстоянии вытянутой руки, достаточное освещение, расстояние до принтера максимум 5 м).",
  "3. Понятно где и что лежит. Достаточный уровень визуализации содержимого шкафов, тумб, папок, лотков для документов, ящиков, столов и т.д.",
  "4. Наличие инфо стендов в кабинетах, коридорах (цели и политики, информация по 5S, план эвакуации, ответственный за уборку и т.д.).",
  "5. Названия папок хранения документов на бумажных носителях соответствуют названиям папок в компьютере, содержащих в электронном виде аналоги документов (в случае хранения как в эл.виде, так и на бумажном носителе).",
];

export const table3 = [
  "1. Рабочие поверхности компьютера, клавиатуры, мышки, коврика для мышки, настольной лампы, принтера, телефона, другой оргтехники содержатся в чистоте",
  "2. Пол, шкафы, окна, подоконники, столы, стулья, стены, выключатели света и т.д. содержатся в чистоте.",
  "3. Достаточно корзин для мусора, салфеток для монитора, стола.",
  "4. Достаточно средств бытовой химии, ветоши и другого инвентаря для уборки.",
  "5. Компьютеры проверяются на наличие вирусов, существует постоянно действующая система защиты от вирусов.",
];

export const table4 = [
  "1. Есть стандарт информационных табличек и указателей (табличек на двери кабинетов, куверт на столах работников, навигационных табличек и наклеек и т.д.); стендов.",
  "2. Есть стандарт обеспечения раб.места (канцтоварами, техникой и т.п.).",
  "3. Есть стандарт идентификации и визуализации информации (папки, файлы, цветовая кодировка, размещение).",
  "4. Есть ли стандарт уборки, в котором закреплена периодичность уборки и ответственные с закреплением зон: кабинетов, мест общего пользования.",
  "5. Есть стандарт организации эл. пространства и эл.документооборота.",
];

export const table5 = [
  "1. Установленные стандарты и правила выполняются.",
  "2. Уборка проводится регулярно.",
  "3. Подаются предложения по улучшению рабочего пространства.",
  "4. Поданные предложения рассматриваются и внедряются. Ведется учет поданных и реализованных предложений.",
  "5. Проводятся регулярные проверки выполнения 5S (аудиты по производсвенной культуре с четкими критериями и периодичностью).",
];

export const columnsAll = [
  {
    dataIndex: "1",
  },
  {
    dataIndex: "2",
  },
  {
    dataIndex: "3",
  },
  {
    dataIndex: "4",
  },
];

export const resultData = {
  5: {
    title: "баллов",
    description: "соответствует безупречно",
  },
  4: {
    title: "балла",
    description: "хорошо",
  },
  3: {
    title: "балла",
    description: "посредственно",
  },
  2: {
    title: "балла",
    description: "еще много надо сделать",
  },
  1: {
    title: "балл",
    description: "не соответствует полностью",
  },
};
