import React from "react";
import { Alert } from "react-bootstrap";

import { Layout } from "../../components";

const NotFoundPage = () => {
  return (
    <Layout>
      <Alert variant="danger">
        <h2 className="text-center m-0">Страница не найдена</h2>
      </Alert>
    </Layout>
  );
};

export default NotFoundPage;
