import { Button, Form, Input, Modal, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTech, sendTech } from "../../../redux/warehouseSlice";

const MaterialsContent = () => {
  const dispatch = useDispatch();
  const { techEquip, loading } = useSelector((state) => state.ware);
  const [modal, setModal] = useState({ open: false, type: "", item: {} });

  useEffect(() => {
    dispatch(getTech());
  }, [dispatch]);

  const send = (val) => {
    dispatch(
      sendTech({
        id: modal.item.id,
        quantity:
          modal.type === "add" ? `+${val.quantity}` : `-${val.quantity}`,
      })
    );
    setModal({ open: false, type: "", item: {} });
  };

  const columns = [
    {
      title: "Материал",
      dataIndex: "title",
    },
    {
      title: "Цена",
      dataIndex: "price",
    },
    {
      title: "Количество",
      dataIndex: "quantity",
      render: (q, rec) => `${q} ${rec.unit}`,
    },
    {
      title: "Действия",
      dataIndex: "",
      key: "x",
      render: (_, item) => (
        <>
          <Button onClick={() => setModal({ open: true, type: "get", item })}>
            Выдача
          </Button>
          <Button onClick={() => setModal({ open: true, type: "add", item })}>
            Получение
          </Button>
        </>
      ),
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        loading={loading}
        dataSource={techEquip}
        pagination={false}
        rowKey="id"
      />
      <Modal
        visible={modal.open}
        destroyOnClose
        okButtonProps={{
          htmlType: "submit",
          form: "myForm",
        }}
        okText={modal.type === "add" ? "Получение" : "Выдача"}
        cancelText="Отменить"
        cancelButtonProps={{
          danger: true,
        }}
        onCancel={() => setModal({ open: false, type: "", item: {} })}
      >
        <p>{modal.type === "add" ? "Получение" : "Выдача"}</p>
        <Form id="myForm" name="material" onFinish={send}>
          <Form.Item
            name="quantity"
            rules={[
              () => ({
                validator(_, value) {
                  if (modal.type === "add") {
                    return Promise.resolve();
                  }
                  if (Number(value) < modal.item.quantity) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("Недостаточно количество на складе!")
                  );
                },
              }),
            ]}
          >
            <Input placeholder="Введите число" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default MaterialsContent;
