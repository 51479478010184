export const generateHandleStyle = (number, key) =>
  new Array(number).fill(undefined).reduce(
    (acc, _, index) => ({
      ...acc,
      [index]: { [key]: `${((index + 1) * 100) / (number + 1)}%` },
    }),
    {}
  );

export const generateHandlePort = (number, key) =>
  new Array(number).fill(undefined).map((_, index) => `${key}-${index}`);

export const defaultStyles = {
  position: "absolute",
  maxWidth: "61px",
  padding: 0,
};

export const configArrow = {
  0: {
    markerStart: "arrowclosed",
    markerEnd: "arrowclosed",
  },
  1: {
    markerStart: "arrowclosed",
    markerEnd: undefined,
  },
  2: {
    markerStart: undefined,
    markerEnd: "arrowclosed",
  },
  3: {
    markerStart: undefined,
    markerEnd: undefined,
  },
};
