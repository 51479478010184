import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import {
  HomePage,
  MasterPage,
  ModelDetailPage,
  ModelsPage,
  NotFoundPage,
  OrdersPage,
  LoginPage,
  WarehouseOrdersPage,
  WarehousePage,
} from "./pages";
import router from "./utils/router";
import OrderDetail from "./pages/orders/OrderDetail";
import { checkToken, offLoading } from "./redux/authSlice";
import { ConfigProvider, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import Analytics from "./pages/analytics/Analytics";
import AnalyticAbout from "./pages/analytics/AnalyticAbout";
import StatMethods from "./pages/StatMethods/StatMethods";
import StatMethodsRender from "./pages/StatMethods/StatMethodsRender";
import TypeDefects from "./pages/typeDefects";
import WithProtectedPage from "./components/withProtectedPage";
import { UserRoles } from "./utils/roles";
import ru_RU from "antd/lib/locale/ru_RU";
import "moment/locale/ru";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const App = () => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.auth);

  useEffect(() => {
    if (localStorage.getItem("token")) {
      dispatch(checkToken());
    } else {
      dispatch(offLoading());
    }
  }, [dispatch]);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          flex: "1 1 100%",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spin indicator={antIcon} />
      </div>
    );
  }

  return (
    <ConfigProvider locale={ru_RU}>
      <BrowserRouter>
        <Routes>
          <Route path={router.main} element={<HomePage />} />
          <Route path={router.login} element={<LoginPage />} />
          <Route
            path={router.modules}
            element={
              <WithProtectedPage
                allowRoles={[UserRoles.observer, UserRoles.superuser]}
              >
                <ModelsPage />
              </WithProtectedPage>
            }
          />
          <Route
            path={router.typeDefects}
            element={
              <WithProtectedPage
                allowRoles={[UserRoles.observer, UserRoles.superuser]}
              >
                <TypeDefects />
              </WithProtectedPage>
            }
          />
          <Route
            path={`${router.modules}/:id`}
            element={
              <WithProtectedPage
                allowRoles={[UserRoles.observer, UserRoles.superuser]}
              >
                <ModelDetailPage />
              </WithProtectedPage>
            }
          />
          <Route
            path={router.orders}
            element={
              <WithProtectedPage
                allowRoles={[UserRoles.observer, UserRoles.superuser]}
              >
                <OrdersPage />
              </WithProtectedPage>
            }
          />
          <Route
            path={`${router.orders}/:id`}
            element={
              <WithProtectedPage
                allowRoles={[UserRoles.observer, UserRoles.superuser]}
              >
                <OrderDetail />
              </WithProtectedPage>
            }
          />
          <Route
            path={router.analytics}
            element={
              <WithProtectedPage
                allowRoles={[UserRoles.observer, UserRoles.superuser]}
              >
                <Analytics />
              </WithProtectedPage>
            }
          />
          <Route
            path={`${router.analytics}/:id`}
            element={
              <WithProtectedPage
                allowRoles={[UserRoles.observer, UserRoles.superuser]}
              >
                <AnalyticAbout />
              </WithProtectedPage>
            }
          />
          <Route
            path={router.master}
            element={
              <WithProtectedPage allowRoles={[UserRoles.worker]}>
                <MasterPage />
              </WithProtectedPage>
            }
          />
          <Route
            path={router.warehouse}
            element={
              <WithProtectedPage
                allowRoles={[
                  UserRoles.superuser,
                  UserRoles.observer,
                  UserRoles.warehouseman,
                ]}
              >
                <WarehousePage />
              </WithProtectedPage>
            }
          />
          <Route
            path={router.warehouseOrders}
            element={
              <WithProtectedPage
                allowRoles={[
                  UserRoles.superuser,
                  UserRoles.observer,
                  UserRoles.warehouseman,
                ]}
              >
                <WarehouseOrdersPage />
              </WithProtectedPage>
            }
          />
          <Route
            path={router.stat}
            element={
              <WithProtectedPage
                allowRoles={[
                  UserRoles.superuser,
                  UserRoles.observer,
                  UserRoles.calcman,
                ]}
              >
                <StatMethods />
              </WithProtectedPage>
            }
          />
          <Route
            path={`${router.stat}/:stat`}
            element={
              <WithProtectedPage
                allowRoles={[
                  UserRoles.superuser,
                  UserRoles.observer,
                  UserRoles.calcman,
                ]}
              >
                <StatMethodsRender />
              </WithProtectedPage>
            }
          />
          <Route
            path="*"
            element={
              <WithProtectedPage
                allowRoles={[
                  UserRoles.superuser,
                  UserRoles.observer,
                  UserRoles.calcman,
                  UserRoles.warehouseman,
                  UserRoles.worker,
                ]}
              >
                <NotFoundPage />
              </WithProtectedPage>
            }
          />
        </Routes>
      </BrowserRouter>
    </ConfigProvider>
  );
};

export default App;
