import React, { useCallback } from 'react';
import { List } from 'antd';
import { useReactFlow } from 'reactflow';

import './style.css';

const ContextMenuEdge = ({ id, top, left, right, bottom, ...props }) => {
  const { setEdges, setNodes, getNode } = useReactFlow();

  const deleteNode = useCallback(() => {
    let findTarget = null;
    setEdges((edges) => edges.filter((edge) => {
      if (edge.id !== id){
        return true;
      } else {
        findTarget = edge.target;
        return false;
      }
    }));
    const nodeFind = getNode(findTarget);
    if (nodeFind.type === "pointNode") {
      setNodes((nodes) => nodes.filter((node) => node.id !== nodeFind.id))
    }
  }, [getNode, id, setEdges, setNodes]);


  const data = [
    {
      title: 'Удалить',
      onClick: deleteNode,
    }
  ];

  return (
    <div style={{ top, left, right, bottom }} className="context-menu" {...props}>
      <List
        bordered
        dataSource={data}
        renderItem={(item) => (
          <List.Item className='menu-item' onClick={item.onClick}>
            {item.title}
          </List.Item>
        )}
      />
    </div>
  );
}

export default ContextMenuEdge;
