import React, { Fragment, useEffect, useRef, useState } from "react";
import { Button, Card, Descriptions, Form, Table } from "antd";
import { TablePaper } from "./Table";
import {
  columnsAll,
  resultData,
  table1,
  table2,
  table3,
  table4,
  table5,
} from "./helper";

import styles from "./styles.module.css";
import { SavePNGButton } from "../../../components/SavePNGButton";

let locale = {
  emptyText: <div />,
};

export const EvaluationPaper = () => {
  const [globalSum, setGlobalSum] = useState("");
  const [saveAllSum, setSaveAllSum] = useState({});
  const [showTotal, setShowTotal] = useState(false);
  const ref = useRef();

  const getSum = (name, info) => {
    const data = info.forms[name];
    const allData = data.getFieldsValue();
    const formatData = Object.values(allData);
    const filteredData = formatData.filter((item) => Number.isInteger(item));

    const sumBy =
      formatData.length === filteredData.length
        ? filteredData.reduce((acc, item) => (item ? acc + item : acc), 0)
        : "";

    setSaveAllSum((prev) => ({
      ...prev,
      [name]: sumBy,
    }));
    setShowTotal(false);
  };

  useEffect(() => {
    const filteredData = Object.values(saveAllSum).filter(Boolean);
    const allSum = filteredData.length
      ? filteredData.reduce((acc, item) => (item ? acc + item : acc), 0)
      : "";

    setGlobalSum(allSum);
  }, [saveAllSum]);

  const handleSubmit = (val) => setShowTotal(true);

  return (
    <div>
      <Card ref={ref}>
        <Form.Provider onFormChange={getSum}>
          <TablePaper title="1S. Сортировка" dataArray={table1} name="1" />
          <TablePaper
            title="2S. Систематизация"
            dataArray={table2}
            name="2"
            showHeader={false}
          />
          <TablePaper
            title="3S. Содержание в чистоте"
            dataArray={table3}
            name="3"
            showHeader={false}
          />
          <TablePaper
            title="4S. Стандартизация"
            dataArray={table4}
            name="4"
            showHeader={false}
          />
          <TablePaper
            title="5S. Соблюдение и совершенствование"
            dataArray={table5}
            name="5"
            showHeader={false}
          />
          <Table
            showHeader={false}
            columns={columnsAll}
            className={styles.row}
            bordered
            locale={locale}
            summary={() => (
              <Table.Summary.Row>
                <Table.Summary.Cell index={0} colSpan={2}>
                  ИТОГО (сумма всех баллов):
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={0} />
                <Table.Summary.Cell index={2} colSpan={2}>
                  {globalSum}
                </Table.Summary.Cell>
              </Table.Summary.Row>
            )}
          />
        </Form.Provider>
        <Button
          style={{ marginTop: 15, marginBottom: 15 }}
          onClick={handleSubmit}
        >
          Рассчитать
        </Button>
        {showTotal && (
          <Descriptions title="Вывод" bordered column={1}>
            {Object.keys(saveAllSum).map(
              (item) =>
                saveAllSum[item] && (
                  <Fragment key={item}>
                    <Descriptions.Item>
                      {`${item}S - ${saveAllSum[item]} ${
                        resultData[saveAllSum[item]].title
                      } - ${resultData[saveAllSum[item]].description}`}
                    </Descriptions.Item>
                  </Fragment>
                )
            )}
          </Descriptions>
        )}
      </Card>
      <Card>
        <SavePNGButton containerRef={ref.current} name="оценочный-лист-5s" />
      </Card>
    </div>
  );
};
