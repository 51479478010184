export const columns = [
    {
      title: "Наименование работ",
      dataIndex: "name",
      width: "30%",
    },
    {
      title: "Конструктор",
      dataIndex: "constructor",
      editable: true,
    },
    {
      title: "Технолог",
      dataIndex: "technologist",
      editable: true,
    },
    {
      title: "Служба качества",
      dataIndex: "service",
      editable: true,
    },
    {
      title: "Группа закупок",
      dataIndex: "group",
      editable: true,
    },
    {
      title: "Производство",
      dataIndex: "production",
      editable: true,
    },
  ];

export const rows = [
  {
    key: "0",
    name: "Определение номенклатуры закупаемой продукции",
    constructor: null,
    technologist: null,
    service: null,
    group: null,
    production: null,
  },
  {
    key: "1",
    name: "Установление и документирование требований к продукции",
    constructor: null,
    technologist: null,
    service: null,
    group: null,
    production: null,
  },
  {
    key: "2",
    name: "Составление перечня потенциальных поставщиков по каждому виду продукции",
    constructor: null,
    technologist: null,
    service: null,
    group: null,
    production: null,
  },
  {
    key: "3",
    name: "Оценка, выбор и допуск поставщика по анкетным данным",
    constructor: null,
    technologist: null,
    service: null,
    group: null,
    production: null,
  },
  {
    key: "4",
    name: "Заключение контракта на поставку",
    constructor: null,
    technologist: null,
    service: null,
    group: null,
    production: null,
  },
  {
    key: "5",
    name: "Оценка соответствия продукции установленным контрактом требованиям по факту поставок",
    constructor: null,
    technologist: null,
    service: null,
    group: null,
    production: null,
  },
  {
    key: "6",
    name: "Оценка соответствия объектов и сроков поставки установленным требованиям",
    constructor: null,
    technologist: null,
    service: null,
    group: null,
    production: null,
  },
  {
    key: "7",
    name: "Информирования поставщика об отклонениях",
    constructor: null,
    technologist: null,
    service: null,
    group: null,
    production: null,
  },
  {
    key: "8",
    name: "Ведение реестра / Базы данных приемлемых поставщиков",
    constructor: null,
    technologist: null,
    service: null,
    group: null,
    production: null,
  },
];

export const selectOptions = [
  {
    value: "r",
    label: "Р",
  },
  {
    value: "p",
    label: "П",
  },
  {
    value: "o",
    label: "О",
  },
  {
    value: "i",
    label: "И",
  },
  {
    value: "y",
    label: "У",
  },
];