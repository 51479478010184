import React, { useCallback, useLayoutEffect, useRef, useState } from "react";
import { CardText } from "./CardText";
import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";

const cardWidth = 150;
const step = cardWidth / 2;

export const Diagram = ({ changeLength, title, cardTitle }) => {
  const [blocks, setBlocks] = useState([]);
  const [startPoint, setStartPoint] = useState(0);
  const svgBlock = useRef();
  const container = useRef(null);
  const titleContainer = useRef(null);
  const idCount = useRef(0);

  const rightPoint = (blocks.length - 1) * step;

  const handleAddBlock = useCallback(() => {
    const newBlocks = [...blocks, { id: idCount.current }];
    setBlocks(newBlocks);
    changeLength?.(newBlocks);
    idCount.current = idCount.current + 1;
  }, [blocks, changeLength]);

  const handleDeleteBlock = useCallback(
    (id) => {
      const newBlocks = blocks.filter((item) => item.id !== id);
      setBlocks(newBlocks);
      changeLength?.(newBlocks);
    },
    [blocks, changeLength]
  );

  useLayoutEffect(() => {
    if (svgBlock.current) {
      setStartPoint(svgBlock.current.clientWidth / 2);
    }
  }, [blocks.length]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        overflowX: "auto",
        width: "100%",
      }}
      ref={container}
    >
      <div style={{ margin: "auto" }}>
        <div
          style={{
            display: "flex",
            gap: 10,
            justifyContent: "center",
            alignItems: "center",
          }}
          ref={titleContainer}
        >
          <div style={{ border: "1px solid black", padding: "10px 15px" }}>
            {title}
          </div>
          <Button
            onClick={handleAddBlock}
            disabled={blocks.length === 10}
            icon={<PlusOutlined />}
          />
        </div>
        <svg ref={svgBlock} height="80" width="100%">
          {blocks.map((item, index) => (
            <polyline
              key={item.id}
              points={`${startPoint} 0,${startPoint} 0, ${startPoint} 40, ${
                startPoint + (-rightPoint + index * cardWidth)
              } 40, ${startPoint + (-rightPoint + index * cardWidth)} 80`}
              style={{ fill: "transparent", stroke: "black", strokeWidth: 2 }}
            />
          ))}
        </svg>
        <div style={{ display: "flex", justifyContent: "center" }}>
          {blocks.map((item, index) => (
            <CardText
              key={item.id}
              cardTitle={cardTitle}
              onDelete={() => handleDeleteBlock(item.id)}
              number={cardTitle ? index + 1 : index}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
