import React, { memo, useCallback } from "react";
import { NodeResizer, useReactFlow, useStore } from "reactflow";
import { Input } from "antd";
import clsx from "clsx";

import styles from "./styles.module.css";
import "./style.css";
import Ports from "../components/Ports";

export default memo(({ id, data }) => {
  const { setNodes } = useReactFlow();
  const start = useStore((state) => state.connectionStartHandle);

  const handleChangeText = useCallback(
    (e, key) => {
      setNodes((nds) =>
        nds.map((node) => {
          if (node.id === id) {
            node.data = {
              ...node.data,
              [key]: e.target.value,
            };
          }

          return node;
        })
      );
    },
    [id, setNodes]
  );

  return (
    <div className={styles.wrapperNode}>
      <NodeResizer
        lineClassName={styles.resizeLine}
        handleClassName={styles.resizeHandle}
        minWidth={100}
        minHeight={85}
      />
      <div
        className={styles.node}
        style={{
          backgroundColor: "white",
          border: "1px solid black",
          position: "relative",
          height: "100%",
        }}
      >
        <div
          style={{ display: "flex", flexDirection: "column", height: "100%" }}
        >
          <div style={{ padding: "10px", borderBottom: "1px solid black" }}>
            <Input.TextArea
              value={data.mainText}
              className="nodrag"
              bordered={false}
              style={{
                textAlign: "center",
                padding: 0,
              }}
              onChange={(e) => handleChangeText(e, "mainText")}
              autoSize={{ minRows: 1 }}
            />
          </div>
          <div style={{ padding: "10px", height: "100%" }}>
            <Input.TextArea
              value={data.secondText}
              className="nodrag"
              bordered={false}
              style={{
                textAlign: "center",
              }}
              onChange={(e) => handleChangeText(e, "secondText")}
              autoSize={{ minRows: 1 }}
            />
          </div>
        </div>
        <Ports
          size={10}
          className={clsx(styles.handle, {
            [styles.show]: start,
          })}
        />
      </div>
    </div>
  );
});
