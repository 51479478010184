import { Input } from "antd";
import { useCallback } from "react";

const CustomInput = ({ value, id, setter, sizeBlock }) => {
  const handleChangeText = useCallback(
    (e, key) => {
      setter((nds) =>
        nds.map((node) => {
          if (node.id === id) {
            node.data = {
              ...node.data,
              [key]: e.target.value,
            };
          }

          return node;
        })
      );
    },
    [id, setter]
  );

  return (
    <div
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        width: "100%",
        height: "100%",
        transform: "translate(-50%, -50%)",
        display: "flex",
        alignItems: "center",
        overflow: "hidden",
      }}
    >
      <Input.TextArea
        value={value}
        className="nodrag"
        type="text"
        style={{
          textAlign: "center",
          maxHeight: "100%",
          border: "none",
          outline: "none",
          boxShadow: "none",
          background: "none",
        }}
        onChange={(e) => handleChangeText(e, "mainText")}
        autoSize={{
          minRows: 1,
          maxRows: Math.trunc(sizeBlock.height / 30),
        }}
      />
    </div>
  );
};

export default CustomInput;
