import { Card } from "antd";
import React from "react";

import html from "./html/pooled_assessment.html";
import { SavePNGButton } from "../../components/SavePNGButton";

const Assessment = () => {
  return (
    <Card>
      <SavePNGButton frame />
      <iframe
        title="test"
        style={{ width: "100%", height: "100vh" }}
        srcDoc={html}
      ></iframe>
    </Card>
  );
};

export default Assessment;
