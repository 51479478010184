import axios from "axios";

const test = axios.create({
  baseURL: process.env.REACT_APP_URL ?? "/",
});

test.interceptors.request.use((config) => {
  if (localStorage.getItem("token")) {
    const token = "Token " + localStorage.getItem("token");
    config.headers.Authorization = token;
  }

  return config;
});

export default test;
