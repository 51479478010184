export const options = [
  {
    value: 1,
    label: 1,
  },
  {
    value: 2,
    label: 2,
  },
  {
    value: 3,
    label: 3,
  },
  {
    value: 4,
    label: 4,
  },
  {
    value: 5,
    label: 5,
  },
  {
    value: 6,
    label: 6,
  },
  {
    value: 7,
    label: 7,
  },
  {
    value: 8,
    label: 8,
  },
  {
    value: 9,
    label: 9,
  },
  {
    value: 10,
    label: 10,
  },
];

export const resultMessage = {
  product: {
    1: {
      title: "Отсутствует",
      description: "Нет последствия",
    },
    2: {
      title: "Очень незначительное",
      description:
        "Отделка/шумность изделия не соответствуют ожиданиям потребителя. Дефект замечают придирчивые потребители",
    },
    3: {
      title: "Незначительное",
      description:
        "Отделка/шумность изделия не соответствуют ожиданиям потребителя. Дефект замечает средний потребитель",
    },
    4: {
      title: "Очень слабое",
      description:
        "Отделка и шумность изделия не соответствуют ожиданиям потребителя. Дефект замечает большинство потребителей",
    },
    5: {
      title: "Слабое",
      description:
        "Транспортное средство/узел работоспособно, но система(ы) комфорта/удобства работают малоэффективно. Потребитель испытывает некоторое неудовлетворение",
    },
    6: {
      title: "Умеренное",
      description:
        "Транспортное средство/узел работоспособно, но системы комфорта/удобства неработоспособны. Потребитель испытывает дискомфорт",
    },
    7: {
      title: "Важное",
      description:
        "Транспортное средство/узел работоспособно, но снижен уровень эффективности. Потребитель неудовлетворен",
    },
    8: {
      title: "Очень важное",
      description:
        "Транспортное средство/узел неработоспособно с потерей основной функции",
    },
    9: {
      title: "Опасное с предупреждением",
      description:
        "Весьма высокий ранг значимости, когда вид потенциального дефекта ухудшает безопасность работы транспортного средства или вызывает несоответствие обязательным требованиям безопасности и экологии с предупреждением",
    },
    10: {
      title: "Опасное без предупреждения",
      description:
        "Очень высокий ранг значимости, когда вид потенциального дефекта ухудшает безопасность работы транспортного средства и/или вызывает несоответствие обязательным требованиям безопасности и экологии без предупреждения",
    },
  },
  process: {
    1: {
      title: "Отсутствует",
      description: "Нет последствия",
    },
    2: {
      title: "Очень незначительное",
      description:
        "Небольшое нарушение производственной линии. Может потребоваться доработка части продукции на основной технологической линии. Отделка и шумность не соответствуют ожиданиям потребителя. Дефект замечает разборчивый потребитель",
    },
    3: {
      title: "Незначительное",
      description:
        "Небольшое нарушение производственной линии. Может потребоваться переделка части продукции на специальном участке. Отделка и шумность не соответствуют ожиданиям потребителя. Дефект замечает средний потребитель",
    },
    4: {
      title: "Очень слабое",
      description:
        "Небольшое нарушение производственной линии. Может потребоваться сортировка и частичная переделка продукции. Отделка и шумность изделия не соответствуют ожиданиям потребителя. Этот дефект замечает большинство потребителей",
    },
    5: {
      title: "Слабое",
      description:
        "Небольшое нарушение производственной линии. Может потребоваться переделка до 100 % продукции. Транспортное средство/узел работоспособны, но некоторые системы комфорта и удобства работают с пониженной эффективностью. Потребитель испытывает некоторое неудовлетворение",
    },
    6: {
      title: "Умеренное",
      description:
        "Небольшое нарушение производственной линии. Часть продукции необходимо забраковать (без сортировки). Транспортное средство/узел работоспособны, но некоторые системы комфорта и удобства не работают. Потребитель испытывает дискомфорт",
    },
    7: {
      title: "Важное",
      description:
        "Небольшое нарушение производственной линии. Может потребоваться сортировка продукции, когда часть ее бракуется. Транспортное средство работоспособно, но с пониженной эффективностью. Потребитель неудовлетворен",
    },
    8: {
      title: "Очень важное",
      description:
        "Большое нарушение производственной линии. Может браковаться до 100% продукции. Транспортное средство/узел неработоспособны с потерей главной функции. Потребитель очень недоволен",
    },
    9: {
      title: "Опасное с предупреждением",
      description:
        "Может подвергнуть опасности персонал у станка или на сборке. Весьма высокий ранг значимости, когда вид потенциального дефекта ухудшает безопасность работы транспортного средства и (или) вызывает несоответствие обязательным требованиям безопасности и экологии с предупреждением",
    },
    10: {
      title: "Опасное без предупреждения",
      description:
        "Может подвергнуть опасности персонал у станка или на сборке. Очень высокий ранг значимости, когда вид потенциального дефекта ухудшает безопасность работы транспортного средства и (или) вызывает несоответствие обязательным требованиям безопасности и экологии без предупреждения",
    },
  },
};

export const locale = {
  emptyText: <div />,
};
