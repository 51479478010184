export const Title = ({ tasks, ...rest }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#fff",
      }}
    >
      <div
        style={{
          height: `${rest.rowHeight}px`,
          display: "flex",
          alignItems: "center",
          padding: "5px 10px",
          border: "1px solid #e6e4e4",
        }}
      >
        Этапы
      </div>
      {(tasks || []).map((task) => (
        <div
          key={task.id}
          style={{
            height: `${rest.rowHeight}px`,
            display: "flex",
            alignItems: "center",
            padding: "5px 10px",
            borderRight: "1px solid #e6e4e4",
            borderLeft: "1px solid #e6e4e4",
            borderBottom: "1px solid #e6e4e4",
          }}
        >
          {task.named}
        </div>
      ))}
    </div>
  );
};
