import React, { useState } from "react";
import { Form, Input, Select, Table } from "antd";

import styles from "./styles.module.css";

const options = [
  {
    value: 0,
    label: "Нет",
  },
  {
    value: 1,
    label: "Да",
  },
];

const columns = [
  {
    title: "Шаг",
    dataIndex: "step",
    width: "10%",
    onCell: (_, index) => ({
      rowSpan: index === 0 ? 5 : 0,
    }),
  },
  {
    title: "Контролируемые параметры",
    dataIndex: "params",
    width: "50%",
  },
  {
    title: "Оценка, баллы (0-нет, 1-да)",
    dataIndex: "point",
    width: "10%",
    render: (_, index) => (
      <Form.Item name={`option-table-1-${index.key}`}>
        <Select options={options} />
      </Form.Item>
    ),
  },
  {
    title: "Примечания/ Kaizen",
    dataIndex: "description",
    width: "20%",
    render: () => <Input.TextArea />,
  },
];

export const TablePaper = ({ dataArray, name, title, showHeader = true }) => {
  const [data] = useState(
    dataArray.map((item, index) => ({ params: item, step: title, key: index }))
  );
  const [sum, setSum] = useState("");

  const handleChangeForm = (_, all) => {
    const valuesArray = all
      .map((item) => item.value)
      .filter((item) => Number.isInteger(item));

    const sumCulc =
      valuesArray.length === 5
        ? valuesArray.reduce(
            (acc, item) => (item ? acc + Number(item) : acc),
            0
          )
        : "";

    setSum(sumCulc);
  };

  return (
    <div>
      <Form name={name} onFieldsChange={handleChangeForm}>
        <Table
          columns={columns}
          showHeader={showHeader}
          dataSource={data}
          pagination={false}
          className={styles.mainTable}
          rowClassName={styles.row}
          bordered
          summary={() => {
            return (
              <>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0} />
                  <Table.Summary.Cell index={1}>
                    ВСЕГО по шагу:
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={2} colSpan={2}>
                    {sum}
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </>
            );
          }}
        />
      </Form>
    </div>
  );
};
