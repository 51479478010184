import React, { useState } from "react";
import { Button, Form, Input, Select, Table } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { locale, options, resultMessage } from "./helper";

export const TableResult = ({ selectItem }) => {
  const [form] = Form.useForm();
  const [dataSource, setDataSource] = useState([]);

  const [count, setCount] = useState(0);

  const ResultComponent = ({ record, index }) => {
    const value = form.getFieldValue(`${record.key}-${index}`);
    return (
      <>
        {value ? (
          <div>
            <div>{resultMessage[selectItem][value].title}:</div>
            <div>{resultMessage[selectItem][value].description}</div>
          </div>
        ) : (
          ""
        )}
      </>
    );
  };

  const handleDelete = (key) => {
    const newData = dataSource.filter((item) => item.key !== key);
    setDataSource(newData);
  };
  const defaultColumns = [
    {
      title: "№",
      dataIndex: "number",
      width: "5%",
      render: (_, __, index) => index + 1,
    },
    {
      title: "Наименование последствия",
      dataIndex: "name",
      render: () => <Input />,
    },
    {
      title: "Балл значимости S",
      dataIndex: "point",
      render: (_, record, index) => (
        <Form.Item name={`${record.key}-${index}`} style={{ margin: 0 }}>
          <Select style={{ width: "100%" }} options={options} />
        </Form.Item>
      ),
    },
    {
      title: "Интерпретация",
      dataIndex: "result",
      render: (_, record, index) => (
        <Form.Item shouldUpdate>
          {() => <ResultComponent record={record} index={index} />}
        </Form.Item>
      ),
    },
    {
      dataIndex: "operation",
      render: (_, record) => (
        <Button
          onClick={() => handleDelete(record.key)}
          icon={<CloseOutlined />}
        />
      ),
    },
  ];
  const handleAdd = () => {
    const newData = {
      key: count,
    };
    setDataSource([...dataSource, newData]);
    setCount(count + 1);
  };
  const handleSave = (row) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
  };

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });
  return (
    <div style={{ width: "100%" }}>
      <Button
        onClick={handleAdd}
        type="primary"
        style={{
          marginBottom: 16,
        }}
      >
        Добавить
      </Button>
      <Form form={form}>
        <Table
          bordered
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          locale={locale}
        />
      </Form>
    </div>
  );
};
