import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Alert } from "react-bootstrap";
import { fetchModels } from "../../redux/modelsSlice";

import { Avatar, Card, Col, Row } from "antd";
import { Link } from "react-router-dom";
import router from "../../utils/router";
import { defaultImage } from "../../constants/project";

import "./style.css";

const ModelsList = () => {
  const dispatch = useDispatch();
  const { models, loading } = useSelector(
    (state) => state.models,
    shallowEqual
  );

  useEffect(() => {
    dispatch(fetchModels());
  }, [dispatch]);

  if (loading) return <>Loading...</>;
  if (!models.length) return <Alert variant="danger">Модели не найдены</Alert>;

  return (
    <Row gutter={16}>
      {models.map((model) => (
        <Col key={model.id} span={8}>
          <Link to={`${router.modules}/${model.id}`}>
            <Card
              hoverable
              cover={
                model.images.length ? (
                  <Avatar
                    shape="square"
                    size={421}
                    className="sizeAvatar"
                    src={model.images?.[0]?.image}
                  />
                ) : (
                  <Avatar
                    shape="square"
                    className="sizeAvatar"
                    size={421}
                    src={defaultImage}
                  />
                )
              }
            >
              <Card.Meta title={model.title} description={model.description} />
            </Card>
          </Link>
        </Col>
      ))}
    </Row>
  );
};

export default ModelsList;
