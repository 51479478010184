import React, { useEffect, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getParetoChart } from "../../redux/analyticsSlice";
import pareto from "./pareto.html";

const ModalPareto = ({ values }) => {
  const ref = useRef(null);
  const dispatch = useDispatch();
  const [ifrLoad, setIfrLoad] = useState(false);
  const { errorPareto, pareto: paretoChart } = useSelector(
    (state) => state.analytics,
    shallowEqual
  );

  useEffect(() => {
    dispatch(getParetoChart(values));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    setIfrLoad(false);
    if (paretoChart) {
      ref.current.addEventListener("load", () => {
        setIfrLoad(true);
      });
    }
  }, [paretoChart]);

  useEffect(() => {
    if (ifrLoad) {
      ref?.current?.contentWindow?.postMessage?.(paretoChart, "*");
    }
  }, [ifrLoad, paretoChart]);

  return (
    <>
      {errorPareto && <div className="center">{errorPareto}</div>}
      {paretoChart && (
        <iframe
          ref={ref}
          title="pareto"
          style={{ width: "100%", height: "55vh" }}
          srcDoc={pareto}
        />
      )}
      {paretoChart?.scaleX?.labels && (
        <ul className="paretoList">
          {paretoChart.scaleX.labels.map((item) => (
            <li>{item}</li>
          ))}
        </ul>
      )}
    </>
  );
};

export default ModalPareto;
