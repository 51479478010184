import React, { useEffect } from "react";
import moment from "moment";

import { useDispatch, useSelector } from "react-redux";
import { getWarehouseComplete } from "../../../redux/warehouseStatusSlice";
import { Button, Table } from "antd";

const TableModels = ({ data }) => {
  const columns = [
    {
      title: "Название",
      dataIndex: "title",
    },
    {
      title: "Количество",
      dataIndex: "quantity",
    },
  ];

  return (
    <Table columns={columns} dataSource={data} rowKey="id" pagination={false} />
  );
};

const ShippedContent = () => {
  const dispatch = useDispatch();
  const { complete, loading } = useSelector((state) => state.wareStatus);

  useEffect(() => {
    dispatch(getWarehouseComplete());
  }, [dispatch]);

  const columns = [
    {
      title: "Заказчик",
      dataIndex: "customer",
    },
    {
      title: "Дата приёма",
      dataIndex: "begin_date",
      render: (date) => moment(date).format("DD.MM.YYYY"),
    },
    {
      title: "Дата отправки",
      dataIndex: "departure_date",
      render: (date) => moment(date).format("DD.MM.YYYY"),
    },
    {
      title: "Действия",
      dataIndex: "",
      render: () => <Button>Отчет партии</Button>,
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        dataSource={complete}
        loading={loading}
        pagination={false}
        rowKey="id"
        expandable={{
          expandedRowRender: (record) => (
            <TableModels data={record.order_shoes_models} />
          ),
        }}
      />
    </>
  );
};

export default ShippedContent;
