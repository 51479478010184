import { useLayoutEffect, useRef, useState } from "react";
import { Button, Card, Table } from "antd";
import {
  createNewComponent,
  createNewIndicator,
  DEFAULT_COMPONENTS,
  DEFAULT_INDICATOR,
} from "./constants";
import { SavePNGButton } from "../../../components/SavePNGButton";

import styles from "./styles.module.css";

const deleteItem = (setter, key) => (id) =>
  setter((prev) => prev.filter((el) => el[key] !== id));

export const MatrixDiagram = () => {
  const [indicator, setIndicator] = useState(DEFAULT_INDICATOR);
  const [components, setComponents] = useState([]);
  const ref = useRef();

  useLayoutEffect(() => {
    setComponents(
      DEFAULT_COMPONENTS(
        deleteItem(setIndicator, "key"),
        deleteItem(setComponents, "dataIndex")
      )
    );
  }, []);

  const handleIndicator = () => {
    setIndicator((prev) => [...prev, createNewIndicator()]);
  };

  const handleComponent = () => {
    setComponents((prev) => [
      ...prev,
      createNewComponent(deleteItem(setComponents, "dataIndex")),
    ]);
  };

  return (
    <Card>
      <div className={styles.controls}>
        <Button onClick={handleIndicator}>Добавить колонку</Button>
        <Button onClick={handleComponent}>Добавить столбец</Button>
        <SavePNGButton
          containerRef={
            ref?.current?.children[0]?.children[0].children[0].children[0]
              .children[0].children[0]
          }
          name="matrix"
        />
      </div>
      <Table
        ref={ref}
        columns={components}
        dataSource={indicator}
        bordered
        tableLayout="auto"
        className={styles.table}
        pagination={false}
        scroll={{ x: true }}
      />
    </Card>
  );
};
