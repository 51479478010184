import React, { memo, useCallback, useState } from "react";
import { NodeResizer, useReactFlow, useStore } from "reactflow";
import clsx from "clsx";
import { Input } from "antd";
import { defaultStyles } from "../utils";

import styles from "./styles.module.css";
import "./style.css";
import Ports from "../components/Ports";

const MIN_WIDTH = 206;
const MIN_HEIGHT = 52;

export default memo(({ id, data }) => {
  const { setNodes, getNode } = useReactFlow();
  const thisNode = getNode(id);
  const [, setSizeBlock] = useState({
    width: thisNode?.style?.width || MIN_WIDTH,
    height: thisNode?.style?.height || MIN_HEIGHT,
  });
  const start = useStore((state) => state.connectionStartHandle);

  const handleChangeText = useCallback(
    (e, key) => {
      setNodes((nds) =>
        nds.map((node) => {
          if (node.id === id) {
            node.data = {
              ...node.data,
              [key]: e.target.value,
            };
          }

          return node;
        })
      );
    },
    [id, setNodes]
  );

  return (
    <div
      className={styles.wrapperNode}
      style={{ minWidth: MIN_WIDTH, minHeight: MIN_HEIGHT }}
    >
      <NodeResizer
        lineClassName={styles.resizeLine}
        handleClassName={styles.resizeHandle}
        onResize={(e, param) => {
          setSizeBlock(param);
        }}
        minWidth={MIN_WIDTH}
        minHeight={MIN_HEIGHT}
      />
      <div
        className={styles.node}
        style={{
          backgroundColor: "white",
          border: "1px solid black",
          position: "relative",
          height: "100%",
          display: "flex",
          alignItems: "center",
          minWidth: MIN_WIDTH,
          minHeight: MIN_HEIGHT,
        }}
      >
        <Input
          value={data.topLeftText}
          className="nodrag"
          bordered={false}
          maxLength={5}
          style={{
            ...defaultStyles,
            top: 0,
            left: "5px",
            zIndex: 10,
          }}
          onChange={(e) => handleChangeText(e, "topLeftText")}
        />
        <Input
          value={data.topRightText}
          className="nodrag"
          bordered={false}
          maxLength={5}
          style={{
            ...defaultStyles,
            top: 0,
            right: "5px",
            textAlign: "end",
            zIndex: 10,
          }}
          onChange={(e) => handleChangeText(e, "topRightText")}
          autoSize={{ minRows: 1, maxRows: 1 }}
        />
        <Input
          value={data.bottomRightText}
          className="nodrag"
          bordered={false}
          maxLength={5}
          style={{
            ...defaultStyles,
            bottom: 0,
            right: "5px",
            textAlign: "end",
            zIndex: 10,
          }}
          onChange={(e) => handleChangeText(e, "bottomRightText")}
          autoSize={{ minRows: 1, maxRows: 1 }}
        />
        <Input
          value={data.bottomLeftText}
          className="nodrag"
          bordered={false}
          maxLength={5}
          style={{
            ...defaultStyles,
            bottom: 0,
            left: "5px",
            zIndex: 10,
          }}
          onChange={(e) => handleChangeText(e, "bottomLeftText")}
          autoSize={{ minRows: 1, maxRows: 1 }}
        />
        <div style={{ width: "100%" }}>
          <Input.TextArea
            value={data.mainText}
            className="nodrag"
            bordered={false}
            style={{
              textAlign: "center",
            }}
            onChange={(e) => handleChangeText(e, "mainText")}
            autoSize={{ minRows: 1 }}
          />
        </div>
        <Ports
          size={10}
          className={clsx(styles.handle, {
            [styles.show]: start,
          })}
        />
      </div>
    </div>
  );
});
