import { Button, Descriptions, Form, Select, Table } from "antd";
import React, { useEffect, useState } from "react";
import {
  columnsFirst,
  columnsSecond,
  results,
  rowsFirst,
  rowsSecond,
} from "./helper";

import styles from "./styles.module.css";

const EditableCell = ({ dataIndex, record, children, ...restProps }) => {
  const [options, setOptions] = useState([]);
  const form = Form.useFormInstance();
  const supplyValue = form.getFieldValue(`suppliers-${record?.key}`);

  useEffect(() => {
    const splitVal = supplyValue?.split("-") ?? [];
    const valCorrect =
      splitVal.length === 1
        ? [{ value: Number(supplyValue), label: supplyValue }]
        : [
            {
              value: Number(splitVal[0]),
              label: splitVal[0],
            },
            {
              value: Number(splitVal[1]),
              label: splitVal[1],
            },
          ];

    form.setFields([
      {
        name: `points-${record?.key}`,
        value: splitVal.length === 1 ? Number(supplyValue) : undefined,
        errors: [],
      },
    ]);

    setOptions(valCorrect);
  }, [form, record?.key, supplyValue]);

  return (
    <td
      {...restProps}
      style={{ width: dataIndex === "suppliers" ? "400px" : "auto" }}
    >
      {dataIndex === "suppliers" ? (
        <Form.Item
          name={`${dataIndex}-${record?.key}`}
          style={{
            margin: 0,
          }}
        >
          <Select options={record.suppliers} />
        </Form.Item>
      ) : dataIndex === "points" ? (
        <Form.Item
          name={`${dataIndex}-${record?.key}`}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: "Необходимо выбрать значение",
            },
          ]}
        >
          <Select disabled={!supplyValue} options={options} />
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

export const TableThird = () => {
  const [form] = Form.useForm();
  const [total, setTotal] = useState({});

  const mergedColumnsFirst = columnsFirst.map((col) => {
    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
      }),
    };
  });

  const mergedColumnsSecond = columnsSecond.map((col) => {
    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
      }),
    };
  });

  const handleFinish = (val) => {
    const valueKeys = Object.keys(val).filter((item) =>
      item.includes("points-")
    );

    const sum = valueKeys.reduce((acc, item) => acc + val[item], 0);
    const percent = (sum / 32) * 100;
    let res = {};

    switch (true) {
      case percent > 90: {
        res = results[90];
        break;
      }
      case percent > 80: {
        res = results[80];
        break;
      }
      case percent > 60: {
        res = results[60];
        break;
      }
      default: {
        res = results[0];
      }
    }

    setTotal(res);
  };

  return (
    <Form
      form={form}
      onFieldsChange={() => setTotal({})}
      onFinish={handleFinish}
    >
      <h5>Оценка потенциального поставщика (первичная оценка)</h5>
      <Table
        size="small"
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        bordered
        dataSource={rowsFirst}
        columns={mergedColumnsFirst}
        rowClassName={styles.table}
        pagination={false}
      />
      <h5 style={{ marginTop: 24 }}>
        Оценка потенциального поставщика при наличии долговременных деловых
        связей (текущая оценка)
      </h5>
      <Table
        size="small"
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        bordered
        dataSource={rowsSecond}
        columns={mergedColumnsSecond}
        rowClassName={styles.table}
        pagination={false}
      />
      <Button style={{ marginTop: 15, marginBottom: 15 }} htmlType="submit">
        Рассчитать
      </Button>
      {total.title && (
        <Descriptions title="Вывод" bordered column={1}>
          <Descriptions.Item label="Показатель приемлемости:">
            {total.title}
          </Descriptions.Item>
          <Descriptions.Item label="Вывод:">
            {total.description}
          </Descriptions.Item>
        </Descriptions>
      )}
    </Form>
  );
};
