import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Input, Space, Table } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { Layout } from "../../components";
import { getTypeDefectsPage } from "../../redux/typeDefectsSlice";
import router from "../../utils/router";

const TypeDefects = () => {
  const { typeDefects } = useSelector((state) => state.typeDefects);
  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState("");

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Введите тип дефекта`}
          value={searchText}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
            setSearchText(e.target.value);
          }}
          onPressEnter={() => confirm()}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => confirm()}
            icon={<SearchOutlined />}
          >
            Поиск
          </Button>
          <Button
            onClick={() => {
              setSearchText("");
              clearFilters();
              confirm();
            }}
          >
            Отмена
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
  });

  const columns = [
    {
      title: "Тип дефекта",
      dataIndex: "title",
      ...getColumnSearchProps("title"),
    },
    {
      title: "Пути решения",
      dataIndex: "issue",
    },
    {
      dataIndex: "title",
      render: (value) => (
        <Link to={`${router.stat}/fishbone`} state={{ from: value }}>
          Диаграмма Исикавы
        </Link>
      ),
    },
  ];

  useEffect(() => {
    dispatch(getTypeDefectsPage());
  }, [dispatch]);

  return (
    <Layout title="Типы дефектов">
      <Table
        columns={columns}
        pagination={false}
        dataSource={typeDefects}
        rowKey="id"
      />
    </Layout>
  );
};

export default TypeDefects;
