import React from "react";
import { Button, Card, Form, Input } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

const ABCTitle = "ABCDEFGHIJ";

export const CardText = ({ onDelete, number, cardTitle }) => {
  return (
    <Card
      title={cardTitle ? `${cardTitle} #${number}` : ABCTitle.charAt(number)}
      size="small"
      extra={<Button onClick={onDelete} icon={<MinusCircleOutlined />} />}
      style={{ width: 150, minWidth: 150 }}
    >
      <Form>
        <Form.List name="names">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, index) => (
                <Form.Item
                  style={{ margin: 0 }}
                  label={index + 1}
                  key={field.key}
                >
                  <div
                    style={{ display: "flex", gap: 5, alignItems: "center" }}
                  >
                    <Form.Item noStyle>
                      <Input
                        bordered={false}
                        maxLength={50}
                        defaultValue=""
                        style={{
                          borderBottom: "1px solid black",
                          padding: "4px 0",
                        }}
                      />
                    </Form.Item>
                    <MinusCircleOutlined
                      className="dynamic-delete-button"
                      onClick={() => remove(field.name)}
                    />
                  </div>
                </Form.Item>
              ))}
              <Form.Item>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  {fields.length < 5 && (
                    <Button
                      type="dashed"
                      onClick={add}
                      style={{ width: "60%", marginTop: 5 }}
                      icon={<PlusOutlined />}
                    />
                  )}
                </div>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form>
    </Card>
  );
};
