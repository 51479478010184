import { Card, Col, Row } from "antd";
import React, { useRef } from "react";
import { TableSWOT } from "./TableSWOT";
import { Block } from "./Block";
import { SavePNGButton } from "../../../components/SavePNGButton";

export const SWOT = () => {
  const ref = useRef();

  return (
    <>
      <Card ref={ref}>
        <TableSWOT />
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Block title="Сильные стороны" />
          </Col>
          <Col span={12}>
            <Block title="Слабые стороны" />
          </Col>
          <Col span={12}>
            <Block title="Возможности" />
          </Col>
          <Col span={12}>
            <Block title="Угрозы" />
          </Col>
        </Row>
      </Card>
      <Card>
        <SavePNGButton containerRef={ref.current} name="swot" />
      </Card>
    </>
  );
};
