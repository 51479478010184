import { Button, Card, Select, Table } from "antd";
import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";

import { Layout } from "../../components";
import { getOrderDetail, readyOrder } from "../../redux/ordersSlice";
import { getWorkers, setWorker } from "../../redux/workersSlice";
import router from "../../utils/router";

function saveData(url) {
  var a = document.createElement("a");
  document.body.appendChild(a);
  a.style = "display: none";
  a.href = url;
  a.click();
}

const OrderDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const { order, loading } = useSelector((state) => state.orders, shallowEqual);
  const { workers } = useSelector((state) => state.workers, shallowEqual);

  useEffect(() => {
    dispatch(getOrderDetail(id));
    dispatch(getWorkers());
  }, [id, dispatch]);

  const setWorkerDispatch = (id, idWorker) => {
    dispatch(setWorker({ id, idWorker }));
  };

  const ready = async () => {
    await dispatch(readyOrder(id));
    navigate(router.warehouseOrders);
  };

  const download = () => {
    saveData(`${process.env.REACT_APP_URL}/api/download-order?order-id=${id}`);
  };

  const columns = [
    {
      title: "Модель: Размер",
      dataIndex: "model",
    },
    {
      title: "Операция",
      dataIndex: "operation",
    },
    {
      title: "Количество",
      dataIndex: "quantity",
    },
    {
      title: "Выполнено",
      dataIndex: "current_amount",
    },
    {
      title: "Забраковано",
      dataIndex: "defects",
    },
    {
      title: "Рабочий",
      render: (record) => (
        <Select
          onChange={(val) => setWorkerDispatch(record.id, val)}
          defaultValue={record?.worker?.id}
          style={{ width: "100%" }}
        >
          {workers.map((worker) => (
            <Select.Option key={worker.id} value={worker.id}>
              {worker.name}
            </Select.Option>
          ))}
        </Select>
      ),
    },
  ];

  return (
    <Layout title="Операции заказа">
      <Card>
        {!!(order.operation_list || []).length && (
          <Button onClick={download}>Сохранить в docx</Button>
        )}
        <Table
          columns={columns}
          loading={loading}
          dataSource={order.operation_list}
          pagination={false}
        />
        {order.ready && <Button onClick={ready}>Отправить на склад</Button>}
      </Card>
    </Layout>
  );
};

export default OrderDetail;
