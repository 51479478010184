import React, { useEffect, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getShewhartChart } from "../../redux/analyticsSlice";
import shewhart from "./shewhart.html";

const ModalShewhart = ({ values }) => {
  const ref = useRef(null);
  const dispatch = useDispatch();
  const [ifrLoad, setIfrLoad] = useState(false);
  const { errorShewhart, shewhart: shewhartChart } = useSelector(
    (state) => state.analytics,
    shallowEqual
  );

  useEffect(() => {
    dispatch(getShewhartChart(values));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    setIfrLoad(false);
    if (shewhartChart) {
      ref.current.addEventListener("load", () => {
        setIfrLoad(true);
      });
    }
  }, [shewhartChart]);

  useEffect(() => {
    if (ifrLoad) {
      ref?.current?.contentWindow?.postMessage?.(shewhartChart, "*");
    }
  }, [ifrLoad, shewhartChart]);

  return (
    <>
      {errorShewhart && <div className="center">{errorShewhart}</div>}
      {shewhartChart && (
        <iframe
          ref={ref}
          title="shewhart"
          style={{ width: "100%", height: "55vh" }}
          srcDoc={shewhart}
        />
      )}
    </>
  );
};

export default ModalShewhart;
