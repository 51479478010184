export const columns = [
  {
    title: "",
    dataIndex: "name",
  },
  {
    title: "ДА",
    dataIndex: "checkedYes",
    editable: true,
  },
  {
    title: "НЕТ",
    dataIndex: "checkedNo",
    editable: true,
  },
];

export const rows = [
  {
    key: "0",
    name: "Располагает ли предприятие сертифицированной системой качества, если «да», то название органа по сертификации, № сертификата и дата выдачи?",
    checkedYes: false,
    checkedNo: false,
  },
  {
    key: "1",
    name: "Планируется ли создание системы качества, если «да»?",
    checkedYes: false,
    checkedNo: false,
  },
  {
    key: "2",
    name: "Может ли предприятие выслать копию руководства по качеству?",
    checkedYes: false,
    checkedNo: false,
  },
  {
    key: "3",
    name: "Проводит ли предприятие оценку своих поставщиков?",
    checkedYes: false,
    checkedNo: false,
  },
  {
    key: "4",
    name: "Требует ли предприятие сертификаты на продукцию от своих поставщиков?",
    checkedYes: false,
    checkedNo: false,
  },
  {
    key: "5",
    name: "Проводит ли предприятие контроль закупаемой продукции?",
    checkedYes: false,
    checkedNo: false,
  },
  {
    key: "6",
    name: "Проводит ли предприятие контроль в процессе производства по документиро- ванным процедурам и правилам?",
    checkedYes: false,
    checkedNo: false,
  },
  {
    key: "7",
    name: "Разрешило ли предприятие проведение аудита качества специалистами наше-го предприятия?",
    checkedYes: false,
    checkedNo: false,
  },
];
