import { Select, Table } from "antd";
import React, { useState } from "react";
import { columns, rows, selectOptions } from "./helper";

const EditableCell = ({
  editing,
  dataIndex,
  record,
  children,
  ...restProps
}) => {
  return (
    <td {...restProps}>
      {dataIndex !== "name" ? (
        <Select
          options={selectOptions}
          style={{ width: "100%" }}
          mode="multiple"
        />
      ) : (
        children
      )}
    </td>
  );
};
export const TableFirst = () => {
  const [data] = useState(rows);

  const mergedColumns = columns.map((col) => {
    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        editing: true,
      }),
    };
  });

  return (
    <>
      <h5>
        Взаимодействие подразделений-участников оценки и выбора приемлемых
        поставщиков
      </h5>
      <Table
        size="small"
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        bordered
        dataSource={data}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={false}
      />
    </>
  );
};
