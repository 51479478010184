import { Button, Table } from "antd";
import React, { useEffect } from "react";
import { Alert } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { Layout } from "../../components";
import { getOrders } from "../../redux/ordersSlice";
import router from "../../utils/router";

const TableModels = ({ data }) => {
  const columns = [
    {
      title: "Название",
      dataIndex: "title",
    },
    {
      title: "Количество",
      dataIndex: "quantity",
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        dataSource={data.order_shoes_models}
        rowKey="id"
        pagination={false}
      />
    </>
  );
};

const OrdersList = () => {
  const dispatch = useDispatch();
  const { orders, loading } = useSelector(
    (state) => state.orders,
    shallowEqual
  );

  useEffect(() => {
    dispatch(getOrders());
  }, [dispatch]);

  const columns = [
    {
      title: "Заказчик",
      dataIndex: "customer",
    },
    {
      title: "Статус",
      dataIndex: "status",
    },
    {
      title: "Дата заказа",
      dataIndex: "begin_date",
    },
    {
      title: "Дата отправки",
      dataIndex: "departure_date",
    },
    {
      title: "Детали",
      dataIndex: "",
      render: (item) => (
        <Button type="primary">
          <Link to={`${router.orders}/${item.id}`}>Открыть</Link>
        </Button>
      ),
    },
  ];

  if (!orders.length && !loading)
    return (
      <Layout title="Список заказов">
        <Alert variant="danger">Заказы не найдены</Alert>
      </Layout>
    );

  return (
    <Layout title="Список заказов">
      <Table
        columns={columns}
        dataSource={orders}
        rowKey="id"
        loading={loading}
        pagination={false}
        expandable={{
          expandedRowRender: (record) => <TableModels data={record} />,
        }}
      />
    </Layout>
  );
};

export default OrdersList;
