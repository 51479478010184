import React, { useCallback, useState } from "react";
import { Button } from "antd";
import { RedoOutlined } from "@ant-design/icons";
import {
  EdgeLabelRenderer,
  getSmoothStepPath,
  getStraightPath,
  useReactFlow,
} from "reactflow";
import { configArrow } from "../utils";

import styles from "./styles.module.css";

const colorKeys = {
  1: {
    name: "Черный",
    code: "#000",
  },
  2: {
    name: "Красный",
    code: "#f00",
  },
  3: {
    name: "Синий",
    code: "#00f",
  },
  4: {
    name: "Зеленый",
    code: "#0f0",
  },
};

const items = [
  {
    label: "Черный",
    value: "1",
  },
  {
    label: "Красный",
    value: "2",
  },
  {
    label: "Синий",
    value: "3",
  },
  {
    label: "Зеленый",
    value: "4",
  },
];

const itemsEdge = [
  {
    label: "Согнутая",
    value: "SmoothStep",
  },
  {
    label: "Прямая",
    value: "Straight",
  },
];

const getEdgeType = (type, params) => {
  switch (type) {
    case "Straight": {
      return getStraightPath(params);
    }
    default: {
      return getSmoothStepPath(params);
    }
  }
};

export default function CustomEdge({
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  id,
  data,
}) {
  const { setEdges } = useReactFlow();
  const [show, setShow] = useState(false);
  const [edgePath, labelX, labelY] = getEdgeType(
    data.edgeType || "SmoothStep",
    {
      sourceX,
      sourceY,
      sourcePosition,
      targetX,
      targetY,
      targetPosition,
    }
  );

  const onEdgeClick = useCallback(
    (evt) => {
      evt.stopPropagation();
      setEdges((edges) =>
        edges.map((edge) => {
          if (edge.id === id) {
            return {
              ...edge,
              data: {
                ...edge.data,
                countArrow:
                  edge.data.countArrow > 2 ? 0 : edge.data.countArrow + 1,
              },
            };
          }
          return edge;
        })
      );
    },
    [id, setEdges]
  );

  const handleEnter = useCallback(() => setShow(true), []);
  const handleLeave = useCallback(() => setShow(false), []);

  const handleChange = useCallback(
    (e) => {
      setEdges((edges) =>
        edges.map((edge) => {
          if (edge.id === id) {
            return {
              ...edge,
              data: {
                ...edge.data,
                color: colorKeys[e.target.value].code,
              },
            };
          }
          return edge;
        })
      );
    },
    [id, setEdges]
  );

  const handleChangeEdge = useCallback(
    (e) => {
      setEdges((edges) =>
        edges.map((edge) => {
          if (edge.id === id) {
            return {
              ...edge,
              data: {
                ...edge.data,
                edgeType: e.target.value,
              },
            };
          }
          return edge;
        })
      );
    },
    [id, setEdges]
  );

  return (
    <>
      <defs>
        <marker
          className="react-flow__arrowhead"
          id={`arrowclosed-id${id}`}
          markerWidth="12.5"
          markerHeight="12.5"
          viewBox="-10 -10 20 20"
          markerUnits="strokeWidth"
          orient="auto-start-reverse"
          refX="0"
          refY="0"
        >
          <polyline
            stroke="#b1b1b7"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            fill="#b1b1b7"
            points="-5,-4 0,0 -5,4 -5,-4"
            style={{
              fill: data.color,
              stroke: data.color,
            }}
          />
        </marker>
      </defs>
      <path
        style={{
          fill: "none",
          stroke: data.color,
        }}
        d={edgePath}
        markerStart={`url(#${
          configArrow[data.countArrow].markerStart
        }-id${id})`}
        markerEnd={`url(#${configArrow[data.countArrow].markerEnd}-id${id})`}
        onMouseEnter={handleEnter}
        onMouseLeave={handleLeave}
      />
      <EdgeLabelRenderer>
        <div
          style={{
            position: "absolute",
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            fontSize: 12,
            pointerEvents: "all",
            zIndex: 1000,
          }}
          onMouseEnter={handleEnter}
          onMouseLeave={handleLeave}
          className={`nodrag nopan`}
        >
          {show && (
            <div className={styles.actions}>
              <Button
                size="small"
                shape="circle"
                onClick={onEdgeClick}
                icon={<RedoOutlined />}
              />
              <div
                style={{ display: "flex", flexDirection: "column", gap: "5px" }}
              >
                <select className={styles.box} onChange={handleChange}>
                  {items.map((item) => (
                    <option
                      key={item.value}
                      value={item.value}
                      className={styles.option}
                      selected={data.color === colorKeys[item.value].code}
                    >
                      {item.label}
                    </option>
                  ))}
                </select>
                <select className={styles.box} onChange={handleChangeEdge}>
                  {itemsEdge.map((item) => (
                    <option
                      key={item.value}
                      value={item.value}
                      className={styles.option}
                      selected={data.edgeType === item.value}
                    >
                      {item.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          )}
        </div>
      </EdgeLabelRenderer>
    </>
  );
}
