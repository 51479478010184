export const ABCTitle = "ABCDEFGHIJ";

export const defaultRowValues = {
  product: [
    {
      key: "1",
      name: "Соблюдение высокой точности размеров",
    },
    {
      key: "2",
      name: "Комплектно / перепутано",
    },
    {
      key: "3",
      name: "С повреждением / с коррозией",
    },
    {
      key: "4",
      name: "Особо сложный монтаж",
    },
    {
      key: "5",
      name: "Неполадки инструмента / оборудования",
    },
    {
      key: "6",
      name: "Ручное изготовление / сборка",
    },
    {
      key: "7",
      name: "Возможность изготовления в технологическом процессе не обеспечена",
    },
    {
      key: "8",
      name: "Ограниченные возможности проверки",
    },
    {
      key: "9",
      name: "Брак в случае возникновения дефекта",
    },
    {
      key: "10",
      name: "Проблемы безопасности",
    },
  ],
  process: [
    {
      key: "1",
      name: "Требования FMEA-конструкции",
    },
    {
      key: "2",
      name: "Требования заказчиков",
    },
    {
      key: "3",
      name: "Критические процессы",
    },
    {
      key: "4",
      name: "Значительное изменение процесса",
    },
    {
      key: "5",
      name: "Внедрение SPC",
    },
    {
      key: "6",
      name: "Новое оборудование/инструмент",
    },
    {
      key: "7",
      name: "Окружающая среда/риски труда",
    },
    {
      key: "8",
      name: "Значительные изменения организации",
    },
  ],
};
