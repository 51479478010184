import React, { useContext, useState } from "react";
import { Button, Form, Input, Popconfirm, Select, Table } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { chooseNumber, chooseOperation, locale } from "./helper";

const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const form = useContext(EditableContext);

  switch (dataIndex) {
    case "name": {
      return (
        <td {...restProps}>
          <Input />
        </td>
      );
    }
    case "importance": {
      return (
        <td {...restProps}>
          <Form.Item style={{ margin: 0 }} name={`${dataIndex}-${record.key}`}>
            <Select options={chooseNumber} />
          </Form.Item>
        </td>
      );
    }
    case "impact": {
      return (
        <td {...restProps}>
          <Form.Item style={{ margin: 0 }} name={`${dataIndex}-${record.key}`}>
            <Select options={chooseNumber} />
          </Form.Item>
        </td>
      );
    }
    case "direction": {
      return (
        <td {...restProps}>
          <Form.Item style={{ margin: 0 }} name={`${dataIndex}-${record.key}`}>
            <Select options={chooseOperation} />
          </Form.Item>
        </td>
      );
    }
    case "degree": {
      const data = form.getFieldsValue() || {};
      const values = Object.values(data);
      const sum =
        values.filter(Boolean).length === 3
          ? values?.reduce((acc, item) => acc * (item || 1), 1)
          : "";

      return <td {...restProps}>{sum}</td>;
    }
    default: {
      return <td {...restProps}>{children}</td>;
    }
  }
};

export const TableSWOT = () => {
  const [dataSource, setDataSource] = useState([]);
  const [count, setCount] = useState(0);

  const handleDelete = (key) => {
    const newData = dataSource.filter((item) => item.key !== key);
    setDataSource(newData);
  };

  const defaultColumns = [
    {
      title: "Фактор среды",
      dataIndex: "name",
      width: "30%",
    },
    {
      title: "Важность для отрасли",
      dataIndex: "importance",
    },
    {
      title: "Влияние на организацию",
      dataIndex: "impact",
    },
    {
      title: "Направленность влияния",
      dataIndex: "direction",
    },
    {
      title: "Степень важности",
      dataIndex: "degree",
    },
    {
      title: "",
      dataIndex: "operation",
      render: (_, record) =>
        dataSource.length >= 1 ? (
          <Popconfirm
            title="Вы уверены, что хотите удалить?"
            okText="Да"
            cancelText="Нет"
            onConfirm={() => handleDelete(record.key)}
          >
            <a>
              <DeleteOutlined />
            </a>
          </Popconfirm>
        ) : null,
    },
  ];

  const handleAdd = () => {
    const newData = {
      key: count,
      name: "",
      importance: "",
      impact: "",
      direction: "",
      degree: "",
      operation: "",
    };
    setDataSource([...dataSource, newData]);
    setCount(count + 1);
  };

  const columns = defaultColumns.map((col) => {
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
      }),
    };
  });

  return (
    <div style={{ marginBottom: 20 }}>
      <Button
        onClick={handleAdd}
        type="primary"
        style={{
          marginBottom: 16,
        }}
      >
        Добавить фактор среды
      </Button>
      <Table
        components={{
          body: {
            row: EditableRow,
            cell: EditableCell,
          },
        }}
        bordered
        locale={locale}
        pagination={false}
        dataSource={dataSource}
        columns={columns}
      />
    </div>
  );
};
