import React, { useCallback } from 'react';
import { List } from 'antd';
import { useReactFlow } from 'reactflow';
import { v4 as uuidv4 } from 'uuid';

import './style.css';

export const ContextMenu = ({ id, top, left, right, bottom, ...props }) => {
  const { getNode, setNodes, addNodes, setEdges } = useReactFlow();
  const duplicateNode = useCallback(() => {
    const node = getNode(id);
    const position = {
      x: node.position.x + 50,
      y: node.position.y + 50,
    };

    addNodes({ ...node, id: uuidv4(), selected: false, position });
  }, [id, getNode, addNodes]);

  const deleteNode = useCallback(() => {
    setNodes((nodes) => nodes.filter((node) => node.id !== id));
    setEdges((edges) => edges.filter((edge) => edge.source !== id));
  }, [id, setNodes, setEdges]);


  const data = [
    {
      title: 'Дублировать',
      onClick: duplicateNode,
    },
    {
      title: 'Удалить',
      onClick: deleteNode,
    }
  ];

  return (
    <div style={{ top, left, right, bottom }} className="context-menu" {...props}>
      <List
        bordered
        dataSource={data}
        renderItem={(item) => (
          <List.Item className='menu-item' onClick={item.onClick}>
            {item.title}
          </List.Item>
        )}
      />
    </div>
  );
}
