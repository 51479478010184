import React, { memo, useState } from "react";
import { NodeResizer, useReactFlow, useStore } from "reactflow";
import clsx from "clsx";

import styles from "./styles.module.css";
import "./style.css";
import Ports from "../components/Ports";
import CustomInput from "../components/CustomInput";

const MIN_WIDTH = 120;
const MIN_HEIGHT = 60;
const STROKE_WIDTH = 2;

export default memo(({ id, data, style }) => {
  const { setNodes, getNode } = useReactFlow();
  const thisNode = getNode(id);
  const [sizeBlock, setSizeBlock] = useState({
    width: thisNode?.style?.width || MIN_WIDTH,
    height: thisNode?.style?.height || MIN_HEIGHT,
  });
  const start = useStore((state) => state.connectionStartHandle);

  return (
    <div className={styles.node}>
      <NodeResizer
        onResize={(e, param) => {
          setSizeBlock(param);
        }}
        handleClassName={styles.handle}
        lineClassName={styles.handle}
        minWidth={MIN_WIDTH}
        minHeight={MIN_HEIGHT}
      />
      <svg width={sizeBlock.width} height={sizeBlock.height}>
        <g transform="translate(2, 2)">
          <path
            d={`M0,${sizeBlock.height - STROKE_WIDTH * 2} L${
              sizeBlock.width / 4 - 1
            },0 L${sizeBlock.width - STROKE_WIDTH * 2},0 L${
              sizeBlock.width - sizeBlock.width / 4 - 3
            },${sizeBlock.height - STROKE_WIDTH * 2} Z`}
            fill="#fff"
            strokeWidth={STROKE_WIDTH}
            stroke="#000"
          ></path>
        </g>
      </svg>
      <Ports
        size={10}
        className={clsx(styles.handle, {
          [styles.show]: start,
        })}
      />
      <CustomInput
        value={data.mainText}
        id={id}
        setter={setNodes}
        sizeBlock={sizeBlock}
      />
    </div>
  );
});
