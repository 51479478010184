import React, { useState } from "react";
import { Card, Tabs } from "antd";

import { Layout } from "../../components";

import CompletedContent from "./comleted";
import ShippedContent from "./shipped";

const WarehouseOrdersPage = () => {
  const [tab, setTab] = useState("1");

  return (
    <Layout title="Заказы">
      <Card>
        <Tabs
          defaultActiveKey={tab}
          activeKey={tab}
          onChange={(tab) => setTab(tab)}
        >
          <Tabs.TabPane tab="Выполненные" key="1">
            <CompletedContent setTab={setTab} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Отправленные" key="2">
            <ShippedContent />
          </Tabs.TabPane>
        </Tabs>
      </Card>
    </Layout>
  );
};

export default WarehouseOrdersPage;
