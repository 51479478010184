import React from "react";
import { Button, Form, Input } from "antd";
import { MinusCircleOutlined } from "@ant-design/icons";

const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 20,
      offset: 4,
    },
  },
};

const mapColors = {
  "Сильные стороны": {
    background: "#dbf4ca",
    titleBackground: "#3f7819",
    textColor: "white",
  },

  "Слабые стороны": {
    background: "#f2f2f2",
    titleBackground: "#7f7f7f",
    textColor: "white",
  },
  Возможности: {
    background: "#f5e3a5",
    titleBackground: "#febe00",
    textColor: "black",
  },
  Угрозы: {
    background: "#f9d2cd",
    titleBackground: "#c00000",
    textColor: "white",
  },
};

/**
 * @param {{
 *  title: "Сильные стороны"|"Слабые стороны"|"Возможности"|"Угрозы",
 * }} props
 */

export const Block = ({ title }) => {
  return (
    <div
      style={{
        backgroundColor: mapColors[title].background,
        height: "100%",
        minHeight: 300,
        paddingBottom: 15,
      }}
    >
      <div
        style={{
          backgroundColor: mapColors[title].titleBackground,
          color: mapColors[title].textColor,
          paddingLeft: 15,
          marginBottom: 15,
        }}
      >
        {title}
      </div>
      <Form name="dynamic_form_item" {...formItemLayoutWithOutLabel}>
        <Form.List name="names">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field) => (
                <Form.Item noStyle required={false} key={field.key}>
                  <div
                    style={{
                      display: "flex",
                      gap: 10,
                      marginBottom: 5,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Form.Item {...field} noStyle>
                      <Input
                        style={{
                          width: "60%",
                        }}
                      />
                    </Form.Item>
                    <MinusCircleOutlined
                      className="dynamic-delete-button"
                      onClick={() => remove(field.name)}
                    />
                  </div>
                </Form.Item>
              ))}
              <Form.Item noStyle>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    style={{
                      width: "30%",
                    }}
                  >
                    Добавить
                  </Button>
                </div>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form>
    </div>
  );
};
