const router = {
  main: "/",
  login: "/login",
  modules: "/models",
  orders: "/orders",
  typeDefects: "/typeDefects",
  analytics: "/analytics",
  master: "/master",
  warehouse: "/warehouse",
  warehouseOrders: "/warehouseOrders",
  stat: "/stat",
  admin: "/admin/",
};

export default router;
