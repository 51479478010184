import React from "react";
import { List } from "antd";
import { nodeTypesKeys } from "../../config";

import styles from "./sideMenu.module.css";
import { ReactComponent as BlockText } from "./assets/blockText.svg";
import { ReactComponent as BlockAnd } from "./assets/blockAnd.svg";
import { ReactComponent as BlockO } from "./assets/blockO.svg";
import { ReactComponent as BlockOr } from "./assets/blockOr.svg";
import { ReactComponent as BlockSob } from "./assets/blockSob.svg";
import { ReactComponent as BlockTextBorder } from "./assets/blockTextBorder.svg";
import { ReactComponent as BlockTextDouble } from "./assets/blockTextDouble.svg";
import { ReactComponent as BlockTextTriple } from "./assets/blockTextTriple.svg";
import { ReactComponent as BlockTextDoubleVertical } from "./assets/blockTextDoubleVertical.svg";
import { ReactComponent as BlockTextTripleVertical } from "./assets/blockTextTripleVertical.svg";
import { ReactComponent as Circle } from "./assets/circle.svg";
import { ReactComponent as Oval } from "./assets/oval.svg";
import { ReactComponent as Diamond } from "./assets/diamond.svg";
import { ReactComponent as Hexagon } from "./assets/hexagon.svg";
import { ReactComponent as Parallelogram } from "./assets/parallelogram.svg";
import { ReactComponent as Document } from "./assets/document.svg";

export const SideMenu = () => {
  const handleDragStart = (event, nodeType, payload) => {
    event.dataTransfer.setData("application/reactflow", nodeType);
    if (payload) {
      event.dataTransfer.setData("application/reactflow/payload", payload);
    }
    event.dataTransfer.effectAllowed = "move";
  };

  const data = [
    {
      title: "Текст",
      handleDragStart: (event) =>
        handleDragStart(event, nodeTypesKeys.textNode),
    },
    {
      title: <BlockTextBorder />,
      handleDragStart: (event) =>
        handleDragStart(event, nodeTypesKeys.textNodeWithBlocksBorder),
    },
    {
      title: <Circle />,
      handleDragStart: (event) =>
        handleDragStart(event, nodeTypesKeys.circleNode),
    },
    {
      title: <Oval />,
      handleDragStart: (event) =>
        handleDragStart(event, nodeTypesKeys.ovalNode),
    },
    {
      title: <Diamond />,
      handleDragStart: (event) =>
        handleDragStart(event, nodeTypesKeys.diamondNode),
    },
    {
      title: <Hexagon />,
      handleDragStart: (event) =>
        handleDragStart(event, nodeTypesKeys.hexagonNode),
    },
    {
      title: <Parallelogram />,
      handleDragStart: (event) =>
        handleDragStart(event, nodeTypesKeys.parallelogramNode),
    },
    {
      title: <Document />,
      handleDragStart: (event) =>
        handleDragStart(event, nodeTypesKeys.documentNode),
    },
    {
      title: <BlockText />,
      handleDragStart: (event) =>
        handleDragStart(event, nodeTypesKeys.textNodeWithBlocks),
    },
    {
      title: <BlockTextDouble />,
      handleDragStart: (event) =>
        handleDragStart(event, nodeTypesKeys.doubleTextNode),
    },
    {
      title: <BlockTextDoubleVertical />,
      handleDragStart: (event) =>
        handleDragStart(event, nodeTypesKeys.doubleTextNodeVertical),
    },
    {
      title: <BlockTextTriple />,
      handleDragStart: (event) =>
        handleDragStart(event, nodeTypesKeys.tripleTextNode),
    },
    {
      title: <BlockTextTripleVertical />,
      handleDragStart: (event) =>
        handleDragStart(event, nodeTypesKeys.tripleTextNodeVertical),
    },
    {
      title: <BlockOr />,
      handleDragStart: (event) =>
        handleDragStart(event, nodeTypesKeys.orNode, "X"),
    },
    {
      title: <BlockSob />,
      handleDragStart: (event) =>
        handleDragStart(event, nodeTypesKeys.orNode, "@"),
    },
    {
      title: <BlockO />,
      handleDragStart: (event) =>
        handleDragStart(event, nodeTypesKeys.orNode, "O"),
    },
    {
      title: <BlockAnd />,
      handleDragStart: (event) =>
        handleDragStart(event, nodeTypesKeys.orNode, "&"),
    },
  ];

  return (
    <div className={styles.side}>
      <List
        dataSource={data}
        grid={{ gutter: 16, column: 4 }}
        renderItem={(item) => (
          <List.Item
            className={styles.menu_item}
            draggable
            onDragStart={item.handleDragStart}
          >
            <div>{item.title}</div>
          </List.Item>
        )}
      />
    </div>
  );
};
