import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../api/api";

const initialState = {
  ready: [],
  complete: [],
  loading: false,
};

export const getWarehouseReady = createAsyncThunk(
  "warehouseStatus/getWarehouseReady",
  async () => {
    const response = await api.get("/api/storage/orders/ready/");
    return response.data;
  }
);

export const getWarehouseComplete = createAsyncThunk(
  "warehouseStatus/getWarehouseComplete",
  async () => {
    const response = await api.get("/api/storage/orders/sent/");
    return response.data;
  }
);

export const sentWarehouseComplete = createAsyncThunk(
  "warehouseStatus/sentWarehouseComplete",
  async (data) => {
    const response = await api.post("/api/storage/orders/ready/", data);
    return response.data;
  }
);

export const warehouseStatusSlice = createSlice({
  name: "warehouseStatus",
  initialState,
  reducers: {},
  extraReducers: {
    [getWarehouseReady.pending]: (state) => {
      state.loading = true;
    },
    [getWarehouseReady.rejected]: (state) => {
      state.loading = false;
    },
    [getWarehouseReady.fulfilled]: (state, action) => {
      state.ready = action.payload;
      state.loading = false;
    },
    [getWarehouseComplete.pending]: (state) => {
      state.loading = true;
    },
    [getWarehouseComplete.rejected]: (state) => {
      state.loading = false;
    },
    [getWarehouseComplete.fulfilled]: (state, action) => {
      state.complete = action.payload;
      state.loading = false;
    },
    [sentWarehouseComplete.pending]: (state) => {
      state.loading = true;
    },
    [sentWarehouseComplete.rejected]: (state) => {
      state.loading = false;
    },
    [sentWarehouseComplete.fulfilled]: (state, action) => {
      state.complete = action.payload;
      state.loading = false;
    },
  },
});

export const act = warehouseStatusSlice.actions;

export default warehouseStatusSlice.reducer;
