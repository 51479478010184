import { Typography } from "antd";
import { useState } from "react";

export const EditText = ({ startText, className }) => {
  const [text, setText] = useState(startText);

  return (
    <Typography.Paragraph
      className={className}
      editable={{
        icon: <></>,
        // editing: true,
        triggerType: ["text"],
        onChange: setText,
      }}
    >
      {text}
    </Typography.Paragraph>
  );
};
