import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../api/api";

const initialState = {
  isAuth: false,
  loading: true,
  loadingAuth: false,
  error: null,
  user: {},
};

export const fetchAuth = createAsyncThunk("auth/fetchModels", async (data) => {
  const response = await api.post("/auth-token/login/", data);
  if (response.data.token) {
    localStorage.setItem("token", response.data.token);
    const authData = await api.get("/auth-token/user/");
    return authData.data;
  } else {
    throw new Error("error");
  }
});

export const checkToken = createAsyncThunk("auth/checkToken", async () => {
  const response = await api.get("/auth-token/user/");
  return response.data;
});

export const logout = createAsyncThunk("auth/logout", async () => {
  const response = await api.post("/auth-token/logout/");
  localStorage.removeItem("token");
  return response.data;
});

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    offLoading: (state) => {
      state.loading = false;
    },
  },
  extraReducers: {
    [fetchAuth.fulfilled]: (state, action) => {
      state.isAuth = true;
      state.loading = false;
      state.loadingAuth = false;
      state.user = action.payload;
    },
    [fetchAuth.rejected]: (state, action) => {
      state.isAuth = false;
      state.loading = false;
      state.loadingAuth = false;
      state.error = true;
    },
    [fetchAuth.pending]: (state) => {
      state.error = null;
      state.loadingAuth = true;
    },
    [checkToken.fulfilled]: (state, action) => {
      state.isAuth = true;
      state.loading = false;
      state.user = action.payload;
    },
    [checkToken.rejected]: (state) => {
      state.isAuth = false;
      state.loading = false;
      localStorage.removeItem("token");
    },
    [logout.fulfilled]: (state) => {
      state.isAuth = false;
      state.loading = false;
    },
  },
});

export const { offLoading } = authSlice.actions;

export default authSlice.reducer;
