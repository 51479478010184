import React from "react";
import { Card, Tabs } from "antd";
import { Layout } from "../../components";

import MaterialsContent from "./materials";
import TechEquipContent from "./tech-equip";
import IssueHistoryContent from "./issue-history";

const WarehousePage = () => (
  <Layout title="Комплектующие">
    <Card>
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab="Материалы" key="1">
          <MaterialsContent />
        </Tabs.TabPane>
        <Tabs.TabPane tab="ОргТехОснастка" key="2">
          <TechEquipContent />
        </Tabs.TabPane>
        <Tabs.TabPane tab="История выдачи" key="3">
          <IssueHistoryContent />
        </Tabs.TabPane>
      </Tabs>
    </Card>
  </Layout>
);

export default WarehousePage;
