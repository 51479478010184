import React, { useEffect } from "react";
import moment from "moment";
import { Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getHistory } from "../../../redux/warehouseSlice";

const IssueHistoryContent = () => {
  const dispatch = useDispatch();
  const { history, loading } = useSelector((state) => state.ware);

  useEffect(() => {
    dispatch(getHistory());
  }, [dispatch]);

  const columns = [
    {
      title: "Название",
      dataIndex: "material",
    },
    {
      title: "Количество",
      dataIndex: "quantity",
    },
    {
      title: "Дата",
      dataIndex: "date",
      render: (date) => moment(date).format("DD.MM.YYYY"),
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        loading={loading}
        dataSource={history}
        pagination={false}
        rowKey="id"
      />
    </>
  );
};

export default IssueHistoryContent;
