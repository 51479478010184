export const columns = [
  {
    title: "Название",
    dataIndex: "title",
  },
  {
    title: "Материал",
    dataIndex: "material",
  },
  {
    title: "Площадь (см^2)",
    dataIndex: "area",
  },
  {
    title: "Стоимость",
    dataIndex: "price",
  },
  {
    title: "Количество",
    dataIndex: "quantity",
  },
  {
    title: "Итоговая стоимость",
    dataIndex: "final_price",
  },
];

export const additionalColumnsProcess = [
  {
    title: "Технический процесс",
    dataIndex: "tech_process",
  },
  {
    title: "Количество",
    dataIndex: "quantity",
  },
  {
    title: "Оборудование",
    dataIndex: "machine_tools",
    render: (item) => (item || []).join(", "),
  },
  {
    title: "Описание",
    dataIndex: "description",
  },
];
