import { Button, Col, Row, Select } from "antd";
import { MinusCircleOutlined } from "@ant-design/icons";
import { v4 as uuidv4 } from "uuid";
import { EditText } from "./components/EditText";

import { ReactComponent as Circle } from "./assets/circle.svg";
import { ReactComponent as CircleFilled } from "./assets/circleFilled.svg";
import { ReactComponent as Triangle } from "./assets/triangle.svg";

import styles from "./styles.module.css";

export const options = [
  {
    value: "circle",
    label: <Circle />,
  },
  {
    value: "circleFilled",
    label: <CircleFilled />,
  },
  {
    value: "triangle",
    label: <Triangle />,
  },
];

const DEFAULT_TEXT_INDICATOR = [
  {
    text: "массовая доля белка",
    0: "circleFilled",
    1: "circleFilled",
    2: "circleFilled",
    3: "triangle",
    4: "triangle",
    5: "triangle",
    6: "triangle",
  },
  {
    text: "массовая доля жира",
    0: "circleFilled",
    1: "circleFilled",
    2: "circleFilled",
    3: "triangle",
    4: "triangle",
    5: "triangle",
    6: "triangle",
  },
  {
    text: "массовая доля влаги",
    0: "circleFilled",
    1: "circleFilled",
    2: "circle",
    3: "triangle",
    4: "circle",
    5: "triangle",
    6: "triangle",
  },
  {
    text: "массовая доля рыбного фарша",
    0: "circleFilled",
    1: "triangle",
    2: "triangle",
    3: "triangle",
    4: "triangle",
    5: "",
    6: "",
  },
  {
    text: "массовая доля нерыбных компонентов",
    0: "circleFilled",
    1: "circleFilled",
    2: "circleFilled",
    3: "circle",
    4: "circle",
    5: "triangle",
    6: "triangle",
  },
  {
    text: "энергетическая ценность",
    0: "circleFilled",
    1: "circleFilled",
    2: "circleFilled",
    3: "circleFilled",
    4: "triangle",
    5: "",
    6: "triangle",
  },
  {
    text: "предельное напряжение сдвига",
    0: "circleFilled",
    1: "circleFilled",
    2: "circleFilled",
    3: "triangle",
    4: "triangle",
    5: "",
    6: "",
  },
  {
    text: "влагоудерживающая способность",
    0: "circleFilled",
    1: "circleFilled",
    2: "circleFilled",
    3: "triangle",
    4: "triangle",
    5: "",
    6: "triangle",
  },
  {
    text: "срок годности",
    0: "circleFilled",
    1: "circleFilled",
    2: "circleFilled",
    3: "circle",
    4: "circle",
    5: "circleFilled",
    6: "circle",
  },
  {
    text: "цена",
    0: "circleFilled",
    1: "circleFilled",
    2: "circleFilled",
    3: "triangle",
    4: "circleFilled",
    5: "triangle",
    6: "circleFilled",
  },
];

export const DEFAULT_INDICATOR = DEFAULT_TEXT_INDICATOR.map(
  (record, index) => ({
    key: index,
    ...record,
  })
);

const DEFAULT_TEXT_COMPONENTS = [
  "рыбный фарш",
  "сухое цельное молоко",
  "яичный порошок",
  "лук",
  "морковь",
  "соль",
  "сахар",
];

export const DEFAULT_COMPONENTS = (deleteFn, deleteFnCol) => [
  {
    title: () => (
      <div className={styles.diagonalLine}>
        <Row className={styles.row}>
          <Col span={12}></Col>
          <Col span={12}>
            <div className={styles.textRight}>
              <EditText startText="Компоненты рецептуры" />
            </div>
          </Col>
          <Col span={12}>
            <div className={styles.textLeft}>
              <EditText startText="Показатели качества готового продукта" />
            </div>
          </Col>
          <Col span={12}></Col>
        </Row>
      </div>
    ),
    dataIndex: "text",
    align: "center",
    render: (text, record) => (
      <div style={{ minWidth: "500px", position: "relative" }}>
        <EditText startText={text || "Введите текст"} />
        <Button
          icon={<MinusCircleOutlined style={{ color: "red" }} />}
          onClick={() => deleteFn(record.key)}
          type="text"
          style={{
            position: "absolute",
            top: "50%",
            right: "0",
            transform: "translate(0, -50%)",
          }}
        />
      </div>
    ),
  },
  ...DEFAULT_TEXT_COMPONENTS.map((text, index) => {
    return {
      title: () => (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <EditText className={styles.verticalText} startText={text} />
          <Button
            icon={<MinusCircleOutlined style={{ color: "red" }} />}
            onClick={() => deleteFnCol(index)}
            type="text"
            style={{
              position: "absolute",
              bottom: "0",
              left: "50%",
              transform: "translate(-50%, 0)",
            }}
          />
        </div>
      ),
      dataIndex: index,
      render: (_, record) => (
        <Select
          allowClear
          defaultValue={record[index]}
          style={{ width: "100%" }}
          options={options}
        />
      ),
      width: 100,
    };
  }),
];

export const createNewComponent = (deleteFnCol) => {
  const id = uuidv4();

  return {
    title: () => (
      <div>
        <EditText className={styles.verticalText} startText="Введите текст" />
        <Button
          icon={<MinusCircleOutlined style={{ color: "red" }} />}
          onClick={() => deleteFnCol(id)}
          type="text"
          style={{
            position: "absolute",
            bottom: "0",
            left: "50%",
            transform: "translate(-50%, 0)",
          }}
        />
      </div>
    ),
    dataIndex: id,
    key: id,
    render: () => (
      <Select allowClear style={{ width: "100%" }} options={options} />
    ),
    width: 100,
  };
};

export const createNewIndicator = () => ({
  key: uuidv4(),
  text: "Введите текст",
});
