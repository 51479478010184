import React, { memo, useCallback } from "react";
import { NodeResizer, useReactFlow, useStore } from "reactflow";
import { Input } from "antd";
import clsx from "clsx";
import { defaultStyles } from "../utils";

import styles from "./styles.module.css";
import "./style.css";
import Ports from "../components/Ports";

export default memo(({ id, data }) => {
  const { setNodes } = useReactFlow();
  const start = useStore((state) => state.connectionStartHandle);

  const handleChangeText = useCallback(
    (e, key) => {
      setNodes((nds) =>
        nds.map((node) => {
          if (node.id === id) {
            node.data = {
              ...node.data,
              [key]: e.target.value,
            };
          }

          return node;
        })
      );
    },
    [id, setNodes]
  );

  return (
    <div className={styles.wrapperNode}>
      <NodeResizer
        lineClassName={styles.resizeLine}
        handleClassName={styles.resizeHandle}
        minWidth={100}
        minHeight={50}
      />
      <div
        className={styles.node}
        style={{
          backgroundColor: "white",
          borderRadius: "8px",
          border: "1px solid black",
          position: "relative",
          height: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Input
          value={data.topLeftText}
          className="nodrag"
          bordered={false}
          maxLength={5}
          style={{
            ...defaultStyles,
            top: 0,
            left: "5px",
            zIndex: 10,
          }}
          onChange={(e) => handleChangeText(e, "topLeftText")}
        />
        <Input
          value={data.topRightText}
          className="nodrag"
          bordered={false}
          maxLength={5}
          style={{
            ...defaultStyles,
            top: 0,
            right: "5px",
            textAlign: "end",
            zIndex: 10,
          }}
          onChange={(e) => handleChangeText(e, "topRightText")}
        />
        <Input
          value={data.bottomRightText}
          className="nodrag"
          bordered={false}
          maxLength={5}
          style={{
            ...defaultStyles,
            bottom: 0,
            right: "5px",
            textAlign: "end",
            zIndex: 10,
          }}
          onChange={(e) => handleChangeText(e, "bottomRightText")}
        />
        <Input
          value={data.bottomLeftText}
          className="nodrag"
          bordered={false}
          maxLength={5}
          style={{
            ...defaultStyles,
            bottom: 0,
            left: "5px",
            zIndex: 10,
          }}
          onChange={(e) => handleChangeText(e, "bottomLeftText")}
        />
        <div style={{ width: "100%" }}>
          <Input.TextArea
            value={data.mainText}
            className="nodrag"
            bordered={false}
            style={{
              textAlign: "center",
            }}
            onChange={(e) => handleChangeText(e, "mainText")}
            autoSize={{ minRows: 1 }}
          />
        </div>
        <Ports
          size={10}
          className={clsx(styles.handle, {
            [styles.show]: start,
          })}
        />
      </div>
    </div>
  );
});
