import React, { useRef, useState } from "react";
import { Gantt as GanttCore } from "../../../components/gantt-react";
import "../../../components/gantt-react/index.css";
import { Button, Card, DatePicker, Form, Input, Modal, Select } from "antd";
import { VIEW_MODE_OPTIONS } from "./constants";
import { v4 as uuidv4 } from "uuid";
import { Title } from "./components/Title";
import { Content } from "./components/Content";
import moment from "moment";

import styles from "./styles.module.css";
import { SavePNGButton } from "../../../components/SavePNGButton";

export const Gantt = () => {
  const [form] = Form.useForm();
  const [viewMode, setViewMode] = useState("Month");
  const [newData, setNewData] = useState([]);
  const [modal, setModal] = useState({
    isOpen: false,
    id: null,
  });
  const ref = useRef(null);

  const onTaskChange = (newTask) => {
    setNewData((prev) =>
      prev.map((task) => {
        if (task.id === newTask.id) {
          return newTask;
        } else {
          return task;
        }
      })
    );
  };

  const onTaskDelete = (task) => {
    console.log(task, "delete");
  };

  const onDblClick = (task) => {
    form.setFieldsValue({
      named: task.named,
      date: [moment(task.start), moment(task.end)],
    });
    setModal({ isOpen: true, id: task.id });
  };

  const handleAdd = () => setModal({ isOpen: true, id: null });
  const handleClose = () => setModal({ isOpen: false, id: null });
  const handleCreate = ({ named, date }) => {
    if (modal.id) {
      setNewData((prev) =>
        prev.map((item) => {
          if (item.id === modal.id) {
            return {
              ...item,
              named,
              start: date[0].toDate(),
              end: date[1].toDate(),
            };
          }

          return item;
        })
      );
    } else {
      setNewData((prev) => [
        ...prev,
        {
          id: uuidv4(),
          named,
          start: date[0].toDate(),
          end: date[1].toDate(),
          type: "task",
          isDisabled: true,
          progress: 100,
          styles: {
            progressColor: "#ffbb54",
            progressSelectedColor: "#ff9e0d",
          },
        },
      ]);
    }

    form.resetFields();
    setModal({ isOpen: false, id: null });
  };
  const handleDelete = () => {
    setNewData((prev) => prev.filter((item) => item.id !== modal.id));

    form.resetFields();
    setModal({ isOpen: false, id: null });
  };

  return (
    <Card>
      <div className={styles.controls}>
        <Button onClick={handleAdd}>Создать этап</Button>
        <Select
          options={VIEW_MODE_OPTIONS}
          value={viewMode}
          onChange={(val) => setViewMode(val)}
        />
        <SavePNGButton containerRef={ref} fullScreen name="gantt" />
      </div>
      <GanttCore
        tasks={newData}
        viewMode={viewMode}
        TaskListHeader={() => <></>}
        TaskListTable={Title}
        TooltipContent={Content}
        locale="ru"
        preStepsCount={2}
        onDateChange={onTaskChange}
        onTaskDelete={onTaskDelete}
        onDoubleClick={onDblClick}
      />
      <Modal
        title={modal.id ? "Редактировать этап" : "Создать этап"}
        open={modal.isOpen}
        footer={
          <>
            {modal.id && (
              <Button danger onClick={() => handleDelete()}>
                Удалить
              </Button>
            )}
            <Button type="primary" onClick={() => form.submit()}>
              Сохранить
            </Button>
            <Button onClick={() => handleClose()}>Отмена</Button>
          </>
        }
      >
        <Form form={form} onFinish={handleCreate}>
          <Form.Item
            name="named"
            rules={[
              {
                required: true,
                message: "Пожалуйста, введите название",
              },
            ]}
          >
            <Input placeholder="Введите название" />
          </Form.Item>
          <Form.Item
            name="date"
            rules={[
              {
                required: true,
                message: "Пожалуйста, введите даты",
              },
            ]}
          >
            <DatePicker.RangePicker
              style={{ width: "100%" }}
              format={["DD.MM.YYYY", "DD.MM.YYYY"]}
            />
          </Form.Item>
        </Form>
      </Modal>
    </Card>
  );
};
